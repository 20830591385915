.google-map {
    padding-top: 56%;
    position: relative;
    width: 100%;

    &__map {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__directions {
        display: block;
        margin-top: $baseline * 2;
        text-align: right;
    }
}
