.service {
    &__container {
        border-bottom: 2px solid $color-02;
        border-top: 2px solid $color-02;
        display: table;
        padding: $baseline * 8 0;
        width: 100%;
    }

    &__content,
    &__image-container {
        @include respond-to(md) {
            display: table-cell;
            vertical-align: top;
        }
    }

    &__image-container {
        text-align: center;
        width: 30%;

        @include respond-to(0, sm) {
            display: none;
        }
    }

    &__heading {
        display: block;
        margin-bottom: $baseline * 2;
        text-decoration: none;

        * {
            font-size: px-to-rem(22px);
            line-height: px-to-rem($baseline * 7);
            margin: 0;
        }
    }

    &__sub-heading {
        font-family: inherit;
        font-size: px-to-rem(16px);
        font-weight: bold;
        line-height: px-to-rem($baseline * 5);
        margin: 0;
        text-transform: none;
    }

    &__list {
        display: block;
        line-height: px-to-rem($baseline * 6);
        list-style: none;
        margin: 0 0 $baseline * 2 0;
        padding: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__item {
        position: relative;
        font-size: px-to-rem(14px);

        @include respond-to(md) {
            font-size: px-to-rem(16px);
        }
    }

    &__title {
        display: block;
        left: 0;
        position: absolute;
        top: 0;
    }

    &__description {
        padding-left: $baseline * 16;

        @include respond-to(md) {
            padding-left: $baseline * 30;
        }
    }

    &__nav {
        padding-top: $baseline * 7;
        text-align: center;
    }

    &__nav-item {
        font-size: px-to-rem(14px);
        text-decoration: none;

        &:first-child {
            float: left;
        }

        &:last-child {
            float: right;
        }
    }

    &__image-border {
        background-size: cover;
        background-position: top center;
        margin: $baseline * 6 auto;
        width: 230px;

        @include respond-to(md) {
            display: none;
            margin: 0;
            width: 100%;
        }

        .service__image-container & {
            display: block;
            height: auto;
            width: 100%;
        }

        .service--rounded-image & {
            border-radius: 50%;
            border-style: solid;
            border-width: 2px;
            height: 230px;
            min-width: auto;
            overflow: hidden;
            position: relative;
        }

        .service--rounded-image .service__image-container & {
            height: auto;
            padding-top: 100%;
        }
    }

    .service:not(.service--rounded-image) .service__image-border {
        background: none;
    }

    &__image {
        display: block;
        width: 100%;

        .service--rounded-image & {
            display: none;
        }
    }
}
