$funeral-planner-not-selected-color: #e65f00;
$funeral-planner-selected-color: #0e6c8b;

.product-container {
    padding: $baseline * 12 0;
    position: relative;
    z-index: index($z-stack, segment);

    @include respond-to(md) {
        padding: $baseline * 14 0 $baseline * 10 0;
    }

    &__background {
        display: block;
        height: 100%;
        left: 50%;
        margin-left: -75vw;
        position: absolute;
        top: 0;
        width: 150vw;
    }

    &__content {
        position: relative;
        z-index: 1;
    }

    &__no-product {
        position: absolute;
        right: 0;
        top: 0;

        input {
            visibility: hidden;

            &:checked + label {
                display: none;
            }
        }

        label {
            cursor: pointer;

            &:before {
                content: '\d7';
                margin-right: $baseline;
            }
        }
    }

    .custom-header__heading {
        @include respond-to(0, sm) {
            padding-right: $baseline * 20;
        }
    }

    &__input {
        margin-top: $baseline * 6;

        &:after {
            clear: both;
            content: '';
            display: block;
        }
    }

    &__heading {
        margin-bottom: $baseline * 4;
    }

    &__price-difference {
        font-weight: bold;
    }

    .general-form__checkbox,
    .general-form__radio {
        & + .general-form__label .product-container__price-difference {
            color: $funeral-planner-not-selected-color;
        }

        &:checked + .general-form__label .product-container__price-difference {
            color: $funeral-planner-selected-color;
        }
    }

    .general-form__element--radio,
    .general-form__element--checkbox {

        @include respond-to(sm) {
            float: left;
            width: 33%;
        }

        .general-form__label {
            @include respond-to(0, xs) {
                margin-bottom: $baseline * 5;
            }
            &:before {
                top: 0;
            }

            &:after {
                top: 6px;
            }
        }
    }

    .general-form__textarea {
        min-height: auto;
    }
}
