@font-face {
	font-family: "icon-fonts";
	src: url('../resources/icon-fonts/icon-fonts.eot');
	src: url('../resources/icon-fonts/icon-fonts.eot?#iefix') format('eot'),
		url('../resources/icon-fonts/icon-fonts.woff2') format('woff2'),
		url('../resources/icon-fonts/icon-fonts.woff') format('woff'),
		url('../resources/icon-fonts/icon-fonts.ttf') format('truetype'),
		url('../resources/icon-fonts/icon-fonts.svg#icon-fonts') format('svg');
}

.icon {
	&:before {
		font-family: "icon-fonts";
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
		text-decoration: none;
		text-transform: none;
	}
}

.icon-arrow-down-o {
	@extend .icon;
	
	&:before {
		content: "\E001";
	}
}
.icon-caret-down {
	@extend .icon;
	
	&:before {
		content: "\E002";
	}
}
.icon-chat-pp {
	@extend .icon;
	
	&:before {
		content: "\E003";
	}
}
.icon-chat {
	@extend .icon;
	
	&:before {
		content: "\E004";
	}
}
.icon-chevron-left {
	@extend .icon;
	
	&:before {
		content: "\E005";
	}
}
.icon-chevron-right {
	@extend .icon;
	
	&:before {
		content: "\E006";
	}
}
.icon-cross {
	@extend .icon;
	
	&:before {
		content: "\E007";
	}
}
.icon-edit {
	@extend .icon;
	
	&:before {
		content: "\E008";
	}
}
.icon-fa-bars {
	@extend .icon;
	
	&:before {
		content: "\E009";
	}
}
.icon-fa-chevron-down {
	@extend .icon;
	
	&:before {
		content: "\E00A";
	}
}
.icon-fa-comments {
	@extend .icon;
	
	&:before {
		content: "\E00B";
	}
}
.icon-fa-envelope {
	@extend .icon;
	
	&:before {
		content: "\E00C";
	}
}
.icon-fa-search {
	@extend .icon;
	
	&:before {
		content: "\E00D";
	}
}
.icon-fa-times {
	@extend .icon;
	
	&:before {
		content: "\E00E";
	}
}
.icon-facebook {
	@extend .icon;
	
	&:before {
		content: "\E00F";
	}
}
.icon-feature-arrow {
	@extend .icon;
	
	&:before {
		content: "\E010";
	}
}
.icon-feature-cross {
	@extend .icon;
	
	&:before {
		content: "\E011";
	}
}
.icon-feature-plus {
	@extend .icon;
	
	&:before {
		content: "\E012";
	}
}
.icon-feature-tick {
	@extend .icon;
	
	&:before {
		content: "\E013";
	}
}
.icon-fp-calendar {
	@extend .icon;
	
	&:before {
		content: "\E014";
	}
}
.icon-fp-location {
	@extend .icon;
	
	&:before {
		content: "\E015";
	}
}
.icon-fp-search {
	@extend .icon;
	
	&:before {
		content: "\E016";
	}
}
.icon-info {
	@extend .icon;
	
	&:before {
		content: "\E017";
	}
}
.icon-linkedin {
	@extend .icon;
	
	&:before {
		content: "\E018";
	}
}
.icon-mail-pp {
	@extend .icon;
	
	&:before {
		content: "\E019";
	}
}
.icon-mail {
	@extend .icon;
	
	&:before {
		content: "\E01A";
	}
}
.icon-menu {
	@extend .icon;
	
	&:before {
		content: "\E01B";
	}
}
.icon-paw {
	@extend .icon;
	
	&:before {
		content: "\E01C";
	}
}
.icon-phone {
	@extend .icon;
	
	&:before {
		content: "\E01D";
	}
}
.icon-search-pp {
	@extend .icon;
	
	&:before {
		content: "\E01E";
	}
}
.icon-search {
	@extend .icon;
	
	&:before {
		content: "\E01F";
	}
}
.icon-tick {
	@extend .icon;
	
	&:before {
		content: "\E020";
	}
}
.icon-vetlink {
	@extend .icon;
	
	&:before {
		content: "\E021";
	}
}
.icon-youtube {
	@extend .icon;
	
	&:before {
		content: "\E022";
	}
}
