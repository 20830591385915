/**
 * These are elements of a segment (container component) that need to be 100% of the viewport width.
 * Since these segment are dropped in a layout using the AEM grid, they are not full viewport width.
 * To make them full viewport width, they have their position set to absolute, and have a margin left
 * set to -50vw and a width of 100vw set. However, this is only set when the segment is 12 columns.
 * The mixin full-viewport-width is used as shorthand to make the code more concise.
 */
@mixin full-viewport-width {
    left: 50%;
    margin-left: -50vw;
    width: 100vw;
}

.segment {
    position: relative;
    z-index: index($z-stack, segment);

    &__inner {
        padding: $baseline * 12 0;

        @include respond-to(md) {
            padding: $baseline * 16 0;
        }

        @media print {
            padding: 0;
        }

        .segment--full-height & {
            .simplicity & {
                height: calc(100vh - #{$header-height-simplicity});
            }

            .white-lady & {
                height: calc(100vh - #{$header-height-white-lady});
            }

            @include respond-to(md) {
                display: table;
                height: 100vh;
                height: calc(100vh - #{$header-height-md});
                max-height: 2000px;
                width: 100%;

                .simplicity & {
                    height: calc(100vh - #{$header-height-simplicity-md});
                }

                .white-lady & {
                    height: calc(100vh - #{$header-height-white-lady-md});
                }
            }
        }
    }

    &__border {
        position: relative;
    
        &-image,
        &-image-stretch {
            @include respond-to(0,md){
                max-width: 100%;
            }
            display: block;
            opacity: 0;
        }


        &--stretch {
            .segment__border-bg {
                background-size: 100% 100%;
            }
        }

        &--tile {
            .segment__border-bg {
                background-position: center center;
            }
        }

        &--cover {
            .segment__border-image-stretch {
                height: 100%;
                left: 50%;
                min-width: 100%;
                opacity: 1;
                position: absolute;
                transform: translateX(-50%);
            }

            .segment__border-bg {
                background: none !important;
            }
        }
    }

    &__background-image {
        background-position: center center;
        background-size: cover;

        .segment--bg-align-top & {
            background-position: top center;
        }

        .segment--bg-align-bottom & {
            background-position: bottom center;
        }

        .segment--bg-scale-cover & {
            background-size: cover;
        }

        .segment--bg-scale-width & {
            background-size: 100%;
        }

        .segment:not(.segment--bg-hide-thumb-mobile) & {
            @include respond-to(0, sm) {
                background-image: none !important;
            }
        }
    }

    &__overlay {
        background: $color-02;
        opacity: 0.4;

        .segment:not(.segment--bg-hide-thumb-mobile) & {
            @include respond-to(0, sm) {
                opacity: 0;
            }
        }
    }

    &__background + .segment__overlay {
        display: none;
    }

    &__content-container {
        .segment--full-height & {
            @include respond-to(md) {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }

    &__content {
        position: relative;
        z-index: 1;

        .segment--has-jump-down & {
            padding-bottom: $baseline * 18;
        }

        .segment--fp-contact-form & {
           background: rgba(232, 244, 245, 0.5);
           border: 5px solid $color-01;
           border-radius: 10px;
           padding: $baseline * 2 $baseline * 4;

           @include respond-to(sm) {
               padding: $baseline * 2 $baseline * 4;
           }

           @include respond-to(md) {
               padding: $baseline * 2 $baseline * 4;
           }
        }
    }

    .section--segment-image {
        padding: 0 0 $section-gutter / 2 0;

        @include respond-to(md) {
            display: none;
        }
    }

    &--bg-hide-thumb-mobile .section--segment-image {
        display: none;
    }

    .segment-image {
        width: 100%;
    }

    &--full-height {
        .jump-down {
            padding-top: $baseline * 3;
            position: relative;
            text-align: center;

            @include respond-to(md) {
                bottom: $baseline * 15;
                font-size: $baseline * 16;
                left: 50%;
                padding-top: 0;
                margin-left: -$baseline * 8;
                position: absolute;
            }
        }
    }

    &--bg-scale-cover {
        & > .segment__background,
        & > .segment__background-image,
        & > .segment__overlay {
            @include full-viewport-width;
        }
    }
}

[class*='aem-AuthorLayer'] .segment--full-height .segment__inner {
    height: auto;
}

.segment__background,
.segment__background-image,
.segment__overlay,
.segment__border-bg {
    display: block;
    height: 100%;
    left: -$gutter / 2;
    position: absolute;
    top: 0;
    width: calc(100% + #{$gutter});

    // The customization-form is the class on the product customization page. If there is a section
    // on this page it should always be 100% viewport width, even if its parent is not set to 100%
    // viewport width. To accomplish this the following styles are used:
    .customization-form & {
        left: 50%;
        margin-left: -75vw;
        width: 150vw;
    }

    @include respond-to(md) {
        left: -$gutter-md / 2;
        width: calc(100% + #{$gutter-md});
    }

    @include respond-to(lg) {
        left: -$gutter-lg / 2;
        width: calc(100% + #{$gutter-lg});
    }

    @include respond-to(xl) {
        left: -$gutter-xl / 2;
        width: calc(100% + #{$gutter-xl});
    }
}


 // The following elements will use the full-viewport-width mixin to make them 100% width of the viewport,
 // even if their parent isn't 100% viewport width.
.segment__border--cover .segment__border-bg,
.segment__border--tile .segment__border-bg {
    .footer > & {
        @include full-viewport-width;
    }

    .section--segment.aem-GridColumn--default--12:not([class*="aem-GridColumn--phone--"]) > &,
    .section--segment.aem-GridColumn--phone--12 > & {
        @include respond-to(0, xs) {
            @include full-viewport-width;
        }
    }

    .section--segment.aem-GridColumn--default--12:not([class*="aem-GridColumn--tablet--"]) > &,
    .section--segment.aem-GridColumn--tablet--12 > & {
        @include respond-to(sm, md) {
            @include full-viewport-width;
        }
    }

    .section--segment.aem-GridColumn--default--12 > & {
        @include respond-to(lg) {
            @include full-viewport-width;
        }
    }
}

.segment > .segment__background,
.segment > .segment__background-image,
.segment > .segment__overlay,
.segment__border .segment__border-bg {
    .footer > & {
        @include full-viewport-width;
    }
}