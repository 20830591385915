.quote-form {
    margin-left: -$baseline * 3.5;
    padding: $baseline * 3.5;
    width: calc(100% + #{$baseline * 7});

    @include respond-to(xs) {
        padding: $baseline * 7;
        margin-left: 0;
        width: 100%;
    }

    @include respond-to(md) {
        padding: $baseline * 12;
    }

    .modal & {
        background: transparent;
        padding: 0;
    }

    .cmp-text {
        margin-bottom: $section-gutter / 2;

        @include respond-to(md) {
            margin-bottom: $section-gutter-md / 2;
        }
    }

    &__inner {
        margin-bottom: $baseline * 4;

        @include respond-to(md) {
            display: table;
            table-layout: fixed;
            margin-bottom: $baseline * 9;
            margin-left: -$gutter-md / 2;
            width: calc(100% + #{$gutter-md});
        }

        @include respond-to(lg) {
            margin-left: -$gutter-lg / 2;
            width: calc(100% + #{$gutter-lg});
        }

        @include respond-to(xl) {
            margin-left: -$gutter-xl / 2;
            width: calc(100% + #{$gutter-xl});
        }
    }

    &__column {
        position: relative;

        @include respond-to(md) {
            display: table-cell;
            padding: 0 $gutter-md / 2;
            vertical-align: bottom;
        }

        @include respond-to(lg) {
            padding: 0 $gutter-lg / 2;
        }

        @include respond-to(xl) {
            padding: 0 $gutter-xl / 2;
        }

        &:not(:first-child) {
            margin-top: $baseline * 4;

            @include respond-to(md) {
                margin-top: 0;
            }
        }
    }

    .quote-form__column--funeral-location-select {
        display: none;
    }

    &--funeral-home,
    &--value-cremation {
        .quote-form__column--service-column {
            display: none;
        }

        .quote-form__inner {
            @include respond-to(md) {
                margin-left: auto;
                margin-right: auto;
                max-width: 900px;
            }
        }
    }

    &--customize {
        .quote-form__column--service-column,
        .quote-form__column--funeral-location {
            display: none;
        }

        .quote-form__inner {
            @include respond-to(md) {
                margin-left: auto;
                margin-right: auto;
                max-width: 600px;
            }
        }
    }

    &--value-cremation {
        .quote-form__column--funeral-location {
            display:none;
        }
        .quote-form__column--funeral-location-select {
            display: block;
        }
    }

    .general-form__label {
        display: none;
    }

    .general-form__select:not(.quote-form__funeral-location-select),
    .general-form__input {
        padding-left: $baseline * 11;
    }

    .general-form__select {
        padding-right: $baseline * 15;
    }

    .general-form__select,
    .general-form__input {
        background: #8f69e0;
        color: $color-01;
        text-align: center;
    }

    .icon {
        color: $color-01;
        font-size: 20px;
        left: $baseline * 6;
        position: absolute;
        top: 11px;

        @include respond-to(md) {
            left: $baseline * 7;
        }

        @include respond-to(xl) {
            left: $baseline * 9;
        }
    }

    .button {
        display: block;
        margin: auto;

        @include respond-to(md) {
            width: calc(33% - #{$gutter-md / 2});
        }
    }

    .general-form__select-wrapper .general-form__select {
        border: 0;
    }
}

// Autocomplete
.pac-container {
    border-radius: 0;
    border: 0;
    font-family: inherit;

    &:after {
        display: none;
    }

    span {
        color: inherit;
        font-size: px-to-rem(14px);
        font-weight: normal;
    }

    .pac-item {
        color: inherit;
        line-height: $baseline * 10;

        span {
            font-weight: 600;
        }
    }

    .pac-icon {
        margin-top: 10px;
    }

    .pac-icon-marker {
        background-position: -17px -161px;
    }

    .pac-matched {
        color: #e65f00;
    }
}
