.service-listing-cta {
    padding: 0;
}

.ups-listing-cta {
    padding: $baseline * 6 $baseline * 5;
    margin-top: $baseline * 12;
    font-size: px-to-rem(14px);
    line-height:  px-to-rem(21px);
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: $baseline * 16;
    border-radius: 16px;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    // overflow: hidden;
    // max-width: 1062px;

    @include respond-to(sm) {
        padding: $baseline * 8;
        margin-top: $baseline * 25;
        font-size: px-to-rem(18px);
        line-height: px-to-rem(26px);
        flex-direction: row;
        justify-content: space-around;
    }

    @include respond-to(lg) {
        margin: 100px 45px;
        width: 93%;
    }
     
    &__title {
        font-size: px-to-rem(18px);
        line-height: px-to-rem(26px);
        word-break: break-word;

        @include respond-to(lg) {
            font-size: px-to-rem(20px);
            line-height: px-to-rem($baseline * 8);
        }
    }

    &__subtitle {
        font-size: px-to-rem(14px);
        line-height: px-to-rem($baseline * 5);

        @include respond-to(lg) {
            font-size: px-to-rem($baseline * 4);
            line-height: px-to-rem($baseline * 6);
        }
    }    
}

.ups-listing-cta__primary {
    margin-bottom: $baseline * 4;
    
    @include respond-to(sm) {
        margin-bottom: 0;
        width: 50%;
    }
}

.ups-listing-cta__secondary {
   
    a {
        width: 84%;
        float: left !important;
        text-transform: none;
        font-weight: 600;
    }

    a:last-child {
        margin-top: $baseline * 3;
    }

    @include respond-to(md) {
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    @include respond-to(lg) {
        a:last-child {
            margin-left: $baseline * 2;
            margin-top: 0;
        }

        a {
            width: auto;
            text-transform: none;
            font-weight: 600;
        }
    }
}