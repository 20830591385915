.aem-GridColumn.section--carousel {
    margin-top: 0;
}

.carousel {
    z-index: index($z-stack, carousel);

    .carousel__slick {
        overflow: hidden;
    }

    .carousel__bar {
        background: #cccccc;
        height: 3px;
        margin-top: -3px;
        left: 0;
        position: absolute;
        right: 0;

        @include respond-to(md) {
            height: 5px;
            margin-top: -5px;
        }
    }

    .carousel__cell .carousel__bar {
        opacity: 0;
    }

    .carousel__cell.slick-active .carousel__bar {
        opacity: 1;
    }

    .carousel__progress {
        background: #c92641;
        height: 100%;
    }

    .slick-arrow {
        appearance: normal;
        background: transparent;
        border: 0;
        bottom: $baseline * 4;
        cursor: pointer;
        display: none !important;
        font-size: 0;
        left: calc(50% - #{$baseline * 2.75});
        padding: $baseline * 2;
        position: absolute;
        transition: top .5s, bottom .5s;
        z-index: 1;

        @include respond-to(md) {
            display: block !important;
        }

        &:after {
            border: 0;
            border-top: 2px solid white;
            border-right: 2px solid white;
            content: '';
            display: block;
            height: $baseline * 2;
            margin-left: -$baseline / 2;
            width: $baseline * 2;
        }
    }

    .slick-prev {
        &:after {
            transform: rotate(-135deg);
        }
    }

    .slick-next {
        &:after {
            transform: rotate(45deg);
        }
    }

    .slick-dots {
        bottom: $baseline * 6;
        left: 0;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        text-align: center;
        transition: top .5s, bottom .5s;
        width: 100%;

        li {
            display: inline-block;
        }

        button {
            appearance: normal;
            background: rgba(white, 0.5);
            border-radius: 50%;
            border: 0;
            cursor: pointer;
            display: block;
            font: 0 sans-serif;
            height: $baseline * 2.5;
            margin: 0 $baseline;
            padding: 0;
            width: $baseline * 2.5;
            vertical-align: bottom;
        }

        .slick-active button {
            border: 2px solid white;
        }
    }
}
