.other-items {
    border-top: 3px solid $color-01;
    padding: $baseline * 12 0;
    position: relative;
    z-index: index($z-stack, segment);

    @include respond-to(md) {
        padding: $baseline * 14 0;
    }

    &__background {
        display: block;
        height: 100%;
        left: 50%;
        margin-left: -75vw;
        position: absolute;
        top: 0;
        width: 150vw;
    }

    &__content {
        position: relative;
        z-index: 1;
    }

    &__list {
        list-style: none;
        margin: $baseline * 8 0 0 $baseline * 23;
        padding: 0;

        @include respond-to(md) {
            column-count: 2;
            margin: $baseline * 12 0 0 $baseline * 23;
        }

        li {
            margin-bottom: $baseline * 2;
        }
    }

    .cmp-text p,
    li {
        @include respond-to(sm) {
            font-size: px-to-rem(18px);
            line-height: px-to-rem($baseline * 6);
        }
    }
}
