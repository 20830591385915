// sass-lint:disable force-pseudo-nesting

$sl-link-title-color: #fff;
$sl-divider-bg-color: #fff;
$sl-link-item-color: #fff;

$wl-link-title-color: #9b1e34;
$wl-divider-bg-color: #ccc;
$wl-link-item-color: #999;


.ll {
    position: relative;

    &__title {
        margin: 0 0 $baseline * 4;
        float: left;

        .simplicity & {
            font-size: px-to-rem(20px);
        }

        .white-lady & {
            font-size: px-to-rem(18px);
        }
    }

    &__states {
        clear: both;
        display: none;
        float: left;
        list-style: none;
        margin: -$baseline * 2 0 $baseline * 4;
        padding: 0;

        @include respond-to(0, sm) {
            font-size: px-to-rem(16px);
        }

        @include respond-to(md) {
            clear: none;
            float: right;
            margin: 0;
        }

        .white-lady & {
            display: block;
        }
    }

    &__state-item {
        float: left;

        &:after {
            content: ' / ';
            display: inline-block;
            float: left;
            margin: 0 $baseline * 2;
            line-height: 1.5rem;
        }

        &:last-child:after {
            display: none;
        }
    }

    &__state-button {
        @extend %button-reset;
        background: none;
        cursor: pointer;
        display: block;
        float: left;
        margin: 0;

        .white-lady & {
            color: $wl-link-item-color;
            font-size: px-to-rem(18px);
        }
    }

    &__state-button--active {
        .white-lady & {
            color: $wl-link-title-color;
        }
    }

    &__states-select {
        display: none;
        float: right;
        position: relative;
        top: -$baseline;

        .simplicity & {
            display: block;
        }
    }

    .general-form__select-wrapper {
        width: auto;
    }

    .general-form__select {
        min-height: auto;
        padding: $baseline $baseline * 12 $baseline $baseline * 4;

        .simplicity & {
            background-color: #f3f3f3;
            border-width: 0;
            color: #009bb5;
            font-weight: 300;
        }
    }

    .general-form__select-wrapper {
        &:before {
            .simplicity & {
                border-color: #00daf9;
                right: 18px;
            }
        }

        &:after {
            .simplicity & {
                bottom: 0;
                border-width: 0;
                right: 0;
                top: 0;
                width: $baseline * 9;
            }
        }
    }

    &__divider {
        border: 0;
        clear: both;
        height: 1px;
        margin: 0 0 $baseline * 5;
        width: 100%;

        .footer & {
            .simplicity & {
                background: $sl-divider-bg-color;
            }

            .white-lady & {
                background: $wl-divider-bg-color;
            }
        }
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;

        &-item {
            font-size: px-to-rem(18px);
            line-height: px-to-rem(24px);
            margin: 0 0 ($baseline * 2);
            padding: 0;

            .white-lady & {
                font-size: px-to-rem(14px);
            }

            .ll--footer-location & {
                float: left;
                width: 50%;
            }

            .section--link-list.aem-GridColumn--default--6 .ll--footer-location & {
                @include respond-to(xl) {
                    width: 33%;
                }
            }
        }
    }

    &__link,
    &__link:focus,
    &__link:hover {
        display: block;
        text-decoration: none;

        .white-lady & {
            text-transform: uppercase;
        }

        .footer & {
            .simplicity & {
                color: $sl-link-title-color;
            }

            .white-lady & {
                color: $wl-link-item-color;
            }
        }

        .ll__title & {
            .footer & {
                .simplicity & {
                    color: $sl-link-title-color;
                }

                .white-lady & {
                    color: $wl-link-title-color;
                }
            }
        }
    }
}
