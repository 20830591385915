/**
 * Funeral planner styles.
 */
.section--complex-header + * .carousel--image-carousel {
    .carousel__content {

        @include respond-to(md) {
            text-align: center;
            width: 80%;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold;
        margin-bottom: $baseline * 4;
    }

    .carousel__slick .slick-dots {
        bottom: $baseline * 5;
        top: auto;

        @include respond-to(0, sm) {
            bottom: auto;
            margin-bottom: $baseline * 4;
            position: relative;
            top: auto !important;
        }
    }

    .slick-dots button {
        background: none;
        border: 1px solid #e65f00;
        height: 14px;
        margin: 0 $baseline * 2.5;
        width: 14px;
    }

    .slick-active button {
        background: #e65f00;
    }

    .slick-arrow {
        height: 38px;
        margin-left: 0 !important;
        overflow: hidden;
        top: calc(50% - 19px) !important;
        padding: 0;
        width: 38px;

        &:after {
            border-top: 4px solid #e65f00;
            border-right: 4px solid #e65f00;
            height: 38px;
            margin-top: -2px;
            width: 38px;
        }
    }

    .slick-prev {
        left: $baseline * 6;

        &:after {
            margin-left: 10px;
        }
    }

    .slick-next {
        left: auto;
        right: $baseline * 6;

        &:after {
            margin-left: -12px;
        }
    }
}
.funeral-planner .carousel .carousel__slick {
      overflow: unset;
      .slick-list {
          overflow: unset;
      }
      .slick-track .carousel__cell {
          overflow: unset;
      }
  }

 .funeral-planner .carousel--hero-carousel .jump-down {
      bottom: -$baseline * 8;
      @include respond-to(md) {
          bottom: -$baseline * 11;
      }
      .icon-arrow-down-o:before {
          content: "\2002";
          border-right: 5px solid #A1A4F2;
          border-bottom: 5px solid #A1A4F2;
          transform: rotate(45deg);
          height: 30px;
          width: 30px;
          border-radius: 5px;
      }
  }
