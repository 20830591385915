.loader {
    margin: auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;

    &__loader-text {
        @include sr-only();
    }
}
