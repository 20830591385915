// sass-lint:disable no-color-literals placeholder-in-extend

.section--button,
.cmp-button {
    text-align: center;

    .contact-form & {
        .simplicity &,
        .white-lady & {
            text-align: right;
        }
    }
}

.button {
    border-style: solid;
    cursor: pointer;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-decoration: none;
    transition: background .4s, border-color .4s, color .4s;

    @include respond-to(0, xs) {
       max-width: 100%;
    }

    .section--complex-header + * & {
        min-width: 150px;
        width: auto;
    }

    .section--complex-header + * .contact-form & {
        width: 100%;
    }

    &[disabled] {
        opacity: .5;
        cursor: default;
    }
}
