.customize-form {
    &__progress {
        width: 100%;

        @include respond-to(sm) {
            display: table;
        }
    }

    &__progress-label,
    &__progress-bar-container {
        position: relative;

        @include respond-to(sm) {
            display: table-cell;
        }
    }

    &__progress-label {
        font-weight: bold;
        margin-bottom: $baseline * 14;
        text-transform: uppercase;

        @include respond-to(sm) {
            margin-bottom: $baseline * 6;
            padding-right: $baseline * 6;
            white-space: nowrap;
            width: 1px;
        }
    }

    &__progress-bar {
        border-width: 4px;
        border-style: solid;
        border-radius: 18px;
        height: 18px;
        width: 100%;
    }

    &__progress-bar-filled {
        height: 100%;
        transition: width .3s;
    }

    &__progress-percent {
        border-radius: $baseline * 3;
        bottom: calc(100% + #{$baseline});
        color: $color-01;
        font-weight: bold;
        padding: $baseline * 1.5 $baseline * 2;
        position: absolute;
        transform: translateX(-50%);
        transition: left .3s;

        &:after {
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-top-width: 3px;
            border-top-style: solid;
            content: '';
            display: block;
            height: 0;
            left: calc(50% - 3px);
            position: absolute;
            top: 100%;
            width: 0;
        }
    }

    &__question-container {
        background: rgba(232, 244, 245, 0.5);
        border: 5px solid $color-01;
        border-radius: 10px;
        margin-top: $baseline * 4;
        padding: $baseline * 4 $baseline * 4;

        @include respond-to(sm) {
            padding: $baseline * 5 $baseline * 7;
        }

        @include respond-to(md) {
            padding: $baseline * 8 $baseline * 4;
        }
    }

    &__question-heading {
        font-size: px-to-rem(20px);
        font-weight: bold;
        line-height: px-to-rem(24px);
        margin-bottom: $baseline * 4;
        text-transform: uppercase;
    }

    &__items {
        width: 100%;

        &:after {
            clear: both;
            content: '';
            display: block;
        }
    }

    &__item {
        display: inline-block;
        vertical-align: top;
        width: 100%;

        @include respond-to(md) {
            width: 33%;
        }
    }

    &__button-radio {
        opacity: 0;
        position: absolute;

        & + .button {
            margin-bottom: $baseline * 4;
            padding: $baseline * 7 $baseline * 5;
            width: 100%;

            @include respond-to(md) {
                margin-bottom: $baseline * 6;
                width: calc(100% - #{$baseline * 5});
            }
        }
    }

    &__item:last-child .button {
        @include respond-to(0, sm) {
            margin-bottom: $baseline * 6;
        }
    }

    .general-form__label {
        margin-bottom: $baseline * 4;
        &:before {
            top:0;
        }
        &:after {
            top: 6px;
        }
    }

    .general-form__element--radio,
    .general-form__element--checkbox {
        padding-right: $baseline * 6;
    }

    &__textarea-container,
    &__dropdown-container  {
        margin-bottom: $baseline * 6;
    }

    &__dropdown-container {
        width: 100%;
        @include respond-to(md) {
            width: 33%;
        }
    }

    &__textarea {
        min-height: auto;

        &[disabled="disabled"] {
            opacity: 0.5;
        }
    }

    &__action-bar {
        margin-top: $baseline * 12;
        width: 100%;

        @include respond-to(sm) {
            display: table;
            margin-top: $baseline * 22;
        }
    }

    &__action-col {
        @include respond-to(sm) {
            display: table-cell;
            vertical-align: middle;
        }
    }

    &__action-col--button {
        @include respond-to(sm) {
            white-space: nowrap;
            width: 1px;
        }

        &:first-child .button {
            background: #777;
            color: $color-01;
        }
    }

    &__mandatory {
        display: inline-block;
        font-weight: bold;
        font-size: px-to-rem(14px);
        font-style: italic;
        margin: $baseline * 4 0;
        padding: 0 $baseline * 4;
        text-align: center;
        width: 100%;

        @include respond-to(sm) {
            margin: 0;
            padding: 0 $baseline * 7;
            text-align: right;
        }
    }

    .customize-form__previous,
    .customize-form__next {
        width: 100%;
    }
}
