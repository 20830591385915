body {
    margin: 0;
}

h1,
body h1 { // Hack to get around coral overwriting styles in author.
    font-size: px-to-rem(30px);
    line-height: px-to-rem($baseline * 9);
    margin: 0 0 $baseline * 4 0;

    @include respond-to(md) {
        font-size: px-to-rem(50px);
        line-height: px-to-rem($baseline * 13);
        margin: 0 0 $baseline * 4 0;
    }

    a {
        text-decoration: none;
    }
}

h2 {
    font-size: px-to-rem(30px);
    line-height: px-to-rem($baseline * 9);
    margin: 0 0 $baseline * 9 0;

    @include respond-to(md) {
        font-size: px-to-rem(36px);
        line-height: px-to-rem($baseline * 10);
        margin: 0 0 $baseline * 10 0;
    }

    a {
        text-decoration: none;
    }

    .white-lady & {
        font-size: px-to-rem(26px);
        line-height: px-to-rem($baseline * 9);

        @include respond-to(md) {
            font-size: px-to-rem(30px);
            line-height: px-to-rem($baseline * 10);
        }
    }
}

.h3,
h3 {
    font-size: px-to-rem(22px);
    line-height: px-to-rem($baseline * 6);
    margin: 0 0 $baseline * 6 0;
}

h5 {
    font-size: px-to-rem(14px);
    line-height: px-to-rem($baseline * 4);
}

h6 {
    font-size: px-to-rem(12px);
    line-height: px-to-rem($baseline * 4);
}

h4,
h5,
h6 {
    margin: 0 0 $baseline * 1 0;
    text-transform: uppercase;
}

p {
    margin: 0 0 $baseline * 4 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    &:last-child {
        margin-bottom: 0;
    }
}

h1,
h2,
h3 {
    @media print {
        font-size: 18px;
        line-height: 1.5;
        margin-bottom: 4px;
    }
}

h4,
h5,
h6 {
    @media print {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 4px;
    }
}

li,
p {
    @media print {
        font-size: 14px;
        line-height: 1.5;
        margin-top: 0 !important;
    }
}

// AEM styles
.blockquote {
    font-style: italic;
    padding-left: $baseline * 7;
}

.small {
    display: block;
    font-size: px-to-rem($baseline * 3);
    line-height: px-to-rem(18px);
}

.lead {
    font-size: px-to-rem($baseline * 5);
    line-height: px-to-rem($baseline * 7);
}
