.search-form {
    [class*='section--'] {
        float: left;
        margin-top: 8px;
        margin-bottom: 8px;
        width: 100%;
    }

    &:not(.search-form--has-state-filter) {
        margin-left: 0;
        width: 100%;

        [class*='section--'] {
            .simplicity &,
            .white-lady & {
                float: none;
                margin-top: 0;
                margin-bottom: 0;
                width: auto;
            }
        }
    }

    .general-form__label {
        display: block;
        text-align: center;
        margin-bottom: $baseline * 4;
    }

    &--has-state-filter {
        .general-form__label {
            display: block;
            margin-bottom: $baseline * 3;
            text-align: left;
        }
    }

    &--service,
    &--combined {
        .general-form__label {
            display: none;
        }
    }

    &--no-state-filter {
        .search-form__aem-query-input {
            @include respond-to(lg) {
                margin-left: 25%;
                text-align: center;
            }
        }

        .search-form__aem-state-filter {
            display: none;
        }

        .search-form__aem-branch-button {
            @include respond-to(lg) {
                width: 100% !important;
            }
        }
    }


    .modal & {
        .simplicity & {
            padding-bottom: 4px;
            padding-top: 4px;
        }
    }

    &:not(.search-form--has-state-filter) .general-form__element--input {
        // TODO: move below to searh-form.scss
        .simplicity &,
        .white-lady & {
            @include respond-to(md) {
                float: left;
                padding-right: 30px;
            }
        }

        .simplicity & {
            @include respond-to(md) {
                width: calc(100% - #{$sf-button-width-sl-desktop});
            }
        }

        .white-lady & {
            @include respond-to(md) {
                width: calc(100% - #{$sf-button-width-wl-desktop});
            }
        }

        .modal & {
            .simplicity & {
                float: none;
                width: auto;
            }
        }
    }

    &:not(.search-form--has-state-filter) .general-form__input {
        .modal & {
            .simplicity & {
                background: #f3f3f3;
            }
        }
    }

    &:not(.search-form--has-state-filter) .button {
        .simplicity &,
        .white-lady & {
            @extend .icon, .icon-fa-search;

            @include respond-to(null, sm) {
                font-size: 0;
                margin: 0;
                position: absolute;
                top: $section-gutter / 2 - $gutter-tight / 2;
                right: 0;
            }

            &:before {
                @include respond-to(null, sm) {
                    display: block;
                    font-size: 1.25rem;
                    left: calc(50% - 10px);
                    line-height: 1.5rem;
                    position: absolute;
                }

                @include respond-to(md) {
                    display: none;
                }
            }
        }

        .simplicity & {
            width: $sf-button-width-sl-mobile;

            @include respond-to(null, sm) {
                background-color: #fff;
                border-color: #005170;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                color: #005170;
            }

            @include respond-to(md) {
                width: $sf-button-width-sl-desktop;
            }
        }

        .white-lady & {
            width: $sf-button-width-wl-mobile;

            @include respond-to(md) {
                width: $sf-button-width-wl-desktop;
            }
        }

        .modal & {
            .simplicity & {
                @extend .icon, .icon-fa-search;
                background-color: #fff;
                border-color: #005170;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: 0;
                color: #005170;
                font-size: 0;
                margin: 0;
                position: absolute;
                top: $section-gutter / 2 - $gutter-tight / 2;
                right: 0;
                width: $sf-button-width-sl-mobile;

                &:before {
                    color: #00DAF9;
                    display: block;
                    font-size: 1.25rem;
                    left: calc(50% - 10px);
                    line-height: 1.5rem;
                    position: absolute;
                }
            }
        }
    }
}

.js-search-form--has-state-filter {
    display: none;
}
