.branch {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    width: 100%;

    &__info-container,
    &__map-container {
        padding-bottom: $baseline * 10;
        padding-top: $baseline * 10;
    }

    &__heading {
        font-size: px-to-rem(23px);
        font-weight: 400;
        line-height: px-to-rem($baseline * 6);
        text-transform: none;

        a {
            text-decoration: none;
        }
    }

    &__info-container {
        .google-map {
            margin: $baseline * 6 0;

            @include respond-to(sm) {
                display: none;
            }
        }
    }

    p,
    &__row {
        font-size: px-to-rem(14px);
        line-height: px-to-rem($baseline * 5);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__heading,
    p,
    &__row {
        margin-bottom: $baseline * 3;
    }

    dl,
    dt,
    dd {
        padding: 0;
        margin: 0;
    }

    &__row {
        display: table;
    }

    dt {
        font-weight: 500;
        width: 100px;
    }

    dt,
    dd {
        display: table-cell;
        vertical-align: top;
    }

    .branch__map-container {
        display: none;

        @include respond-to(sm) {
            display: block;
        }
    }
}
