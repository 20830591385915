$color-01: #fff;
$color-02: #000;
$color-03: #444;
$color-04: #666;
$color-05: #fafafa;
$color-06: #00aeef;
$color-07: #bbb;


// Complex header
$complex-header-bg-color: #1f8f99;

// Footer
$footer-copyright-bg-color: $color-02;
$footer-back-to-top-bg-color: $color-03;
$footer-back-to-top-fg-color: $color-01;

// General image
$general-image-color: $color-04;

// Modal
$modal-overlay-bg-color: $color-02;
$modal-dialog-bg-color: $color-01;

// Funeral planner
$dark-button-bg: #b34900;
