.customize-summary {
    background: $color-01;
    border: 1px solid $color-02;
    border-radius: 4px;

    &__heading {
        font-size: px-to-rem(22px);
        margin: 0;
        padding: $baseline * 6 $baseline * 5;
        text-align: center;
    }

    &__list {
        list-style: none;
        padding: 0 $baseline * 5;
        margin: 0;

        @include respond-to(lg) {
            -webkit-overflow-scrolling: touch;
            max-height: 500px;
            overflow-y: auto;
        }
    }

    &__item {
        margin: 0;
        padding: 0;
        position: relative;
    }

    &__question {
      padding-right: $baseline * 6;
        font-size: px-to-rem(18px);
        font-weight: bold;
        line-height: px-to-rem(22px);
        margin-bottom: $baseline;
        text-transform: uppercase;
    }

    &__answer {
        padding-right: $baseline * 6;
        margin-bottom: $baseline * 6;
    }

    &__edit {
        position: absolute;
        right: 0;
        text-decoration: none;
        top: 0;
    }
}
