.search-group {
    &__tabs {
        margin: 0;
        padding: 0;
    }

    &__tabs {
        list-style: none;
        width: 100%;

        @include respond-to(md) {
            display: table;
        }
    }

    &__tab {
        @include respond-to(0, sm) {
            margin-bottom: $baseline / 2;
        }

        @include respond-to(md) {
            display: table-cell;
            width: 33%;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--hidden {
            display: none;
        }
    }

    .button {
        width: calc(100% - 2px);
    }

    .search-result {
        display: none;
    }

    .search-result__warning--no-results {
        text-align: center;
    }
}
