@mixin chevron($direction, $color) {
    border-top: 2px solid $color;
    border-left: 2px solid $color;
    content: '';
    display: block;
    height: $baseline * 2;
    transform-origin: center;
    transform: rotate(45deg);
    width: $baseline * 2;

    @if $direction == up {
        margin: (-$baseline) (-$baseline - 1) 0 (-$baseline - 1);
        transform: rotate(45deg);
    }

    @if $direction == down {
        margin: (-$baseline - 3px) (-$baseline - 1) 0 (-$baseline - 1);
        transform: rotate(-135deg);
    }
}
