.customization-heading {
    margin-bottom: 1px;
    padding-bottom: $baseline * 13;
    padding-top: $baseline * 13;
    position: relative;

    @include respond-to(md) {
        padding-bottom: $baseline * 16;
        padding-top: $baseline * 16;
    }

    &__background {
        height: 100%;
        left: 50%;
        margin-left: -50vw;
        position: absolute;
        top: 0;
        width: 100vw;
    }

    &__inner {
        position: relative;
    }
}
