$pf-slick-dot: 12px;
$pf-slick-arrow-height: 76px;
$pf-slick-arrow-width: 64px;
$pf-box-shadow: rgba($color-02, .2);

.package-filter {
    position: relative;

    &:after {
        content: '';
        clear: both;
        display: block;
    }

    &__label {
        display: block;
        margin: 0 0 ($baseline * 6);
        text-align: center;

        @include respond-to(sm) {
            margin: 0 auto ($baseline * 6);
            width: 50%;
        }

        &-text {
            display: block;
            font-size: px-to-rem(26px);
            line-height: px-to-rem(32px);
            margin: 0 0 $baseline * 8 0;
        }

        .package-filter--hide-heading & {
            display: none;
        }
    }

    &__help-text {
        text-align: center;

        .package-filter--hide-heading & {
            display: none;
        }
    }

    &__packages {
        margin-top: $baseline * 15;

        transition: filter .4s linear;

        @include respond-to(md) {
            margin-left: -($baseline * 5);
            margin-right: -($baseline * 5);
        }

        &--blured {
            opacity: .5;
            filter: blur(10px);
        }
    }

    &__prompt {
        background: $color-01;
        box-shadow: 0 0 20px $pf-box-shadow;
        display: none;
        text-align: center;
        margin-left: -160px;
        padding: $baseline * 5;
        width: 320px;

        position: absolute;
        top: 20%;
        left: 50%;

        .package-filter__packages--blured + & {
            display: block;
        }
    }

    &__arrow {
        height: $pf-slick-arrow-height;

        position: absolute;
        z-index: 1;
        top: 50vh;

        &--prev {
            left: -32px;

            @include respond-to(md) {
                left: -$pf-slick-arrow-width;
            }
        }

        &--next {
            right: 32px;

            @include respond-to(md) {
                right: 0;
            }
        }
    }

    .package-listing {
        @include respond-to(sm) {
            margin-left: $baseline * 5;
            margin-right: $baseline * 5;
            max-width: 400px;
            transition: opacity .35s linear;
        }

        &__container {
            width: 100%;
            max-width: 375px;
            margin: auto;

            @include respond-to(sm) {
                max-width: none;
            }
        }
    }

    // Slick JS styles
    .slick {
        &-track {
            margin: 0 auto;
        }

        &-arrow {
            appearance: none;
            background-color: transparent;
            border: 0;
            font-size: px-to-rem(24px);
            height: $pf-slick-arrow-height;
            line-height: $pf-slick-arrow-height;
            margin: 0;
            overflow: hidden;
            padding: 0;
            width: $pf-slick-arrow-width;
            position: absolute;

            &-shape {
                height: 100%;
                opacity: .6;
                position: absolute;
                z-index: -1;
                top: 0;

                @include respond-to(md) {
                    opacity: 1;
                }

                @include respond-to(null, sm) {
                    transform: scaleX(-1);
                }
            }

            &--fixed {
                position: fixed;
                top: 50vh;
            }
        }

        &-prev {
            .slick-arrow-shape {
                right: 0;

                @include respond-to(md) {
                    right: -6px;
                }
            }
        }

        &-next {
            .slick-arrow-shape {
                left: 0;

                @include respond-to(md) {
                    left: -6px;
                }
            }
        }

        &-dots {
            font-size: 0;
            list-style: none;
            margin: 0;
            padding: 0;
            text-align: center;

            li {
                display: inline-block;
                line-height: $pf-slick-dot;
                margin: 0;
                padding: 20px 10px 0;
                opacity: .5;

                &.slick-active {
                    opacity: 1;
                }

                button {
                    appearance: none;
                    border: 0;
                    border-radius: 50%;
                    font-size: 0;
                    line-height: 0;
                    padding: 0;
                    margin: 3px;
                    height: $pf-slick-dot;
                    vertical-align: middle;
                    width: $pf-slick-dot;
                }
            }
        }
    }
}
