$admin-toolbar-bkg-color: #322D2A;

// Admin toolbar
.ups-admin-toolbar {
    background: $admin-toolbar-bkg-color;
    color: #fff;
    display: flex;
    align-items: center;
    position: relative;

    @include respond-to(md) {
        padding-top: $baseline * 3;
        padding-bottom: $baseline * 3;
    }

    &::before {
        content: '';
		display: block;
		background: $admin-toolbar-bkg-color;
		width: 8.7%;
		height: 100%;
		position: absolute;
        left: 100%;
        z-index: 2;
        overflow: hidden;

        @include respond-to(lg) {
            width: 100%;
        }
    }

    &::after {
        content: '';
		display: block;
		background: $admin-toolbar-bkg-color;
		width: 100%;
		height: 100%;
		position: absolute;
        right: 100%;
        z-index: 2;
    }
}

.ups-admin-btn {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;
    color: #fff;
    padding: $baseline * 2 $baseline * 4;
    border-radius: 2px;
    margin-left: $baseline * 2;
    font-size: px-to-rem(14px);
    font-weight: 600;
    border: 0;
    height: 34px;

    @include respond-to(lg) {
        height: 48px;
        margin-left: $baseline * 3;
    }

    &--red {
        background: #D60000;
    }

    &--green {
        background: #3F840B;
    }

    &--grey {
        background: #E3E3E3;
        color: initial;
    }
    
    @include respond-to(md) {
        height: 48px;
    }
}

.ups-icon {
    @include respond-to(sm) {
        margin-right: $baseline * 2;
        transform: translateY(1px);
    }
}

.ups-admin-btn--inline {
    background: transparent;
    white-space: nowrap;
    padding-left: $baseline;

    span {
        vertical-align: middle;
    }

    .ups-icon {
        margin-right: $baseline;
    }
}

.ups-admin-btn__text {
    display: none;

    @include respond-to(sm) {
        display: inline-block;
    }
}

.ups-admin-toolbar-left {
    display: flex;
    flex: 1;
    justify-content: space-between;
    
    @include respond-to(sm) {
        justify-content: initial;
    }
}

.ups-admin-toolbar-right {
    display: flex;
    justify-content: space-between;
    padding: $baseline * 3;

    @include respond-to(lg) {
        padding: 0;
    }
}