.section--header {
    padding-bottom: $header-height-md;
}

.header {
    position: fixed;
    left: 0;
    right: 0;
    background-color: $color-01;
    z-index: index($z-stack, header);

    &__inner {
        @include clear();
        @extend %container;
        padding: $baseline * 5 0 $baseline * 12;

        @include respond-to(md) {
            padding: 0;
        }
    }

    // Logo
    &__logo-container {
        float: left;

        @include respond-to(md) {
            display: table;
            height: $header-height-md;
        }
    }

    &__logo-anchor {
        @include respond-to(md) {
            display: table-cell;
            vertical-align: middle;
        }
    }

    &__logo {
        display: none;
        max-height: 50px;

        @include respond-to(lg) {
            display: block;
        }

        &--small {
            display: block;
            max-height: 32px;

            @include respond-to(lg) {
                display: none;
            }
        }
    }

    // Action bar
    &__action-bar {
        display: block;
        float: right;

        &--desktop {
            display: none;
        }

        @include respond-to(md) {
            display: block;
            position: fixed;
            right: 0;
            top: calc(50% - #{$baseline * 24});

            [class*='aem-AuthorLayer'] &,
            &--mobile {
                display: none;
            }
        }
    }

    &__action-bar-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__action-bar-item {
        float: left;
        margin-left: $baseline * 3;

        @include respond-to(sm) {
            margin-left: $baseline * 5;
        }

        @include respond-to(md) {
            @include clear();
            float: none;
        }

        // Need to hide when .hide-sticky-nav is on a parent as header is from SSI include and can't
        // be modified by AEM.
        .hide-sticky-nav &:not(.header__action-bar-toggle-nav) {
            display: none;
        }

        .header__anchor {
            appearance: normal;
            border: none;
            border-radius: 0;
            cursor: pointer;
            display: block;
            font-family: inherit;
            font-size: inherit;
            font-style: inherit;
            font-weight: inherit;
            padding: 0;

            @include respond-to(md) {
                @include clear();
                float: right;
                padding: $baseline * 3.5 $baseline * 4;
                margin-bottom: $baseline;
                transition: width .3s, background .3s;
                width: $baseline * 40;

                &:hover {
                    width: $baseline * 41;
                }
            }

            .header__action-bar--mobile & {
                background: transparent;
            }

            &--toggle-nav {
                @extend %button-reset;
                background: transparent;
                color: inherit;
                height: $baseline * 8;
                position: relative;
                width: $baseline * 5;
            }
        }
    }

    // Need to hide nav when .hide-header-nav is on a parent as header is from SSI include and can't
    // be modified by AEM.
    &__action-bar-toggle-nav {
        .hide-header-nav & {
            display: none;
        }
    }

    &__anchor-text,
    &__anchor-icon {
        display: block;
        line-height: $baseline * 8;
    }

    &__anchor-text {
        float: left;
        font-size: px-to-rem(12px);
        margin-right: $baseline * 5;
        text-transform: uppercase;

        @include respond-to(0, sm) {
            @include sr-only();
        }
    }

    &__anchor-icon {
        font-size: 28px;

        @include respond-to(md) {
            float: right;
        }

        &:before {
            display: block;
        }

        .header__anchor--toggle-nav & {
            font-size: 20px;
        }

        &.icon-cross,
        &.icon-menu {
            left: 0;
            position: absolute;
            top: 0;
            transition: opacity .3s, transform .3s;
            transform-origin: 50% 50%;
        }

        &.icon-cross {
            opacity: 0;
            transform: rotateX(180deg);

            [aria-pressed="true"] & {
                opacity: 1;
                transform: rotateX(0deg);
            }
        }

        &.icon-menu {
            opacity: 1;
            transform: rotateX(0deg);

            [aria-pressed="true"] & {
                opacity: 0;
                transform: rotateX(180deg);
            }
        }
    }

    // Nav bar
    &__nav-bar {
        @include respond-to(md) {
            @include clear();
            float: right;
        }
    }

    // Nav
    &__nav {
        background-color: $color-01;
        display: none;
        left: 0;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 1;

        // Need to hide nav when .hide-header-nav is on a parent as header is from SSI include and can't
        // be modified by AEM.
        .hide-header-nav & {
            display: none !important; // Overwrite display none from jQuery slideDown.
        }
    }

    .page,
    &__nav {
        @include respond-to(md) {
            background: transparent;
            color: inherit;
            display: block !important; // Overwrite display none from jQuery slideDown.
            float: left;
            position: static;
            vertical-align: middle;
            width: auto;
        }
    }

    &__nav-list {
        @extend %container;
        list-style: none;
        margin-top: 0;
        margin-bottom: 0;
        padding: $baseline * 6 0;

        @include respond-to(md) {
            @include clear();
            margin: 0;
            padding: 0;
            white-space: nowrap;
        }
    }

    .header__nav-list {
        @include respond-to(0, sm) {
            margin: 0;
            width: 100%;
        }
    }

    &__nav-item {
        position: relative;

        @include respond-to(md) {
            display: table;
            float: left;
            height: $header-height-md;
        }

        .header__anchor {
            color: inherit;
            display: block;
            font-size: px-to-rem(14px);
            font-weight: 600;
            line-height: px-to-rem($baseline * 4);
            padding: $baseline * 3 $baseline * 12 $baseline * 3 $baseline * 7;
            text-transform: uppercase;
            vertical-align: middle;

            @include respond-to(md) {
                display: table-cell;
                padding: 3px 0 0 0;
            }
        }
    }

    &__expand-button {
        @extend %button-reset;
        background: transparent;
        height: $baseline * 10;
        position: absolute;
        right: 0;
        top: 0;
        width: $baseline * 10;

        @include respond-to(md) {
            display: none !important // Overwrite jQuery slide down.;
        }

        &:after {
            border-top: 1px solid $color-02;
            border-right: 1px solid $color-02;
            content: '';
            display: block;
            height: $baseline * 1;
            position: absolute;
            top: calc(50% - #{$baseline / 2});
            right: $baseline * 7;
            transform: rotate(45deg);
            transition: .3s transform;
            width: $baseline * 1;

            .header__nav-item--expanded > & {
                transform: rotate(135deg);
            }
        }

        .header__child-list & {
            height: 25px;
            top: 0;
        }
    }

    &__child-list {
        display: none;
        list-style: none;
        margin: -$baseline 0 0 0;
        padding: 0 0 $baseline * 2 0;

        @include respond-to(md) {
            background: $color-01;
            left: $baseline * 1.5;
            margin: 0;
            padding: 0;
            position: absolute;
            top: 72px;
            width: 180px;
        }

        .header__anchor {
            padding: $baseline * 1.5 $baseline * 12 $baseline * 1.5 $baseline * 11;

            @include respond-to(md) {
                padding: $baseline * 2 $baseline * 2.5;
                display: block;
                font-size: px-to-rem(12px);
                white-space: normal;
                width: 100%;
            }
        }
    }

    &__child-item {
        position: relative;
    }

    // Telephone number
    &__nav-item,
    &__telephone-container {
        @include respond-to(md) {
            padding-left: $baseline * 2;
        }

        @include respond-to(lg) {
            padding-left: $baseline * 4;
        }
    }

    &__telephone-container {
        bottom: 0;
        display: block;
        left: 0;
        position: absolute;
        text-align: center;
        width: 100%;

        @include respond-to(md) {
            clear: none;
            display: table;
            float: left;
            height: $header-height-md;
            position: static;
            text-align: left;
            width: auto;
        }

        a {
            display: block;
            font-size: px-to-rem(18px);
            font-weight: 500;
            line-height: px-to-rem($baseline * 5);
            padding: $baseline * 3.5 0;
            speak-numeral: digits;
            text-decoration: none;

            @include respond-to(md) {
                display: table-cell;
                font-size: px-to-rem(20px);
                font-weight: normal;
                padding: 0 0 $baseline / 2 0;
                vertical-align: middle;
            }
        }
    }

    &__anchor {
        text-decoration: none;
    }
}
