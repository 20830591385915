$carousel-height-md: calc(100vh - #{$header-height-md});

 .carousel--hero-carousel {
    max-height: 1600px;
    opacity: 0;

    &.carousel--loaded {
        opacity: 1;
    }

    @include respond-to(0, sm) {
        margin-left: -$gutter;
        width: calc(100% + #{$gutter * 2});
    }

    @include respond-to(md) {
        min-height: 500px;
        position: relative;
        width: 100vw;

        @include respond-to(md) {
            left: calc(#{$container-md} / 2 - 50vw);
        }

        @include respond-to(lg) {
            left: calc(#{$container-lg} / 2 - 50vw);
        }

        @include respond-to(xl) {
            left: calc(#{$container-xl} / 2 - 50vw);
        }
    }

    .carousel__slick,
    .slick-track,
    .carousel__content {
        height: 100% !important;
    }

    .slick-track {
        display: table;
    }

    .carousel__cell {
        display: table-cell !important;
        overflow: hidden;
        position: relative;
        height: $carousel-height-md;
        max-height: 2000px;

        [class*='aem-AuthorLayer'] & {
            max-height: 700px;
        }

        &--has-shadow {
            text-shadow: 0 0 15px rgba(0, 0, 0, .7);
        }

        .simplicity & {
            height: calc(100vh - #{$header-height-simplicity});
        }

        .white-lady & {
            height: calc(100vh - #{$header-height-white-lady});
        }

        @include respond-to(md) {
            .simplicity & {
                height: calc(100vh - #{$header-height-simplicity-md});
            }

            .white-lady & {
                height: calc(100vh - #{$header-height-white-lady-md});
            }
        }
    }

    .carousel__background,
    .carousel__overlay,
    .carousel__watermark-image {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .carousel__background {
        background-position: center center;
        background-size: cover;

        &--mobile {
            @include respond-to(md) {
                display: none;
            }
        }

        &--desktop {
            display: none;

            @include respond-to(md) {
                display: block;
            }

            @include respond-to(0, md) {
                height: 100% !important;
                transform: none !important;
            }
        }
    }

    .carousel__overlay {
        opacity: 0.4;
    }

    .carousel__watermark-image {
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: 45%;
        opacity: 0.1;
    }

    .carousel__content {
        display: table;
        position: relative;
        z-index: 1;

        @include respond-to(md) {
            width: 100%;
        }
    }

    .carousel__content-inner {
        display: table-cell;
        padding: $baseline * 15 0 $baseline * 32 0;
        text-align: center;
        vertical-align: middle;

        @include respond-to(md) {
            padding: $baseline * 32 0 $baseline * 32 0;
        }
    }

    .carousel__content--align-top .carousel__content-inner {
        vertical-align: top;
    }

    .carousel__content--align-middle .carousel__content-inner {
        vertical-align: middle;
    }

    .carousel__content--align-bottom .carousel__content-inner {
        vertical-align: bottom;
    }

    .carousel__content-contained {
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        @include respond-to(md) {
            width: 67%;
        }

        @include respond-to(lg) {
            width: 80%;
        }

        p {
            @include respond-to(0, sm) {
                font-size: px-to-rem(16px);
            }
        }
    }

    .carousel__content--align-left .carousel__content-contained {
        margin-left: 0;
    }

    .carousel__content--align-right .carousel__content-contained {
        margin-right: 0;
    }

    .section--button {
        margin-top: $baseline * 10;
    }

    .button {
        text-shadow: none;
    }

    .jump-down {
        bottom: $baseline * 13;
        font-size: $baseline * 16;
        left: 50%;
        margin-left: -$baseline * 8;
        position: absolute;
        width: $baseline * 16;

        @include respond-to(md) {
            bottom: $baseline * 15;
        }
    }
}
