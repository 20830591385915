%button-reset {
    appearance: normal;
    border: 0;
    border-radius: 0;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    padding: 0;
}
