 .carousel--image-carousel {
    position: relative;

    p {
        font-size: px-to-rem(16px);
        line-height: px-to-rem($baseline * 7);

        @include respond-to(md) {
            font-size: px-to-rem(20px);
        }
    }

    .carousel__image {
        width: 100%;

        &--desktop {
            display: none;

            @include respond-to(md) {
                display: block;
            }
        }

        &--mobile {
            @include respond-to(md) {
                display: none;
            }
        }
    }

    .carousel__cell {
        position: relative;
    }

    .carousel__content {
        max-width: 100%;

        @include respond-to(md) {
            position: absolute;
            width: 560px;
        }
    }

    .carousel__content--align-top {
        @include respond-to(md) {
            top: $baseline * 16;
        }
    }

    .carousel__content--align-middle {
        @include respond-to(md) {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .carousel__content--align-bottom {
        @include respond-to(md) {
            bottom: $baseline * 16;
        }
    }

    .carousel__content--align-left {
        @include respond-to(md) {
            left: $baseline * 16;
        }
    }

    .carousel__content--align-center {
        @include respond-to(md) {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .carousel__content--align-right {
        @include respond-to(md) {
            right: $baseline * 16;
        }
    }

    .carousel__content--align-center.carousel__content--align-middle {
        @include respond-to(md) {
            transform: translate(-50%, -50%);
        }
    }

    .carousel__content-align {
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - #{$gutter * 2});

        @include respond-to(md) {
            width: 67%;
        }

        @include respond-to(lg) {
            width: 80%;
        }
    }

    .carousel__content--align-left .carousel__content-contained {
        margin-left: 0;
    }

    .carousel__content--align-right .carousel__content-contained {
        margin-right: 0;
    }

    .carousel__text-container {
        position: relative;
    }

    .carousel__text-background {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        @include respond-to(md) {
            opacity: .9;
        }
    }

    .cmp {
        padding: $baseline * 7;
        position: relative;
    }

    .button {
        border-radius: 0;
        border-bottom-right-radius: $baseline * 4;
        border: 0;
        float: right;
    }
}
