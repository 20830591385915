%container {
    margin-left: $gutter;
    margin-right: $gutter;
    width: calc(100% - #{$gutter * 2});

    @include respond-to(md) {
        margin-left: auto;
        margin-right: auto;
        max-width: $container-md;
        width: 100%;
    }

    @include respond-to(lg) {
        max-width: $container-lg;
    }

    @include respond-to(xl) {
        max-width: $container-xl;
    }
}
