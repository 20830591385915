.section--product {
    @include respond-to(0, sm) {
        width: 100% !important;  // Overwrite grid.
    }
    @include respond-to(sm, sm) {
        width: 50% !important;  // Overwrite grid.
    }
}

.product {
    $selected-color: #0e6c8b;
    $selected-color-hover: #1390b9;

    @extend %funeral-planner-border;
    overflow: hidden;
    position: relative;
    text-align: center;

    &:hover {
        .product__image-content {
            opacity: 1;
        }
    }

    &__radio {
        opacity: 0;
        position: absolute;

        &:checked + .product__content .button {
            background-color: $selected-color;
        }

        &:checked:hover + .product__content .button {
            background-color: $selected-color-hover;
        }

        &:focus + .product__content .button {
            outline: $selected-color solid 2px;
        }
    }

    &__label {
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__image {
        background-color: $color-01;
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 100%;
        height: 172px;
        position: relative;
        width: 100%;
        width: calc(100% + 2px);
    }

    &__image-content {
        display: table;
        height: 100%;
        left: 0;
        opacity: 0;
        padding: 0 $baseline * 3;
        position: absolute;
        top: 0;
        transition: opacity .3s;
        width: 100%;
    }

    &__image-content-inner {
        display: table-cell;
        vertical-align: middle;
    }

    &__title {
        color: $color-01;
        font-size: px-to-rem(18px);
        font-weight: bold;
        line-height: px-to-rem($baseline * 5);
        max-height: px-to-rem($baseline * 12);
        overflow: hidden;
        padding: $baseline 0;
        text-transform: uppercase;

        .product__image-content-inner & {
            background: transparent;
            color: $selected-color;
        }
    }

    .button {
        border-radius: 4px;
        display: block;
        font-size: px-to-rem(15px);
        margin: $baseline * 3 $baseline * 6;
        min-width: 150px;
    }
}
