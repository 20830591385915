.footer {
    &__heading,
    &__list li,
    &__sitemap p {
        font-size: px-to-rem(14px);
        line-height: px-to-rem(22px);
    }

    &__sitemap {
        .cmp-text {
            @include respond-to(md) {
                margin-bottom: $baseline * 8;
            }
        }

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__list-container {
        align-items: top;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @include respond-to(lg) {
            justify-content: center;
        }

        @media print {
            display: none !important;
        }
    }

    &__list-column {
        margin-top: $baseline * 8;
        width: 100%;

        @include respond-to(sm) {
            width: 25%;

            &--two-col:first-child {
                width: 50%;
            }

            &--three-col:first-child {
                width: 75%;
            }
        }

        @include respond-to(md) {
            width: auto;

            &:first-child {
                width: auto;
            }
        }

        &--no-children {
            @include respond-to(0, xs) {
                width: 50%;
            }
        }
    }

    &__heading {
        font-family: inherit;
        font-weight: bold;
        margin: 0 0 $baseline * 1 0;
        text-transform: uppercase;

        a {
            color: inherit;
        }
    }

    &__list {
        column-count: 2;
        column-gap: 0;
        list-style: none;
        margin: 0;
        padding: 0;

        @include respond-to(sm) {
            column-count: 1;
        }

        .footer__list-column--two-col:first-child & {
            @include respond-to(sm) {
                column-count: 2;
            }
        }

        .footer__list-column--three-col:first-child & {
            @include respond-to(sm) {
                column-count: 3;
            }
        }

        li {
            margin: 0;
            padding: 0;
        }
    }


    &__heading,
    &__list li {
        padding-right: $baseline * 2;

        @include respond-to(sm) {
            padding-right: $baseline * 6;
        }

        @include respond-to(md) {
            width: $container-md / 4;
        }

        @include respond-to(lg) {
            width: $container-lg / 8;
        }

        @include respond-to(xl) {
            width: $container-xl / 8;
        }
    }

    a {
        text-decoration: none;
    }

    &__copyright {
        background: $footer-copyright-bg-color;
        position: relative;

        p {
            float: left;
            font-size: px-to-rem(13px);
            line-height: px-to-rem(24px);
            margin: 0;
        }

        &:after {
            clear: both;
            content: '';
            display: block;
        }
    }

    &__copyright-background {
        display: block;
        height: 100%;
        left: 50%;
        margin-left: -50vw;
        position: absolute;
        top: 0;
        width: 100vw;
    }

    &__copyright-content {
        padding: $baseline * 7 0;
        position: relative;
        z-index: 1;

        &:after {
            content: '';
            clear: both;
            display: block;
        }
    }

    &__back-to-top {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 3px;
        display: block;
        float: right;
        height: $baseline * 6;
        position: relative;
        width: $baseline * 6;

        &:after {
            @include chevron(up, $footer-back-to-top-fg-color);
            position: absolute;
            left: 50%;
            top: 50%;
        }
    }
}

// Need to hide when .hide-contact-form is on a parent as footer is from SSI include and can't
// be modified by AEM.
.hide-contact-form {
    .footer {
        .section--general-form {
            display: none !important;
        }
    }

    .content:not(.simplicity):not(.white-lady) {
        .footer {
            .section--contact-tile {
                @include respond-to(lg) {
                    margin-left: 25%;
                }
            }
        }
    }
}

.section--complex-header ~ .section--footer {
    .segment__inner {
        padding: 0px;
    }

    .footer__list-container {
        justify-content: space-between;    
        padding-top: 21px;
        padding-bottom: 21px;     
        .footer__heading {           
            padding-right: 0px;
            line-height: 19px;
            font-weight: 500;
            font-size: px-to-rem(16px);
        }
    }

    .footer__list-column,
    .footer__list-column:first-child {
        width: auto;
        margin-top: 0;
        @include respond-to(0, sm) {
            width: 100%;
            text-align: center;
            padding: 10px 0px;
        }
        @include respond-to(md) {
            display: inline-block;            
        }
        @include respond-to(xl) {
            margin-top: 0;
        }        
    }

    .footer__heading,
    .footer__list {
        width: auto;
    }

    .footer__contact {
        display: none;
    }
    .footer__copyright-content  {  
        height: 82px;
        display: flex;
        align-items: center;
        padding-top: 0px;
        padding-bottom: 0px; 
        @include respond-to(0, sm) {
            justify-content: center;
        }
        p {
            font-size: px-to-rem(13px);
            line-height: 20px;
            font-weight: 300; 
            width: calc(100% - 24px);   
            @include respond-to(0, sm) {
                width: auto;
            }        
        }
    }
    .footer__sitemap p {
        padding-top: 20px;
    }      
}

.simplicity,
.white-lady {
    .footer__copyright {
        text-align: center;

        p {
            float: none;
        }
    }

    .footer__copyright-content {
        padding: 6px 0;

        @include respond-to(md) {
            padding: $baseline * 4 0;
        }
    }

    .footer__back-to-top {
        border-radius: 0;
        position: absolute;
        right: 0;
        transition: height .3s;

        &:before {
            content: '';
            display: block;
            position: relative;
            width: 100%;
        }
    }
}

.simplicity {
    .footer__copyright-content {
        @include respond-to(0, sm) {
            padding-right: $baseline * 20;
            text-align: left;
        }
    }

    .footer__back-to-top {
        border-left: 3px solid $color-01;
        border-right: 3px solid $color-01;
        bottom: 0;
        height: $baseline * 16;
        width: $baseline * 18;

        &:before {
            border: 3px solid $color-01;
            border-bottom: 0;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            height: $baseline * 5;
            margin-top: -15px;
            margin-left: -3px;
        }

        &:after {
            border-top-width: $baseline;
            border-left-width: $baseline;
            height: 14px;
            margin: -20px 0 0 -9px;
            width: 14px;
        }

        &:hover {
            height: $baseline * 18;
        }
    }
}

.white-lady {
    .footer__back-to-top {
        bottom: 100%;
        height: $baseline * 5;
        opacity: 0.9;
        width: $baseline * 14;

        @include respond-to(md) {
            height: $baseline * 8;
            width: $baseline * 24;
        }

        &:before {
            border-top-left-radius: $baseline * 7;
            border-top-right-radius: $baseline * 7;
            height: $baseline * 7;
            margin-top: -$baseline * 7;

            @include respond-to(md) {
                border-top-left-radius: $baseline * 14;
                border-top-right-radius: $baseline * 14;
                height: $baseline * 14;
                margin-top: -$baseline * 11;
            }
        }

        &:after {
            border-top-width: 4px;
            border-left-width: 4px;
            height: 11px;
            margin: -18px 0 0 -8px;
            width: 11px;

            @include respond-to(md) {
                border-top-width: 6px;
                border-left-width: 6px;
                height: 16px;
                margin: -25px 0 0 -11px;
                width: 16px;
            }
        }

        &:hover {
            height: $baseline * 6.5;

            @include respond-to(md) {
                height: $baseline * 10;
            }
        }
    }
}
