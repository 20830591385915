// Baseline grid
$baseline: 4px;

// Gutters
$gutter: $baseline * 7;
$gutter-md: $baseline * 4;
$gutter-lg: $baseline * 4;
$gutter-xl: $baseline * 7;

$gutter-tight: $baseline * 4;

// Section gutter
$section-gutter: $baseline * 6;
$section-gutter-md: $baseline * 10;
$section-gutter-lg: $baseline * 10;
$section-gutter-xl: $baseline * 10;

// Containers
$container-md: 960px;
$container-lg: 1180px;
$container-xl: 1280px;

// Element specific
$header-height-md: $baseline * 25;

// Simplicity Header Heights
$header-height-simplicity: $baseline * 25;
$header-height-simplicity-md: $baseline * 53;

// White Lady Header Heights
$header-height-white-lady: $baseline * 36;
$header-height-white-lady-md: $baseline * 48;

// Hit area (around smaller buttons)
$hit-area: 5px;

// sf = search form
$sf-input-height-sl-mobile: $baseline * 16;
$sf-input-leading-sl-mobile: $baseline * 6;
$sf-button-width-sl-mobile: $baseline * 16;
$sf-input-height-sl-desktop: $baseline * 16;
$sf-input-leading-sl-desktop: $baseline * 6;
$sf-button-width-sl-desktop: 180px;

$sf-input-height-wl-mobile: $baseline * 16;
$sf-input-leading-wl-mobile: $baseline * 6;
$sf-button-width-wl-mobile: 62px;
$sf-input-height-wl-desktop: $baseline * 13;
$sf-input-leading-wl-desktop: $baseline * 6;
$sf-button-width-wl-desktop: 180px;
