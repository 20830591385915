.breadcrumbs {
    text-align: center;
    width: 100%;
    &,
    &__anchor {
        display: inline-block;
        font-size: px-to-rem(14px);
        line-height: px-to-rem($baseline * 6);
        text-transform: uppercase;
        text-decoration: none;
    }
    &__anchor::after {
        content: "/";
    }
    &__anchor:last-child::after {
        content: "";
    }
    .simplicity & {
        margin-top: $baseline * 7;
        @include respond-to(lg) {
            margin-top: $baseline * 15;
        }
    }
}

.aem-GridColumn.section--breadcrumbs {
    margin-bottom: 0;
    margin-top: 0;
}

.aem-GridColumn .breadcrumb {
    margin-bottom: 12px;
    margin-top: 12px;

    @include respond-to(md) {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .simplicity & {
        margin-top: $baseline * 6;
        @include respond-to(md) {
            margin-top: $baseline * 15;
        }
    }
}

.section-breadcrumb--empty {
    @include respond-to(md) {
        margin-bottom: 20px !important;
        margin-top: 20px !important;
    }
}

.aem-GridColumn.cq-Editable-dom--container{
    @include respond-to(0, sm) {
        padding-top: 0 !important;
    }
}