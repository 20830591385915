.home-result {
    opacity: 0;


    &--loaded,
    [class*='aem-AuthorLayer'] & {
        opacity: 1;
    }

    &__body {
        position: relative;
    }

    &__body-background {
        display: block;
        height: 100%;
        left: 50%;
        margin-left: -50vw;
        position: absolute;
        top: 0;
        width: 100vw;
    }

    &__body-background {
        background-position: center center;
        background-size: cover;
    }

    &__body-content {
        padding: $baseline * 12 0;
        position: relative;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        @extend %funeral-planner-border;
        display: block;
        overflow: hidden;
        padding: 0;
        margin: 0 0 $baseline * 8 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__item-inner {
        width: 100%;

        @include respond-to(md) {
            display: table;
            height: 100%;
            table-layout: fixed;
        }
    }

    &__logo-container,
    &__content-container,
    &__ctas-container {
        padding: $baseline * 4;

        @include respond-to(md) {
            display: table-cell;
            padding: $baseline * 8;
            vertical-align: middle;
        }
    }

    &__logo-container,
    &__ctas-container {
        @include respond-to(md) {
            width: 25%;
        }
    }

    &__content-container {
        font-weight: 600;
        vertical-align: top;

        @include respond-to(md) {
            width: 50%;
        }
    }

    &__logo-container {
        background: $color-01;
        padding: $baseline * 8 $baseline * 4;

        @include respond-to(md) {
            padding: $baseline * 8;
        }
    }

    &__logo {
        display: block;
        margin: auto;
        max-width: 100%;

        @include respond-to(0, sm) {
            max-height: $baseline * 20;
        }
    }

    &__item-heading {
        font-size: px-to-rem(17px);
        font-weight: bold;
        line-height: px-to-rem($baseline * 5);
        margin-bottom: $baseline * 2;
        text-transform: uppercase;
    }

    &__directions {
        margin-top: $baseline * 6;
    }

    &__cta--view,
    &__cta--contact {
        display: block;
        padding-left: $baseline * 4;
        padding-right: $baseline * 4;
        width: 100%;
    }

    &__cta--view {
        margin-bottom: $baseline * 4;

        @include respond-to(md) {
            margin-bottom: $baseline * 7;
        }
    }

    &__no-results {
        @extend %funeral-planner-border;
        display: none;
        padding: $baseline * 6;
        text-align: center;
        width: 100%;

        .home-result--no-results & {
            display: block;
        }
    }
}
