$pl-bullet: 18px;

.package-listing {
    &__head {
        background: linear-gradient(#{$color-01}, #{$color-05});
        display: table;
        height: 148px;
        position: relative;
        width: 100%;

        @include respond-to(sm) {
            height: 160px;
        }
    }

    &__shape {
        position: absolute;
        height: 100%;
        width: 100%;
    }

    &__caption {
        color: $color-01;
        display: table-cell;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        width: 100%;
        position: relative;
    }

    &__heading {
        font-size: px-to-rem(18px);
        font-weight: 700;
        line-height: px-to-rem($baseline * 5);
        text-transform: uppercase;
    }

    &__price {
        font-size: px-to-rem(56px);
        line-height: px-to-rem($baseline * 15);
    }

    &__body {
        background-color: $color-05;
        font-weight: 400;
        padding: ($baseline * 4) 30px ($baseline * 2) 20px;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        border-top: 1px solid $color-07;
        font-size: px-to-rem(20px);
        line-height: $baseline * 7;
        margin: 0 0 0 ($pl-bullet + 20px);
        padding: 16px 0;
        position: relative;

        &:first-child {
            border-top: 0;
        }

        &:before {
            color: $color-06;
            font-size: px-to-rem(12px);
            line-height: $pl-bullet;
            height: $pl-bullet;
            text-align: center;
            width: $pl-bullet;
            position: absolute;
            left: -($pl-bullet + 20px);
            top: 23px;
        }

        .simplicity--patch-and-purr & {
            @extend .icon-paw;

            &:before {
                font-size: px-to-rem(18px);
                top: 20px;
            }
        }
    }

    .button {
        margin-top: $baseline * 3;
        width: 100%;
    }
}
