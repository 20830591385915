* {
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

html,
body {
    margin: 0;
}

[v-cloak] {
    display: none;
}
