.cmp-text,
.textimage-text {
    b,
    strong {
        font-weight: bold;
    }

    ol,
    ul {
        list-style: none;
        padding-left: 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        li {
            display: table;
            padding: 0;
            position: relative;

            &:before {
                display: table-cell;
                min-width: $baseline * 9;
                padding-right: $baseline * 4;
                position: relative;
                text-align: right;
                width: $baseline * 9;
            }

            + li {
                margin-top: $baseline * 4;
            }
        }
    }

    ol {
        counter-reset: item;

        li {
            &:before {
                content: counter(item) '. ';
                counter-increment: item;
            }
        }
    }

    ul {
        li {
            &:before {
                content: '\25AA';

                .simplicity--patch-and-purr & {
                    @extend .icon-paw;
                    top: 3px;
                }
            }
        }
    }

    li > {
        ul:first-child,
        ol:first-child,
        ul,
        ol {
            margin-top: $baseline * 4;
        }
    }

    table {
        border: 0;
        height: auto;
        margin: 0 auto $baseline * 8 auto;

        caption {
            font-size: px-to-rem(20px);
            font-weight: bold;
            line-height: $baseline * 6;
            margin: 0 0 $baseline * 6 0;
        }

        th {
            font-weight: bold;
        }

        td {
            font-weight: inherit;
        }

        td,
        th {
            border: 0;
            border-bottom: 1px solid black;
            padding: $baseline * 5 0;
            text-align: left;
        }

        tr:first-child {
            th {
                border-bottom: 2px solid black;
            }
        }

        tr:last-child {
            td,
            th {
                border-bottom: 2px solid black;
            }
        }
    }
    .white-lady & {
        hr {
            display: block;
            height: 2px;
            box-sizing: border-box;
            border: 1px solid #9B1E34;
        }
    }
}

.section--complex-header + * {
    .cmp-text,
    .textimage-text {
        ul {
            li {
                + li {
                    margin-top: $baseline * 2;
                }

                &:before {
                    color: inherit;
                    width: $baseline * 6;
                }
            }
        }
    }
}
