.simplicity .carousel,
.white-lady .carousel {
    .slick-arrow {
        border: 0;
        bottom: auto;
        height: $baseline * 24;
        left: auto;
        margin-left: 0 !important; // Overwrite inline style.
        margin-top: -$baseline * 12;
        position: absolute;
        top: 50% !important;
    }

    .slick-prev {
        border-bottom-right-radius: $baseline * 12;
        border-top-right-radius: $baseline * 12;
        left: 0;
    }

    .slick-next {
        border-bottom-left-radius: $baseline * 12;
        border-top-left-radius: $baseline * 12;
        right: 0;

        &:after {
            float: right;
        }
    }
}

.simplicity .carousel {
    $arrow-width: 10px;
    $arrow-height: 40px;

    .slick-arrow {
        background: none !important;
        opacity: .7;
        width: $baseline * 24;

        &:hover {
            opacity: 1;
        }

        &:before,
        &:after {
            background: white;
            border: 0;
            border-radius: 3px;
            content: '';
            display: block;
            height: $arrow-height;
            margin: 0;
            position: absolute;
            top: calc(50% - #{$arrow-height - ($arrow-width / 2)});
            transform-origin: 50% #{$arrow-height - ($arrow-width / 2)};
            width: $arrow-width;
        }
    }

    .slick-prev {
        &:before,
        &:after {
            left: 50%;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(135deg);
        }
    }

    .slick-next {
        &:before,
        &:after {
            right: 50%;
        }

        &:before {
            transform: rotate(-45deg);
        }

        &:after {
            transform: rotate(-135deg);
        }
    }

    .slick-dots {
        button {
            border: 3px solid $color-01;
            height: $baseline * 4.5;
            opacity: .6;
            width: $baseline * 4.5;
        }

        .slick-active button {
            opacity: 1;
        }
    }

    .cmp-text {
        text-align: left;

        h1,
        h2,
        h3 {
            font-weight: 600;
        }
    }
}

.white-lady .carousel {
    .slick-prev {
        padding-left: $baseline * 8;

        &:hover {
            padding-left: $baseline * 12;
        }
    }

    .slick-next {
        padding-right: $baseline * 8;

        &:hover {
            padding-right: $baseline * 12;
        }
    }

    &.carousel--image-carousel {
        .slick-prev {
            padding-left: $baseline * 7;

            &:hover {
                padding-left: $baseline * 11;
            }
        }

        .slick-next {
            padding-right: $baseline * 7;

            &:hover {
                padding-right: $baseline * 11;
            }
        }
    }

    .slick-arrow {
        transition: width .3s, padding .3s, background .3s;
        width: $baseline * 19;

        &:hover {
            width: $baseline * 23;
        }

        &:after {
            border-top-width: 6px;
            border-right-width: 6px;
            border-top-style: solid;
            border-right-style: solid;
            height: 18px;
            width: 18px;
        }
    }

    .slick-dots {
        bottom: auto;
        display: none;
        left: auto;
        position: absolute;
        top: $baseline * 10;
        right: $baseline * 10;
        width: $baseline * 3.5;

        @include respond-to(0, sm) {
            display: none !important; // Overwrite inline style.
        }

        li {
            display: block;
        }

        button {
            background: $color-01;
            border: 0;
            height: $baseline * 3.5;
            margin: 0 0 $baseline * 2.5 0;
            opacity: 1;
            width: $baseline * 3.5;
        }

        .slick-active button {
            opacity: .6;
        }
    }

    &.carousel--image-carousel .slick-dots {
        display: none !important; // Overwrite inline style.
    }

    .cmp-text {
        h1,
        h2,
        h3 {
            font-weight: 300;
        }
    }

    &.carousel--image-carousel .cmp-text {
        h1,
        h2,
        h3 {
            margin-bottom: $baseline * 4;
        }

        p {
            font-weight: 300;
        }
    }

    &.carousel--image-carousel .button {
        &:after {
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            content: '';
            display: inline-block;
            height: 0;
            margin-left: $baseline * 3;
            width: 0;
        }
    }
}
