.general-image {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;

    &__image {
        display: block;
        width: 100%;
    }

    &__caption {
        margin-top: $baseline * 3;

        * {
            color: $general-image-color;
            font-size: 13px;
            line-height: $baseline * 5;
            margin: 0;
        }
    }
}
