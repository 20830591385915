// sass-lint:disable indentation force-pseudo-nesting no-color-literals

.article {
    $sl-color-light: #fff;
    $sl-color-dark: #005170;

    .simplicity & {
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .2);
    }

    .simplicity--patch-and-purr & {
        border-radius: 0;
        overflow: hidden;
        box-shadow: none;
    }

    & > a {
        color: inherit;
        text-decoration: none;
    }

    &__image {
        display: block;
        width: 100%;
    }

    // NOTE: div that wraps title & description
    &__text {
        .article__image + & {
            margin: $baseline * 4 auto 0 auto;

            .simplicity & { margin: 0; }
        }

        .simplicity & {
            background-color: $sl-color-light;
            color: $sl-color-dark;
            display: flex;
            flex-direction: column;
            align-items: center;
            min-height: ($baseline * 12) + ($baseline * 8);
            padding: $baseline * 4;
            text-align: center;

            transition: background-color .25s linear,
                        color .25s linear;
        }

        .article > a:hover & {
            .simplicity & {
                background-color: $sl-color-dark;
                color: $sl-color-light;
            }
        }
    }

    &__title {
        font-size: px-to-rem(21px);
        line-height: px-to-rem($baseline * 7);
        margin: auto;
        max-width: 740px;
        width: 100%;

        .simplicity & {
            font-size: px-to-rem(20px);
            font-weight: 600;
            line-height: px-to-rem(24px);

        }
    }

    &__description {
        margin: auto;
        max-width: 740px;
        width: 100%;

        .article__title + & {
            margin: $baseline * 1 auto 0 auto;

            .simplicity & { margin: ($baseline * 4) 0 0; }
        }

        * {
            font-size: px-to-rem(15px);
            line-height: px-to-rem($baseline * 5);
        }
    }
}

.section--complex-header + * {
    .article {
        .article__text {
            margin: 0;
            padding: $baseline * 7 $baseline * 6 $baseline * 8 $baseline * 6;
            text-align: center;
        }

        .article__description {
            * {
                font-size: px-to-rem(16px);
                line-height: px-to-rem($baseline * 6);
            }
        }

        .article__title,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: px-to-rem(19px);
            font-weight: 600;
            line-height: px-to-rem($baseline * 6);
            margin-bottom: $baseline * 2.5;
        }
    }
}
