// sass-lint:disable force-pseudo-nesting no-color-literals force-element-nesting

.modal {
    visibility: hidden;
    position: relative;
    transition: visibility .2s;
    z-index: index($z-stack, modal);

    &--visible {
        visibility: visible;
    }

    &__overlay {
        background: rgba($modal-overlay-bg-color, .5);
        bottom: 0;
        display: flex;
        flex-direction: column;
        left: 0;
        overflow-y: hidden;
        position: fixed;
        right: 0;
        top: 0;

        @include respond-to(lg) {
            background: transparent;
        }

        .simplicity & {
            background: rgba(0,81,112,0.9);
        }

        .white-lady & {
            background: rgba(42,42,42,0.2);
        }
    }

    &__dialog {
        background: $modal-dialog-bg-color;
        margin: auto;
        opacity: 0;
        overflow: hidden;
        overflow-y: auto;
        padding: $baseline * 15 - $section-gutter $baseline * 6;
        position: relative;
        top: 100%;
        transition: top .7s, opacity .2s;
        width: calc(100% - #{$gutter * 1});
        -webkit-overflow-scrolling: touch;

        @include respond-to(md) {
            max-width: calc(100% - #{$gutter * 2});
            padding: $baseline * 22 - $section-gutter-md $baseline * 22;
            width: 800px;
        }

        @include respond-to(lg) {
            padding: $baseline * 22 - $section-gutter-lg $baseline * 22;
        }

        @include respond-to(xl) {
            padding: $baseline * 22 - $section-gutter-xl $baseline * 22;
        }

        .simplicity & {
            background: transparent;
            right: 100%;
            top: auto;
            transition: right .7s, opacity .2s;
        }

        .white-lady & {
            background-color: #f6f3ef;
            border-radius: 7px;
            border: 3px solid #efe7dc;
            padding: $baseline * 10;
        }

        .modal--visible & {
            opacity: 1;
        }

        .modal--centered & {
            top: 0;
            .simplicity & {
                right: 0;
            }
        }

        &--quote {
            @include respond-to(md) {
                padding: $baseline * 6 $baseline * 12;
                width: 1100px;
            }
            @include respond-to(lg) {
                width: 1280px;
            }

            .modal__fragment {
                min-height: 0;
            }
        }
    }

    &__close-button {
        appearance: none;
        border: 0;
        border-radius: 0;
        background: transparent;
        cursor: pointer;
        height: $baseline * 12;
        position: absolute;
        right: 0;
        top: 0;
        width: $baseline * 12;

        @include respond-to(md) {
            height: $baseline * 15;
            width: $baseline * 15;
        }

        .modal__dialog--quote & {
            color: #999;
            height: $baseline * 10;
            width: $baseline * 10;

            &:hover {
                color: #333;
            }
        }
    }

    &__close-icon {
        font-size: 18px;

        @include respond-to(md) {
            font-size: 26px;
            line-height: $baseline * 6;
            .white-lady & {
                font-size: 21px;
            }
        }



        .modal__dialog--quote & {
            @include respond-to(md) {
                &:before {
                    font-size: 15px;
                    font-weight: bold;
                }
            }
        }

        &:before {
            display: block;

            .simplicity & {
                color: $color-01;
                font-weight: bold;
                @include respond-to(md) {
                    font-size: 21px;
                }
            }

            .white-lady & {
                color: #9b1e34;
                font-weight: bold;
            }
        }
    }

    &__close-text {
        @include sr-only();
    }

    &__fragment {
        min-height: 150px;
    }

    &__fragment-inner {
        width: 100%;
    }

    .loader {
        left: calc(50% - #{$baseline * 6});
        position: absolute;
        top: calc(50% - #{$baseline * 4});
        transition: opacity .6s;
    }

    &--loaded .loader {
        opacity: 0;
    }

    .simplicity & {
        h1, h2, h3, h4, h5, h6 {
            color: $color-01;
        }
    }
}

html.modal--open {
    overflow-y: hidden;
}

.ups-modal {

    .modal__overlay {
        background: rgba($modal-overlay-bg-color, .5);
        
        @include respond-to(lg) {
            background: rgba($modal-overlay-bg-color, .5);
        }
    }

    .modal__dialog {
        max-width: 500px !important;
        border-radius: $baseline * 4;
    }
}