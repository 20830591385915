.sub-menu {
    position: relative;

    &__background {
        height: 100%;
        left: 50%;
        margin-left: -50vw;
        position: absolute;
        top: 0;
        width: 100vw;

        @include respond-to(sm) {
            display: none;
        }
    }

    &__content {
        padding: $baseline * 6 0;
        position: relative;

        @include respond-to(sm) {
            padding: 0;
        }
    }

    .sub-menu__heading,
    .sub-menu__anchor {
        font-size: px-to-rem(14px);
        line-height: $baseline * 4;
        padding-bottom: $baseline * 2.5;
        padding-top: $baseline * 2.5;
        margin: 0;
        text-transform: uppercase;
    }

    .sub-menu__heading {
        font-weight: bold;

        @include respond-to(sm) {
            padding-top: 0;
        }
    }

    .sub-menu__anchor {
        display: block;
        padding-left: $baseline * 4;
        text-decoration: none;
        transition: color .3s;

        @include respond-to(sm) {
            padding-left: $baseline * 5;
        }

        &:not(:hover):not(.theme-bg-color-button) {
            color: inherit;
        }
    }

    .theme-text-color-button {
        transition: color .3s, background .3s;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;

        .sub-menu__item > & {
            padding-left: $baseline * 4;
        }
    }

    &__item {
        padding: 0;
        margin: 0;
    }

    &__button {
        @extend %button-reset;
        background: transparent;
        height: $baseline * 6;
        position: absolute;
        right: 0;
        top: $baseline * 8;
        width: $baseline * 6;

        &:after {
            border-top: 2px solid $color-02;
            border-right: 2px solid $color-02;
            content: '';
            display: block;
            height: $baseline * 2;
            position: absolute;
            top: 0;
            right: 0;
            transform: rotate(135deg);
            width: $baseline * 2;

            @include respond-to(sm) {
                display: none;
            }
        }
    }
}

.js-sub-menu__list {
    display: none;

    @include respond-to(sm) {
        display: block !important; // Overcome jquery slidedown.
    }
}
