.summary {
    @extend %funeral-planner-border;
    background: $color-01;
    border-radius: 4px;
    overflow: hidden;
    padding: $baseline * 6 $baseline * 5;

    &__logo-container {
        margin-bottom: $baseline * 6;
    }

    &__logo {
        display: block;
        margin: 0 auto;
        max-width: 380px;
        width: calc(100% - #{$baseline * 20});

        @include respond-to(xl) {
            height: 80px;
            max-width: auto;
            width: auto;
        }
    }

    &__heading {
        font-size: px-to-rem(20px);
        font-weight: 700;
        line-height: px-to-rem($baseline * 6);
        margin: 0;
        margin-bottom: $baseline * 8;
        text-align: center;
        text-transform: uppercase;
    }

    &__subheading {
        font-size: px-to-rem(18px);
        font-weight: bold;
        line-height: px-to-rem($baseline * 5);
        margin: $baseline * 6 0 $baseline * 3 0;
    }

    &__list {
        list-style: none;
        font-weight: 600;
        margin: 0;
        padding: 0;

        &:after {
            clear: both;
            content: '';
            display: block;
        }

        @include respond-to(xl) {
            columns: 2;
        }
    }

    li {
        font-weight: 400;
        line-height: px-to-rem($baseline * 6);
        margin-bottom: $baseline;
        padding: 0 $baseline * 2 0 $baseline * 6;
        position: relative;
    }

    &__icon {
        display: block;
        height: $baseline * 2.5;
        left: 0;
        position: absolute;
        top: calc(7px);
    }
}
