.brand-info {
    $price-color: #0e6c8b;
    background: $color-01;
    border-radius: 4px;
    border: 1px solid #736057;
    overflow: hidden;
    padding: $baseline * 4;
    position: relative;

    @include respond-to(lg) {
        .customization-form & {
            top: $baseline * 16;
        }
    }

    &.brand-info--sticky {
        background: $color-01;
        border-radius: 0;
        border: 0;
        overflow: visible;

        @include respond-to(0, md) {
            padding: 0;
        }
    }

    &__inner {
        background: $color-01;
        position: relative;
        z-index: 1;

        @include respond-to(0, md) {
            position: relative !important;
            top: auto !important;
        }

        @include respond-to(lg) {
            .brand-info--sticky & {
                margin-left: 11px;
                padding-top: $baseline * 6;
                position: fixed;
                bottom: $baseline * 16;
                width: 283px;
            }

            [class*='aem-AuthorLayer'] & {
                bottom: auto !important;
                position: relative !important;
                top: auto !important;
            }
        }

        @include respond-to(xl) {
            .brand-info--sticky & {
                margin-left: 30px;
                width: 327px;
            }
        }

        .brand-info--unfixed-top & {
            position: relative;
            bottom: auto;
        }

        .brand-info--unfixed-bottom & {
            position: absolute;
            bottom: auto;
        }

        &:before {
            .brand-info--sticky & {
                @include respond-to(lg) {
                    background: $color-01;
                    border-radius: 4px;
                    border: 1px solid #736057;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                    bottom: -1px;
                    content: '';
                    left: -$gutter-xl / 2;
                    position: absolute;
                    right: -$gutter-xl / 2;
                    top: -$gutter-xl / 2;
                }
            }
        }
    }

    &__content {
        position: relative;

        @include respond-to(sm, md) {
            display: table;
            table-layout: fixed;
        }

        @include respond-to(lg) {
            display: block;
        }
    }

    &__brand-container,
    &__branch-container,
    &__cta-container {
        @include respond-to(sm, md) {
            display: table-cell;
            vertical-align: top;
            width: 50%;

            .customization-form & {
                width: 30%;
            }
        }
    }

    &__branch-container {
        @include respond-to(sm, md) {
            padding-left: $baseline * 4;
            width: 50%;

            .customization-form & {
                padding: 0 5%;
                width: 40%;
            }
        }
    }

    &__cta-container {
        display: none;

        .customization-form & {
            display: block;

            @include respond-to(sm, md) {
                display: table-cell;
            }
        }
    }

    &__logo {
        display: block;
        margin: auto;
        max-width: 350px;
        width: 100%;
        margin: 0 auto $baseline * 10 auto;

        @include respond-to(lg) {
            width: calc(100% - #{$baseline * 6});
        }
    }

    &__branch-heading {
        font-family: inherit;
        font-weight: bold;
        margin-top: $baseline * 6;

        @include respond-to(sm, md) {
            margin-top: 0;
        }
    }

    .google-map {
        margin-top: $baseline * 6;
    }

    .button,
    &__price {
        border-radius: 0;
        font-size: px-to-rem(28px);
        font-weight: 600;
        line-height: px-to-rem($baseline * 8);
        padding: $baseline * 4;
        text-transform: uppercase;
        width: 100%;

        @include respond-to(lg) {
            margin-left: -$baseline * 3.5 + 1px;
            width: calc(100% + #{$baseline * 7 - 2px});
        }
    }

    &__price {
        background: $price-color;
        display: none;
        margin-top: $baseline * 7;
        text-align: center;

        @include respond-to(sm, md) {
            margin: 0;
        }

        .brand-info--show-price & {
            display: block;
        }
    }

    .button {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
