$x-positions: 'left', 'center', 'right';
$y-positions: 'top', 'center', 'bottom';

@each $current-x in $x-positions {
    @each $current-y in $y-positions {
        .background-position--#{$current-x}-#{$current-y} {
            @include respond-to(0, sm) {
                background-position: #{$current-x} #{$current-y} !important;
            }
        }

        .background-position--#{$current-x}-#{$current-y}-md {
            @include respond-to(md) {
                background-position: #{$current-x} #{$current-y} !important;
            }
        }
    }
}

$x-mid-positions: (
    'left': '25%',
    'center': '50%',
    'right': '75%'
);

$y-mid-positions: (
    'top': '25%',
    'center': '50%',
    'bottom': '75%'
);

@each $name-x, $value-x in $x-mid-positions {
    @each $name-y, $value-y in $y-mid-positions {
        .background-position--mid-#{$name-x}-#{$name-y} {
            @include respond-to(0, sm) {
                background-position: #{$value-x} #{$value-y} !important;
            }
        }

        .background-position--mid-#{$name-x}-#{$name-y}-md {
            @include respond-to(md) {
                background-position: #{$value-x} #{$value-y} !important;
            }
        }
    }
}
