.custom-header {
    &__image,
    &__heading,
    &__description {
        float: left;
    }

    &__image {
        width: $baseline * 19;
        margin-right: $baseline * 4;
    }

    &__heading {
        font-family: inherit;
        line-height: px-to-rem($baseline * 5);
        font-size: px-to-rem(18px);
        font-weight: bold;
        margin: 0 0 $baseline * 2 0;
        text-transform: uppercase;

        @include respond-to(md) {
            font-size: px-to-rem(24px);
            line-height: px-to-rem($baseline * 7);
        }
    }

    &__heading,
    &__description {
        width: calc(100% - #{$baseline * 23});
    }

    &__description {
        @include respond-to(sm) {
            font-size: px-to-rem(20px);
            line-height: px-to-rem($baseline * 7);
        }
    }

    &:after {
        clear: both;
        content: '';
        display: block;
    }
}
