// sass-lint:disable indentation force-pseudo-nesting no-transition-all

// theme
$sl-color-light: #fff;
$sl-color-dark: #00aeef;
$sl-color-dark-gradient: #009ad4;
$sl-color-dark-secondary: #005170;

$sl-action-btn-height-desktop: $baseline * 19;
$sl-action-btn-width-desktop: 157px;
$sl-action-btn-offset-desktop: 17px;

$wl-color-light: #fff;
$wl-color-light-secondary: #f6f3ef;
$wl-color-light-secondary-gradient: #efe7dd;
$wl-color-dark: #333;
$wl-color-dark-secondary: #9b1e34;

$wl-contact-info-width-lg: 255px;
$wl-contact-info-width-lg-shrunk: 205px;

$wl-logo-width-md-shrunk: 86px;
$wl-logo-height: 80px;
$wl-logo-height-md: 80px;
$wl-logo-height-md-shrunk: 80px;
$wl-logo-height-lg: 120px;
$wl-logo-height-lg-shrunk: 88px;

$wl-action-btn-height-desktop: $baseline * 12;
$wl-action-btn-width-desktop: $baseline * 41;


// sh = sticky header
$sh-bg-tail: 24px;
$sh-transition-slide-time: .3s;
$sh-transition-fade-time: .5s;
$sh-transition-fade-delay: .2s;

// tablet
$sh-top-height-tablet: $baseline * 15;
$sh-bottom-height-tablet: $baseline * 8;

// desktop
$sh-top-height-desktop: $baseline * 15;
$sh-top-height-desktop-shrunk: $baseline * 12;
$sh-bottom-height-desktop: $baseline * 10;

%sh-button {
    appearance: none;
    border: 0;
    cursor: pointer;
    margin: 0;

    .simplicity & {
        background-color: transparent;
        color: $sl-color-light;
    }
}

// used for action bar and hamburger
%sh-button-action {
    font-size: px-to-rem(18px);
    line-height: px-to-rem($sh-bottom-height-tablet);
    height: $sh-bottom-height-tablet;
    padding: 0;
    text-align: center;
    width: $sh-bottom-height-tablet;

    .white-lady &,
    .simplicity--patch-and-purr & {
        @include respond-to(null, sm) {
            border-radius: 50%;
            font-size: px-to-rem(16px);
        }
    }
}

.sh {
    font-weight: 300;
    position: relative;

    .simplicity & {
        height: 100px;
        @include respond-to(md) { height: 188px; }
        @include respond-to(lg) { height: 212px; }
    }

    .white-lady & {
        height: 144px;
        @include respond-to(md) { height: 148px; }
        @include respond-to(lg) { height: 188px; }
    }

    &__content {
        width: 100%;

        position: fixed;
        z-index: index($z-stack, header);
        left: 0;

        .simplicity & {
            padding-top: $baseline * 2;
            transition: padding-top $sh-transition-slide-time ease-in;

            @include respond-to(md) {
                padding-top: $baseline * 8;
            }

            @include respond-to(lg) {
                padding-top: $baseline * 13;
            }
        }

        .sh--shrunk & {
            .simplicity & {
                @include respond-to(md) {
                    padding-top: $baseline * 6;
                }

                @include respond-to(lg) {
                    padding-top: $baseline * 5;
                }
            }
        }
    }

    &__bg {
        height: 100%;
        margin-left: -50vw;
        width: 100vw;

        position: absolute;
        top: 0;
        left: 50%;

        .white-lady & {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
        }

        &-tail {
            background-repeat: no-repeat;
            background-position: center top;
            background-size: contain;
            height: $sh-bg-tail;
            pointer-events: none;

            position: absolute;
            z-index: 2;
            right: 0;
            bottom: -$sh-bg-tail;
            left: 0;

            @include respond-to(lg) {
                background-position: center;
                background-size: cover;
            }

            .white-lady & {
                display: none;
            }
        }
    }

    &__fg {
        @extend %container;
        position: relative;

        .sh--shrunk & {
            .white-lady & {
                @include respond-to(md) {
                    display: flex;
                    align-items: flex-end;
                }
            }
        }
    }

    &__top {
        @include respond-to(null, sm) {
            height: $sh-top-height-tablet;
            overflow: hidden;
            transition: height $sh-transition-slide-time ease;
        }

        .simplicity & {
            @include respond-to(md) {
                min-height: 60px; // NOTE: in case logo is smaller in height
                width: 100%;
            }
        }

        .white-lady & {
            padding: ($baseline * 4) 0 0;

            transition: height $sh-transition-slide-time ease,
                        padding-top $sh-transition-slide-time ease;

            @include respond-to(null, sm) {
                height: $baseline * 24;
            }

            @include respond-to(md) {
                padding: ($baseline * 4) 0;
            }
        }

        .sh--shrunk & {
            .simplicity & {
                @include respond-to(null, sm) {
                    height: 0;
                }
            }
        }

        .sh--shrunk:not(.sh--open) & {
            .white-lady & {
                @include respond-to(null, sm) {
                    height: 0;
                    padding: 0;
                }

                @include respond-to(md) {
                    padding: ($baseline * 2) 0;
                    width: $wl-logo-width-md-shrunk;
                }
            }
        }
    }

    &__logo {
        .white-lady & {
            height: $wl-logo-height;

            @include respond-to(md) {
                height: $wl-logo-height-md;
            }

            @include respond-to(lg) {
                height: $wl-logo-height-lg;
            }
        }

        .sh--shrunk & {
            .white-lady & {
                @include respond-to(md) {
                    height: $wl-logo-height-md-shrunk;
                }

                @include respond-to(lg) {
                    height: $wl-logo-height-lg-shrunk;
                }
            }
        }

        &-sm,
        &-md,
        &-lg {
            display: none;
        }

        &-sm {
            @include respond-to(null, sm) { display: block; }
        }

        &-md {
            .simplicity & {
                @include respond-to(md, md) { display: block; }
            }

            .white-lady & {
                @include respond-to(md) { display: none; }
            }

            .sh--shrunk & {
                .white-lady & {
                    @include respond-to(md) { display: block; }
                }
            }
        }

        &-lg {
            .simplicity & {
                @include respond-to(lg) { display: block; }
            }

            .white-lady & {
                @include respond-to(md) { display: block; }
            }

            .sh--shrunk & {
                .white-lady & {
                    @include respond-to(md) { display: none; }
                }
            }
        }

        img {
            border: 0;

            .simplicity & {
                margin: 0 auto;
                max-height: $baseline * 10;

                @include respond-to(sm) {
                    max-height: $sh-top-height-tablet;
                    transition: max-height $sh-transition-slide-time ease;
                }

                @include respond-to(md) {
                    margin: 0;
                    max-height: $sh-top-height-desktop;
                }
            }

            .white-lady & {
                max-height: $baseline * 20;

                @include respond-to(null, sm) {
                    margin: 0 auto;
                }

                @include respond-to(lg) {
                    margin: 0 auto;
                    max-height: $baseline * 30;
                }
            }

            .sh--shrunk & {
                .simplicity & {
                    @include respond-to(md) {
                        max-height: $sh-top-height-desktop-shrunk;
                    }
                }

                .white-lady & {
                    @include respond-to(lg) {
                        margin: 0;
                        max-height: $baseline * 22;
                    }
                }
            }
        }
    }

    &__bottom {
        @include respond-to(md) {
            position: absolute;
            right: 0;
        }

        .simplicity & {
            @include respond-to(md) {
                width: 40%;

                top: $sh-top-height-desktop - $sh-bottom-height-desktop;
                transition: top $sh-transition-slide-time ease;
            }
        }

        .simplicity--patch-and-purr & {
            @include respond-to(md) {
                top: 0;
                width: 70%;
            }
        }

        .white-lady & {
            @include respond-to(null, sm) {
                padding: ($baseline * 2) 0;
            }

            @include respond-to(md) {
                top: 52px;
            }

            @include respond-to(lg) {
                width: $wl-contact-info-width-lg;

                top: 136px;
            }
        }

        .sh--shrunk & {
            .simplicity & {
                @include respond-to(md) {
                    top: $sh-top-height-desktop-shrunk - $sh-bottom-height-desktop;
                }
            }

            .simplicity--patch-and-purr & {
                @include respond-to(md) {
                    top: 0;
                }
            }

            .white-lady & {
                @include respond-to(md) {
                    top: 16px;
                }

                @include respond-to(lg) {
                    top: 34px;
                }
            }
        }

        &:after {
            content: '';
            clear: both;
            display: block;
        }
    }

    &__trigger {
        @extend %sh-button, %sh-button-action;
        float: left;

        position: relative;

        @include respond-to(md) {
            float: right;
            font-size: px-to-rem(28px);
            line-height: px-to-rem($sh-bottom-height-desktop);
            height: $sh-bottom-height-desktop;
            opacity: 0;
            visibility: hidden;
            width: 0;

            transition: opacity $sh-transition-slide-time ease;
        }

        .simplicity & {
            color: $sl-color-light;
            padding: 0;
        }

        .white-lady & {
            padding: 0;

            @include respond-to(md) {
                display: none;
            }
        }

        .sh--shrunk & {
            @include respond-to(md) {
                opacity: 1;
                visibility: visible;
                width: $sh-bottom-height-desktop;
            }
        }

        [class*='icon-'] {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;

            transition: opacity $sh-transition-slide-time linear;
        }

        .icon-fa-times {
            opacity: 0;

            .sh--open & {
                opacity: 1;
            }
        }

        .icon-fa-bars {
            .sh--open & {
                opacity: 0;
            }
        }
    }

    &__action-bar {
        z-index: 2;

        @include respond-to(sm) {
            float: left;
        }

        @include respond-to(md) {
            position: fixed;
            bottom: 8.5%;
            right: 0;
        }

        .simplicity & {
            @include respond-to(md) {
                margin-top: -124px;
            }
        }

        .white-lady & {
            @include respond-to(md) {
                margin-top: -82px;
            }
        }

        &-list {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &-item {
            line-height: px-to-rem(20px);
            padding: 0;

            @include respond-to(null, xs) {
                margin: 0 0 0 5px;
            }

            @include respond-to(null, sm) {
                float: left;
            }

            @include respond-to(sm) {
                margin: 0 0 0 10px;
            }

            @include respond-to(md) {
                margin: 10px 0 0;
            }

            &:first-child {
                @include respond-to(md) {
                    margin: 0;
                }
            }
        }

        &-btn {
            @extend %sh-button, %sh-button-action;

            @include respond-to(md) {
                transition: background-color $sh-transition-slide-time ease,
                            color $sh-transition-slide-time ease,
                            right $sh-transition-slide-time ease;
            }

            .simplicity & {
                padding: 0;
                @include respond-to(md) {
                    height: $sl-action-btn-height-desktop;
                    padding: 9px 0;
                    width: $sl-action-btn-width-desktop;

                    position: relative;
                    right: -$sl-action-btn-offset-desktop;

                    &:before {
                        background: url('../resources/images/bg-action-bar-button.svg') no-repeat center;
                        background-size: $sl-action-btn-width-desktop $sl-action-btn-height-desktop;
                        content: '';
                        display: block;
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                    }

                    * {
                        position: relative;
                    }
                }
            }

            .simplicity--patch-and-purr & {
                @include respond-to(md) {
                    border-radius: ($wl-action-btn-height-desktop / 2) 0 0 ($wl-action-btn-height-desktop / 2);
                    height: $wl-action-btn-height-desktop;
                    line-height: $wl-action-btn-height-desktop - 6;
                    padding-left: $wl-action-btn-height-desktop;
                    text-align: left;
                    width: $wl-action-btn-width-desktop;

                    position: relative;
                    right: -($wl-action-btn-width-desktop - $wl-action-btn-height-desktop);

                    border: 3px solid $sl-color-light;
                    box-shadow: 0 0 3px gray;
                }

                &:before {
                    display: none;
                }

                &.contact-btn {
                    background-color: #009997;
                }

                &.search-btn {
                    background-color: #006e6e;
                }

                &.chat-btn {
                    background-color: #003a5d;
                }

                @include respond-to(0, sm) {
                    &.contact-btn,
                    &.search-btn,
                    &.chat-btn {
                        font-size: px-to-rem(24px);
                    }

                    &.chat-btn {
                        background-color: #00578B;
                    }
                }
            }

            .white-lady & {
                padding: 0;

                @include respond-to(md) {
                    border-radius: ($wl-action-btn-height-desktop / 2) 0 0 ($wl-action-btn-height-desktop / 2);
                    height: $wl-action-btn-height-desktop;
                    line-height: $wl-action-btn-height-desktop;
                    padding-left: $wl-action-btn-height-desktop;
                    text-align: left;
                    width: $wl-action-btn-width-desktop;

                    position: relative;
                    right: -($wl-action-btn-width-desktop - $wl-action-btn-height-desktop);
                }
            }

            &:hover {
                .simplicity & {
                    color: $color-01;

                    @include respond-to(md) {
                       right: -9px;
                    }

                    &:before {
                        filter: brightness(90%);
                    }
                }

                .white-lady & {
                    @include respond-to(md) {
                       right: 0;
                    }
                }

                .simplicity--patch-and-purr & {
                    &.contact-btn,
                    &.chat-btn,
                    &.search-btn {
                        border-color: #E5E5E5;
                    }

                    &.contact-btn {
                        background-color: #008A88;
                    }

                    &.search-btn {
                        background-color: #046263;
                    }

                    &.chat-btn {
                        background-color: #023453;
                    }
                }

            }

            &:focus {
                @include respond-to(md) {
                    outline: 0;
                }
            }
        }

        [class*='icon-'] {
            @include respond-to(md) {
                text-align: center;
            }

            .simplicity & {
                @include respond-to(md) {
                    display: block;
                    font-size: px-to-rem(24px);
                    line-height: $baseline * 8;
                    width: $sl-action-btn-width-desktop;
                }
            }

            .white-lady & {
                @include respond-to(md) {
                    font-size: px-to-rem(24px);
                    width: $wl-action-btn-height-desktop;

                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            .simplicity--patch-and-purr & {
                @include respond-to(md) {
                    font-size: px-to-rem(28px);
                    width: $wl-action-btn-height-desktop;
                    line-height: $wl-action-btn-height-desktop - 6;

                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            &:before {
                .simplicity & {
                    @include respond-to(md) {
                        display: block;
                    }
                }
            }
        }

        &-txt {
            font-size: px-to-rem(16px);
            text-transform: uppercase;

            @include respond-to(null, sm) {
                display: none;
            }

            .simplicity & {
                @include respond-to(md) {
                    display: block;
                    font-size: px-to-rem(20px);
                    line-height: $baseline * 6;
                }
            }

            .simplicity--patch-and-purr & {
                font-weight: 300;

                @include respond-to(md) {
                    font-size: px-to-rem(16px);
                }
            }
        }
    }

    &__vetlink-container {
        text-align: right;
        display: block;

        .vetlink-btn {
            font-weight: 300;
            font-size: px-to-rem(15px);
            line-height: px-to-rem(14px);
            letter-spacing: 1px;
            margin-top: -2px;
            padding: 10px;
            text-transform: uppercase;
            width: auto;

            @include respond-to(md) {
                margin-top: 0;
                padding: 12px 18px;
            }

            .sh--shrunk & {
                @include respond-to(md) {
                    margin-right: 30px;
                }
            }
        }

        .simplicity--patch-and-purr .sh--shrunk & {
            .vetlink-btn {
                padding: 10px;
            }

            @include respond-to(md) {
                float: right;
            }
        }
    }

    &__contact-info {
        float: right;
        font-size: px-to-rem(18px);
        line-height: px-to-rem($sh-bottom-height-tablet);

        @include respond-to(sm) {
            font-size: px-to-rem(24px);
        }

        @include respond-to(md) {
            font-size: px-to-rem(32px);
            line-height: px-to-rem(40px);
            margin-right: 0;
        }

        & .sh__contact-text {
            color: $sl-color-light;
        }

        .simplicity & {
            font-weight: 500;

            transition: font-size $sh-transition-slide-time ease,
                        margin-right $sh-transition-slide-time ease;
        }

        .simplicity--patch-and-purr & {
            @include respond-to(0, sm) {
                display: none;
            }

            & .sh__contact-text,
            & a {
                @include respond-to(md) {
                    font-size: px-to-rem(25px);
                    line-height: px-to-rem(28px);
                }
            }

        }

        .white-lady & {
            font-weight: bold;

            @include respond-to(0, 374px) {
                position: absolute;
                right: 0;
                white-space: nowrap;
            }

            @include respond-to(375px) {
                font-size: px-to-rem(24px);
            }

            @include respond-to(md) {
                font-size: px-to-rem(36px);
                line-height: px-to-rem(48px);
            }
        }

        .sh--shrunk & {
            .simplicity & {
                @include respond-to(md) {
                    font-size: px-to-rem(28px);
                    margin-right: 30px;
                }
            }

            .white-lady & {
                @include respond-to(md) {
                    font-size: px-to-rem(30px);
                    line-height: px-to-rem(40px);
                }
            }
        }

        a {
            text-decoration: none;

            .simplicity & {
                color: $sl-color-light;
            }

            .white-lady & {
                color: $wl-color-dark-secondary;
            }

        }
    }

    &__tray {
        -webkit-overflow-scrolling: touch;

        @include respond-to(null, sm) {
            max-height: 0;
            margin-right: -$gutter;
            margin-left: -$gutter;
            padding-right: $gutter;
            padding-left: $gutter;
            overflow-y: auto;
            position: absolute;
            left: 0;
            right: 0;

            transition: max-height $sh-transition-slide-time ease-in;
        }

        .simplicity & {
            @include respond-to(null, sm) {
                background-color: $sl-color-dark-secondary;
            }
        }

        .white-lady & {
            @include respond-to(null, sm) {
                background-color: $wl-color-dark-secondary;
                border-radius: 0 0 7px 7px;
            }

            @include respond-to(lg) {
                width: calc(100% - #{$wl-contact-info-width-lg});
            }
        }

        .sh--shrunk & {
            .white-lady & {
                @include respond-to(md, lg) {
                    width: calc(100% - #{$wl-logo-width-md-shrunk});
                }

                @include respond-to(lg) {
                    width: calc(100% - #{$wl-contact-info-width-lg-shrunk + $wl-logo-width-md-shrunk});
                }
            }
        }

        .sh--open & {
            .simplicity & {
                @include respond-to(null, sm) {
                    max-height: calc(100vh - 40px);
                }
            }

            .white-lady & {
                @include respond-to(null, sm) {
                    max-height: calc(100vh - 219px);
                }
            }
        }

        &:before {
            @include respond-to(null, sm) {
                content: '';
                height: 100%;
                margin-left: -50vw;
                width: 100vw;

                position: absolute;
                top: 0;
                left: 50%;
            }

            .simplicity & {
                @include respond-to(null, sm) {
                    background-color: $sl-color-dark-secondary;
                }
            }
        }
    }

    // ul
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;

        // root-list:
        .sh__nav > & {
            @include respond-to(md) {
                display: flex;
                justify-content: space-between;
            }

            .sh__tray--align-full-width & {
                justify-content: space-between;
            }

            .sh__tray--align-right & {
                justify-content: flex-end;
            }

            .sh__tray--align-left & {
                justify-content: flex-start;
            }

            .simplicity & {
                @include respond-to(null, sm) {
                    margin: ($baseline * 5) 0;
                }

                @include respond-to(md) {
                    padding: ($baseline * 7) 0;

                    transition: padding $sh-transition-fade-time ease;
                }

            }

            .white-lady & {
                @include respond-to(null, sm) {
                    margin-left: -$gutter;
                    margin-right: -$gutter;
                    padding: ($baseline * 2) 0;
                }

                @include respond-to(md) {
                    padding: 0 0 0 10px;
                }
            }

            .sh--shrunk & {
                .simplicity & {
                    @include respond-to(md) {
                        padding: 0;
                    }
                }
            }

            .sh--open & {
                .simplicity & {
                    @include respond-to(md) {
                        padding: ($baseline * 3) 0 ($baseline * 7);
                    }
                }

                .simplicity--patch-and-purr & {
                    @include respond-to(md) {
                        padding: ($baseline * 7) 0;
                    }
                }
            }
        }

        // sub-level:
        .sh__list-item > & {
            height: 0;
            padding: 0;
            overflow: hidden;

            transition: height $sh-transition-slide-time ease-in;

            @include respond-to(md) {
                width: 300px;

                position: absolute;
                z-index: 1;
            }

            .simplicity & {
                background-color: $sl-color-dark;

                @include respond-to(null, sm) {
                    margin-left: -$gutter;
                    margin-right: -$gutter;
                }

                @include respond-to(md) {
                    background-color: $sl-color-dark-secondary;
                    top: calc(100% + 28px);
                }
            }

            .white-lady & {
                font-weight: 400;

                @include respond-to(md) {
                    background-color: $wl-color-dark-secondary;
                    border-radius: 0 0 7px 7px;
                    top: 100%;
                }
            }
        }

        &.sh__list--expanded {
            &:before {
                .sh__list-item > & {
                    @include respond-to(md) {
                        opacity: 1;
                        transition-delay: 0s;
                    }
                }
            }
        }

        &-item {
            display: block;
            margin: 0;
            padding: 0;

            // main-level items:
            .sh__nav > .sh__list > & {
                font-size: px-to-rem(18px);
                line-height: px-to-rem($baseline * 6);
                position: relative;

                &:last-child .sh__list {
                    right: 0;
                }

                .sh__tray--align-full-width & {
                    margin-right: 0;
                }

                .sh__tray--align-left &, .sh__tray--align-right & {
                    margin-right: px-to-rem(25px);

                    &:last-of-type {
                        margin-right: 0;
                    }
                }

                .simplicity & {
                    @include respond-to(lg) {
                        font-size: px-to-rem($baseline * 5);
                        line-height: px-to-rem($baseline * 7);
                    }
                }

                .white-lady & {
                    @include respond-to(md) {
                        display: inline-block;
                        margin: 0;
                        padding-bottom: $baseline * 3;
                    }
                }

                .sh--shrunk & {
                    .white-lady & {
                        @include respond-to(lg) {
                            padding-bottom: $baseline * 9;
                        }
                    }
                }
            }

            // sub-level items:
            .sh__list-item > .sh__list > & {
                &:first-child {
                    .simplicity & {
                        margin-top: $baseline * 5;

                        @include respond-to(md) {
                            border-top-width: 0;
                            margin-top: $baseline * 8;
                        }
                    }

                    .white-lady & {
                        @include respond-to(md) {
                            margin-top: $baseline * 2;
                        }
                    }
                }

                &:last-child {
                    .simplicity & {
                        margin-bottom: $baseline * 5;
                    }

                    .white-lady & {
                        @include respond-to(md) {
                            margin-bottom: $baseline * 2;
                        }
                    }
                }

                &:nth-child(odd) {
                    .white-lady & {
                        @include respond-to(null, sm) {
                            background-color: $wl-color-light-secondary-gradient;
                        }
                    }
                }

                &:nth-child(even) {
                    .white-lady & {
                        @include respond-to(null, sm) {
                            background-color: $wl-color-light-secondary;
                        }
                    }
                }

                .simplicity & {
                    font-size: px-to-rem(18px);
                    line-height: px-to-rem(24px);
                    padding: ($baseline * 2) 30px;

                    @include respond-to(md) {
                        border-top: 2px solid $sl-color-dark;
                        font-size: px-to-rem(20px);
                        line-height: px-to-rem(32px);
                    }
                }

                .white-lady & {
                    font-size: px-to-rem(16px);
                    line-height: px-to-rem(20px);
                }
            }
        }
    }

    &__link {
        text-decoration: none;

        .simplicity &,
        .white-lady & {
            color: $sl-color-light;
        }

        .simplicity & { display: block; }

        .white-lady & {
            text-transform: uppercase;
            transition: background-color $sh-transition-slide-time linear,
                        color $sh-transition-slide-time linear;

            @include respond-to(null, sm) {
                display: block;
                padding: 10px 30px;
            }

            @include respond-to(md) {
                padding: 10px 25px;
            }
        }

        // main level or level I links
        .sh__nav > .sh__list > .sh__list-item > & {
            position: relative;

            .simplicity & {
                padding: ($baseline * 2) 0;

                @include respond-to(md) {
                    opacity: 1;
                    visibility: visible;

                    transition: padding $sh-transition-fade-time ease,
                                opacity $sh-transition-fade-time ease;
                }
            }

            .white-lady & {
                font-weight: 600;

                @include respond-to(md) {
                    color: $wl-color-dark;
                    padding: 0;
                }
            }

            .sh--shrunk & {
                .simplicity & {
                    @include respond-to(md) {
                        height: 0;
                        opacity: 0;
                        padding: 0;
                        visibility: hidden;
                        &:after {
                            bottom: -26px;
                        }
                    }
                }
            }

            .sh--open & {
                .simplicity & {
                    @include respond-to(md) {
                        opacity: 1;
                        padding: ($baseline * 2) 0;
                        visibility: visible;
                    }
                }
            }

            &[aria-expanded='true'] {
                .white-lady & {
                    color: $wl-color-dark-secondary;

                    @include respond-to(null, sm) {
                        background-color: $wl-color-light-secondary;
                    }
                }
            }

            &:after {
                .simplicity & {
                    @include respond-to(md) {
                        background-color: transparent;
                        content: '';
                        height: 3px;
                        width: 100%;

                        position: absolute;
                        left: 0;
                        bottom: -2px;

                        transition: background-color $sh-transition-slide-time linear;
                    }
                }
            }
        }

        // sub-level or level II links:
        .sh__list-item > .sh__list > .sh__list-item > & {
            display: block;

            .white-lady & {
                @include respond-to(null, sm) {
                    color: $wl-color-dark-secondary;
                }
            }
        }

        &.sh__link--active,
        &:focus,
        &:hover {
            // main level or level I links
            .sh__nav > .sh__list > .sh__list-item > & {
                &:after {

                    .simplicity & {
                        @include respond-to(md) {
                            background-color: $sl-color-light;
                        }
                    }
                }
            }

            // sub-level or level II links:
            .sh__list-item > .sh__list > .sh__list-item > & {
                .white-lady & {

                    @include respond-to(md) {
                        color: $wl-color-dark-secondary;
                        background-color: $wl-color-light-secondary;
                    }
                }
            }
        }
    }

    &__icon {
        .simplicity & { display: none; }

        .white-lady & {
            color: $wl-color-dark;
            font-size: px-to-rem(12px);

            transition: transform $sh-transition-slide-time ease;

            @include respond-to(null, sm) {
                color: $wl-color-light;
                position: absolute;
                top: 10px;
                right: 30px;
            }

            @include respond-to(md) {
                display: inline-block;
            }
        }

        &:before {
            display: block;
        }

        [aria-expanded='true'] + & {
            .white-lady & {
                color: $wl-color-dark-secondary;

                @include respond-to(null, sm) {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__button {
        @extend %sh-button;
        font-size: px-to-rem(8px);
        line-height: px-to-rem($baseline * 12);
        height: $baseline * 12;
        padding: 0;
        width: $baseline * 12;

        position: absolute;
        top: 0;
        right: -16px;

        @include respond-to(md) {
            display: none;
        }

        .white-lady & { display: none; }

        &:focus { outline: 0; }

        .icon {
            height: $baseline * 12;
            width: $baseline * 12;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            transform: rotate(0);

            transition: transform $sh-transition-slide-time ease;
        }

        &[aria-expanded='true'] {
            .icon {
                transform: rotate(180deg);
            }
        }
    }

    &__fake-background {
        display: none;

        .simplicity & {
            background: $sl-color-dark-gradient;
            display: block;
            height: $baseline * 25;
            left: 50%;
            margin-left: -50vw;
            position: absolute;
            top: 0;
            width: 100vw;

            @include respond-to(md) {
                height: 188px;
            }

            @include respond-to(lg) {
                height: 212px;
            }
        }
    }
}
