$full-width-sections: (
    '.section--header',
    '.section--complex-header',
    '.section--footer',
    '.section--segment',
    '.section--customization-heading',
    '.section--inclusions',
    '.section--carousel',
    '.section--product-container',
    '.section--home-result',
    '.section--complex-header',
    '.section--comparison',
    '.section--fp-heading'
);

// Padding/margin for row.
.aem-Grid {
    margin-left: -$gutter / 2;
    width: calc(100% + #{$gutter});

    @include respond-to(md) {
        margin-left: -$gutter-md / 2;
        width: calc(100% + #{$gutter-md});
    }

    @include respond-to(lg) {
        margin-left: -$gutter-lg / 2;
        width: calc(100% + #{$gutter-lg});
    }

    @include respond-to(xl) {
        margin-left: -$gutter-xl / 2;
        width: calc(100% + #{$gutter-xl});
    }

    &--tight {
        padding-bottom: $section-gutter / 2 - $gutter-tight / 2;
        padding-top: $section-gutter / 2 - $gutter-tight / 2;

        @include respond-to(sm) {
            margin-left: -$gutter-tight / 2;
            width: calc(100% + #{$gutter-tight});
        }

        @include respond-to(md) {
            padding-bottom: $section-gutter-md / 2 - $gutter-tight / 2;
            padding-top: $section-gutter-md / 2 - $gutter-tight / 2;
        }

        @include respond-to(lg) {
            padding-bottom: $section-gutter-lg / 2 - $gutter-tight / 2;
            padding-top: $section-gutter-lg / 2 - $gutter-tight / 2;
        }

        @include respond-to(xl) {
            padding-bottom: $section-gutter-xl / 2 - $gutter-tight / 2;
            padding-top: $section-gutter-xl / 2 - $gutter-tight / 2;
        }
    }
}

// Horizontal gutters
.aem-GridColumn {
    padding-left: $gutter / 2;
    padding-right: $gutter / 2;

    @include respond-to(md) {
        padding-left: $gutter-md / 2;
        padding-right: $gutter-md / 2;
    }

    @include respond-to(lg) {
        padding-left: $gutter-lg / 2;
        padding-right: $gutter-lg / 2;
    }

    @include respond-to(xl) {
        padding-left: $gutter-xl / 2;
        padding-right: $gutter-xl / 2;
    }

    .aem-Grid--tight & {
        @include respond-to(sm) {
            padding-left: $gutter-tight / 2;
            padding-right: $gutter-tight / 2;
        }
    }
}

// Fix for offset of default column appearing on other breakpoints.
.aem-GridColumn {
    @include respond-to(0, xs) {
        &[class*="aem-GridColumn--offset--default--"]:not([class*="aem-GridColumn--offset--phone--"]) {
            margin-left: 0 !important;
        }

        &.aem-GridColumn--offset--phone--0 {
            margin-left: 0 !important;
        }
    }

    @include respond-to(sm, md) {
        &[class*="aem-GridColumn--offset--default--"]:not([class*="aem-GridColumn--offset--tablet--"]) {
            margin-left: 0 !important;
        }

        &.aem-GridColumn--offset--tablet--0 {
            margin-left: 0 !important;
        }
    }
}

// Vertical gutters
.aem-GridColumn {
    &.section,
    &.cmp {
        margin-bottom: $section-gutter / 2;
        margin-top: $section-gutter / 2;

        @include respond-to(md) {
            margin-bottom: $section-gutter-md / 2;
            margin-top: $section-gutter-md / 2;
        }

        @include respond-to(lg) {
            margin-bottom: $section-gutter-lg / 2;
            margin-top: $section-gutter-lg / 2;
        }

        @include respond-to(xl) {
            margin-bottom: $section-gutter-xl / 2;
            margin-top: $section-gutter-xl / 2;
        }

        .aem-Grid--tight & {
            margin-bottom: $gutter-tight / 2;
            margin-top: $gutter-tight / 2;
        }
    }
}

// Vertical gutters between full width components have special rules.
@each $first-section in $full-width-sections {
    .aem-GridColumn#{$first-section} {

        // By default full width components don't have margins between them.
        margin: 0;

        // .section and .cmp may be non-full width components, so if they sit adjecent to a full
        // width component, add extra margins between them.
        + .section,
        + .cmp,
        .section + &,
        .cmp + & {
            margin-top: $baseline * 12;

            @include respond-to(md) {
                margin-top: $baseline * 16;
            }
        }

        // For full width components that sit next to other full width components, overwrite extra
        // margin added and set it to 0.
        @each $second-section in $full-width-sections {
            + .aem-GridColumn#{$second-section} {
                margin-top: 0;
            }
        }
    }
}

// If the component is the last child, and if it is not a full width component,
// add extra padding at the end.
.root > .aem-Grid > .responsivegrid > .aem-Grid > {
    $not-list: '.aem-GridColumn.section';

    @each $current-section in $full-width-sections {
        $not-list: '#{$not-list}:not(#{$current-section})';
    }

    #{$not-list},
    .cmp {
        &:first-child {
            margin-top: $baseline * 12;

            @include respond-to(md) {
                margin-top: $baseline * 16;
            }

            .simplicity & {
                margin-top: $baseline * 14;

                @include respond-to(md) {
                    margin-top: $baseline * 19;
                }
            }
        }

        &:last-child {
            margin-bottom: $baseline * 12;

            @include respond-to(md) {
                margin-bottom: $baseline * 16;
            }
        }
    }

    .section--sub-menu .cmp-text {
        margin-top: $baseline * 12;

        @include respond-to(md) {
            margin-top: $baseline * 16;
        }

        .simplicity & {
            margin-top: $baseline * 14;

            @include respond-to(md) {
                margin-top: $baseline * 19;
            }
        }
    }
}
