.inclusions {
    &__heading {
        font-size: px-to-rem(18px);
        font-weight: bold;
        line-height: px-to-rem($baseline * 5);
        margin-bottom: 0;
        text-transform: uppercase;

        @include respond-to(md) {
            font-size: px-to-rem(24px);
            line-height: px-to-rem($baseline * 8);
        }
    }

    .cmp-text {
        margin-top: $baseline * 6;
    }

    &__subheading {
        font-family: inherit;
        font-weight: bold;
    }

    ul {
        list-style: none;
        margin: $baseline * 10 0 0 0;
        padding: 0;

        @include respond-to(md) {
            column-count: 2;
        }

        + .cmp-text {
            margin-top: $baseline * 10;
        }
    }

    li {
        margin-bottom: $baseline;
        padding-left: $baseline * 6;
        position: relative;

        &:before {
            content: "\2713\0020";
            left: 0;
            position: absolute;
            margin-right: $baseline;
        }
    }

    .cmp-text p,
    li {
        @include respond-to(sm) {
            font-size: px-to-rem(20px);
            line-height: px-to-rem($baseline * 7);
        }
    }
}
