.comparison {

    // Table
    $color-cell-background-1: #f9f4ef;
    $color-cell-background-2: #fff;
    $color-border: #736057;
    $brand-cell-last-border: #736057;
    $brand-cell-border: #fff;
    $cell-heading-width: 130px;
    $cell-heading-width-md: 220px;
    $cell-width: 150px;
    $cell-width-md: 247px;
    $cell-width-lg: 240px;
    $cell-width-xl: 212px;

    // Arrow
    $arrow-color: #e65f00;

    // Tool tip
    $color-tool-tip-background-1: #fff;
    $color-tool-tip-background-2: #e6e6e6;
    $color-tool-tip: #666;

    // Feature icon colors
    $includes-color: #079665;
    $upgrades-color: #0e5cb5;
    $add-ons-color: #f0b802;
    $not-included-color: #ea003b;

    min-height: 150px;

    .button {
        min-width: 0;

        @include respond-to(0, sm) {
            font-weight: normal;
            max-width: auto;
            padding-left: $baseline * 4;
            padding-right: $baseline * 4;
            width: 100%;
        }
    }

    &__body,
    .fp-heading {
        display: none;
    }

    &--loaded,
    [class*='aem-AuthorLayer'] & {
        .comparison__body,
        .fp-heading {
            display: block;
        }
    }

    &__body {
        position: relative;
    }

    &__body-background {
        display: block;
        height: 100%;
        left: 50%;
        margin-left: -50vw;
        position: absolute;
        top: 0;
        width: 100vw;
    }

    &__body-background {
        background-position: center center;
        background-size: cover;
    }

    &__body-content {
        padding: $baseline * 6 0;
        position: relative;

        &--table {
            display: none;

            @include respond-to(md) {
                display: block;
            }
        }

        &--lists {
            position: relative;

            @include respond-to(md) {
                display: none;
            }
        }

        .cmp-text {
            margin-bottom: $baseline * 6;

            @include respond-to(md) {
                margin-bottom: $baseline * 8;
            }
        }
    }

    &__feature-legend {
        @include respond-to(md) {
            display: table;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__feature {
        padding: $baseline * 3 0 0 0;

        @include respond-to(md) {
            display: table-cell;
            padding: $baseline * 6 $baseline * 6 0 $baseline * 6;
            vertical-align: middle;
        }
    }

    &__feature-text {
        margin-left: $baseline * 4;
    }

    &__feature-icon {
        height: $baseline * 4;
        margin: auto;
    }

    &__results-form {
        @extend %funeral-planner-border;
        overflow: hidden;

        @include respond-to(0, sm) {
            border-radius: 0;
            border-left: 0;
            border-right: 0;
            margin-left: -$gutter;
            width: calc(100% + #{$gutter * 2});
        }
    }

    &__options {
        background: $color-cell-background-1;
        border-bottom: 1px solid $color-border;
        font-size: px-to-rem(16px);
        height: $baseline * 14;
        line-height: px-to-rem($baseline * 4);
        overflow: hidden;
        position: relative;

        &:after {
            clear: both;
            content: '';
            display: block;
        }
    }

    .general-form__label,
    .general-form__select-wrapper {
        display: inline-block;
    }

    &__sort {
        display: inline-block;
        float: left;
        padding: $baseline * 3;

        .comparison--brand-only & {
            display: none;
        }

        .general-form__label {
            @include respond-to(0, sm) {
                display: none;
            }
        }
    }

    .general-form__select {
        min-height: px-to-rem($baseline * 4);
        padding-bottom: $baseline;
        padding-top: $baseline;
    }

    &__pagination-container {
        display: none;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;

        @include respond-to(md) {
            display: block;
        }
    }

    &__pagination {
        display: table;
        height: 100%;
        margin-right: -1px;

        .comparison--no-results & {
            display: none;
        }
    }

    &__pagination-button {
        border-radius: 0;
        color: $color-01;
        display: table-cell;
        float: left;
        font-size: px-to-rem(20px);
        height: 100%;
        padding: 0 $baseline * 6;
    }

    &__pagination-label {
        display: table-cell;
        font-size: px-to-rem(20px);
        height: 100%;
        padding: 0 $baseline * 6;
        text-align: center;
        vertical-align: middle;
        width: 248px;
    }

    &__button-cell {
        text-align: left;
        vertical-align: top;
    }

    &__button-compare-description {
        .comparison--brand-only & {
            display: none;
        }
    }

    &__submit-button.button,
    &__reset-button.button {
        margin: 0;
        width: 100%;

        .comparison--brand-only & {
            display: none;
        }
    }

    &__submit-button {
        .comparison--filtered & {
            display: none;
        }
    }

    &__reset-button {
        display: none;

        .comparison--filtered & {
            display: block;
        }
    }

    &__type {
        display: none;
        margin-top: $baseline * 2;
        text-transform: capitalize;

        .comparison--brand-only & {
            display: block;
        }
    }

    &__tool-tip-hotzone {
        position: relative;

        .comparison__tool-tip {
            opacity: 0;
            transition: opacity .3s linear .5s;
        }

        &:hover,
        &:focus {
            .comparison__tool-tip {
                visibility: visible;
                opacity: 1;
            }
        }

        .icon {
            margin-top: -$baseline / 2;
            display: inline-block;
            vertical-align: middle;
        }
    }

    &__tool-tip-info {
        display: inline-block;
        position: relative;
    }

    &__tool-tip {
        background: $color-tool-tip-background-1;
        background: linear-gradient(to bottom, $color-tool-tip-background-1 0%, $color-tool-tip-background-2 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color-tool-tip-background-1}', endColorstr='#{$color-tool-tip-background-2}', GradientType=0);
        bottom: calc(100% + #{$baseline * 4});
        border: 1px solid $color-border;
        border-radius: 6px;
        color: $color-tool-tip;
        display: block;
        font-size: px-to-rem(12px);
        font-weight: normal;
        left: calc(50% - #{$baseline * 20});
        line-height: px-to-rem($baseline * 4);
        padding: $baseline * 3;
        position: absolute;
        text-align: left;
        visibility: hidden;
        white-space: normal;
        width: $baseline * 40;
        z-index: 1;

        @include respond-to(sm) {
            left: calc(50% - #{$baseline * 30});
            width: $baseline * 60;
        }

        .comparison__tool-tip-hotzone--left & {
            left: calc(50% - #{$baseline * 4});
        }

        .comparison__tool-tip-hotzone--right & {
            left: calc(50% - #{$baseline * 36});

            @include respond-to(sm) {
                left: calc(50% - #{$baseline * 56});
            }
        }

        &:before,
        &:after {
            border-width: 12px;
            border-style: solid;
            border-color: $color-tool-tip-background-2 transparent transparent transparent;
            content: " ";
            left: calc(50% - 12px);
            position: absolute;
            top: 100%;

            .comparison__tool-tip-hotzone--left & {
                left: $baseline * 1;
            }

            .comparison__tool-tip-hotzone--right & {
                left: auto;
                right: $baseline * 1;
            }
        }

        &:before {
            border-color: $color-border transparent transparent transparent;
            top: calc(100% + 1px);
        }
    }

    &__table-container {
        position: relative;
    }

    &__table-scroll {
        clear: both;
        min-height: 70px;
        overflow-x: scroll;

        @include respond-to(md) {
            -ms-overflow-style: -ms-autohiding-scrollbar;
            overflow: -moz-scrollbars-none;
            margin-left: $cell-heading-width-md;
        }

        &::-webkit-scrollbar {
            @include respond-to(md) {
                display: none;
            }
        }
    }

    &__table {
        border-spacing: 0;
        text-align: center;
    }

    &__table-heading {
        display: inline-block;
        vertical-align: middle;

        + .icon {
            display: inline-block;
            vertical-align: middle;
        }
    }

    &__package-heading-inner {
        display: table;
        height: 100%;
        position: relative;
        width: 100%;
    }

    &__package-heading-content {
        display: table-cell;
        vertical-align: bottom;
    }

    .comparison__package-heading {
        padding: $baseline * 18 0 $baseline * 3 0;
        vertical-align: bottom;

        @include respond-to(md) {
            padding: $baseline * 26 0 $baseline * 3 0;
        }
    }

    &__package-logo {
        left: 50%;
        max-height: $baseline * 10;
        max-width: 90%;
        position: absolute;
        top: -$baseline * 9;
        transform: translate(-50%, -50%);

        @include respond-to(md) {
            max-height: $baseline * 16;
            top: -$baseline * 13;
        }
    }

    .general-form__checkbox,
    .general-form__checkbox:checked {
         + .general-form__label {
             color: inherit;
             height: 100%;
             padding: 0 $baseline * 3 $baseline * 8 $baseline * 3;
             text-align: center;

             &:before {
                 bottom: 0;
                 border-width: 1px;
                 left: calc(50% - 12px);
                 top: auto;
             }

             &:after {
                 bottom: 8px;
                 font-weight: normal;
                 left: calc(50% - 6px);
                 top: auto;
             }
         }
    }

    &--filtered,
    &--brand-only {
        .general-form__checkbox {
            display: none;
        }

        .general-form__checkbox:checked + .general-form__label,
        .general-form__checkbox + .general-form__label {
            padding: 0 $baseline * 3;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    tr {
        &:nth-child(2n) {
            th,
            td {
                background: $color-cell-background-1;
            }
        }

        &:nth-child(2n + 1) {
            th,
            td {
                background: $color-cell-background-2;
            }
        }

        &:last-child {
            th,
            td {
                background: transparent;
                vertical-align: top;
            }
        }
    }

    &--no-results tr:not(:first-child) {
        display: none;
    }

    th,
    td,
    .general-form__label {
        font-size: px-to-rem(16px);
        line-height: px-to-rem($baseline * 6);
    }

    th,
    td {
        padding: $baseline * 3;

        .icon:before {
            display: block;
        }
    }

    th:first-child {
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        border-right: 1px solid $brand-cell-last-border;
        left: 0;
        min-width: $cell-heading-width;
        max-width: $cell-heading-width;
        text-align: left;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);

        @include respond-to(md) {
            min-width: $cell-heading-width-md;
            max-width: $cell-heading-width-md;
            position: absolute;
        }
    }

    th,
    td {
        min-width: $cell-width;
        max-width: $cell-width;

        @include respond-to(md) {
            min-width: $cell-width-md;
            max-width: $cell-width-md;
        }

        @include respond-to(lg) {
            min-width: $cell-width-lg;
            max-width: $cell-width-lg;
        }

        @include respond-to(xl) {
            min-width: $cell-width-xl;
            max-width: $cell-width-xl;
        }
    }

    &__cell {
        border-right: 1px solid $brand-cell-last-border;
    }

    &__product,
    &__product + .icon {
        display: inline-block;
        vertical-align: middle;
    }

    &__price-text {
        display: none;

        [data-show-price="true"] & {
            display: block;
        }
    }

    &__price {
        display: none;
        font-size: px-to-rem(29px);
        font-weight: bold;
        line-height: px-to-rem($baseline * 6);
        margin: $baseline 0;

        @include respond-to(sm) {
            line-height: px-to-rem($baseline * 6);
        }

        [data-show-price="true"] & {
            display: block;
        }

        + .button {
            margin-top: $baseline * 3;
        }
    }

    &__text {
        margin: $baseline * 4 0;
    }

    &__pack-lists {
        overflow: hidden;
        margin-left: -$gutter;
        width: calc(100% + #{$gutter * 2});

        .slick-arrow {
            appearance: normal;
            background: transparent;
            border: 0;
            top: 50%;
            cursor: pointer;
            font-size: 0;
            padding: 0;
            position: absolute;
            z-index: 1;

            &:after {
                border: 0;
                border-top: 4px solid $arrow-color;
                border-right: 4px solid $arrow-color;
                content: '';
                display: block;
                height: $baseline * 4.5;
                width: $baseline * 4.5;
            }
        }

        .slick-prev {
            left: $baseline * 5;
            transform: rotate(-135deg);
        }

        .slick-next {
            right: $baseline * 5;
            transform: rotate(45deg);
        }

        .slick-list {
            padding: 0 $baseline * 6 !important;
        }
    }

    &__pack-list {
        border-bottom: 1px solid $color-border;
        border-right: 1px solid $color-border;
        border-top: 1px solid $color-border;
        text-align: center;
    }

    &__pack-list-header {
        background: $color-cell-background-2;
        padding: $baseline * 6 0 $baseline * 5 0;
    }

    &__pack-list-logo {
        display: block;
        margin: 0 auto $baseline * 2 auto;
        max-width: 190px;
        width: calc(100% - #{$baseline * 4});
    }

    &__pack-list-heading {
        color: inherit;
        font-size: px-to-rem(16px);
        line-height: px-to-rem($baseline * 6);
        margin: 0;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        background: $color-cell-background-1;
        display: table;
        font-size: px-to-rem(16px);
        line-height: px-to-rem($baseline * 6);
        margin: 0;
        padding: $baseline * 3;
        width: 100%;

        &:nth-child(2n) {
            background: $color-cell-background-2;
        }

        > span {
            display: table-cell;
            vertical-align: middle;
        }
    }

    &__item-label {
        font-weight: bold;

        &--upgradeable {
            color: $upgrades-color;
        }

        &--add-on {
            color: $add-ons-color;
        }
    }

    &__pack-list-footer {
        font-size: px-to-rem(16px);
        line-height: px-to-rem($baseline * 6);
        padding: $baseline * 3;

        p {
            margin-top: $baseline * 2;
        }
    }

    &__no-results {
        border-top: 1px solid $color-border;
        display: none;
        padding: $baseline * 6;
        text-align: center;

        .comparison--no-results & {
            display: block;
        }
    }

    .slick-dots {
        left: 0;
        list-style: none;
        margin: $baseline * 5 0;
        padding: 0;
        text-align: center;
        transition: top .5s, bottom .5s;
        width: 100%;

        li {
            display: inline-block;
        }

        button {
            appearance: normal;
            background: none;
            border-radius: 50%;
            border: 1px solid #e65f00;
            cursor: pointer;
            display: block;
            font: 0 sans-serif;
            height: 14px;
            margin: 0 $baseline * 2.5;
            padding: 0;
            width: 14px;
            vertical-align: bottom;
        }

        .slick-active button {
            background: #e65f00;
        }
    }
}
