// ups pagination

.ups-pagination {
    margin-top: $baseline * 8;
    margin-bottom: $baseline * 8;
}

.ups-pagination__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.ups-pagination__link {
    width: 32px;
    height: 32px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: px-to-rem(12px);
    line-height:  px-to-rem(12px);
    font-weight: bold;
    border: 0;
    cursor: pointer;

    &:focus {
        outline: 0;
        border: 1px dotted lightgrey;
    }

    &.theme-bg-color-dark {
        color: #fff;
    }

    &:not(.theme-bg-color-dark) {
        background: transparent;

        &:hover {
            background: lightgrey;
        }
    }

    @include respond-to(md) {
        font-size: px-to-rem(16px);
        line-height:  px-to-rem(16px);
        width: 44px;
        height: 44px;
    }
}