.location-list {
    &__tabs,
    &__tab {
        margin: 0;
        padding: 0;
    }

    &__tabs {
        border-bottom: 1px solid black;
        list-style: none;

        &:after {
            content: '';
            clear: both;
            display: block;
        }
    }

    &__tab {
        float: left;
    }

    &__tab-button {
        @extend %button-reset;
        border: 1px solid black;
        font-size: px-to-rem(13px);
        margin-right: -1px;
        padding: $baseline * 4 $baseline * 4;
        position: relative;
        text-transform: uppercase;
        top: 1px;
        width: auto;

        &.button--active {
            border-bottom: 1px solid transparent;
        }
    }

    &__list,
    li {
        margin: 0;
        padding: 0;
    }

    &__list {
        column-count: 2;
        display: none;
        list-style: none;
        margin-top: $baseline * 4;

        @include respond-to(sm) {
            column-count: 4;
        }

        @include respond-to(md) {
            column-count: 5;
        }

        li {
            font-size: px-to-rem(14px);
            line-height: px-to-rem($baseline * 5);
        }

        a {
            text-decoration: none;

            .simplicity & {
                color: $color-01;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
