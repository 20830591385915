// sass-lint:disable force-pseudo-nesting no-color-literals

.contact-form {
    .general-form {
        &__label {
            display: none;
        }

        &__input,
        &__textarea,
        &__select {
            .simplicity & {
                background-color: #f3f3f3;
                border-width: 0;
                color: #009bb5;
                font-weight: 300;
            }

            &::-webkit-input-placeholder { // Chrome/Opera/Safari
                .simplicity & { color: #009bb5; }
            }

            &::-moz-placeholder { // Firefox 19+
                .simplicity & { color: #009bb5; }
            }

            &:-ms-input-placeholder { // IE 10+
                .simplicity & { color: #009bb5; }
            }

            &:-moz-placeholder { // Firefox 18-
                .simplicity & { color: #009bb5; }
            }
        }

        // Radio
        &__radio,
        &__checkbox {
            + .general-form__label {
                .simplicity & {
                    color: #fff;
                    font-weight: 300;
                    line-height: px-to-rem($baseline * 6);
                    font-size: px-to-rem(20px);
                }

                &:before {
                    .simplicity & {
                        border-width: 0;
                    }
                }

                &:after {
                    .simplicity & {
                        background-color: #009bb5;
                    }
                }
            }
        }

        &__element--checkbox + .general-form__element--checkbox,
        &__element--radio + .general-form__element--radio {
            margin-top: $baseline * 4;
        }

        &__select-wrapper {
            &:after {
                .simplicity & {
                    border-width: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                }
            }

            &:before {
                .simplicity & {
                    border-color: #00daf9;
                }
            }
        }
    }
}
