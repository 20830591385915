.jump-down {
    display: block;
    font-size: $baseline * 16;
    margin: auto;
    text-decoration: none;

    i,
    i:before {
        display: block;
    }
}
