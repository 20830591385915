// sass-lint:disable force-pseudo-nesting no-color-literals force-element-nesting placeholder-in-extend

$funeral-planner-not-selected-color: #e65f00;
$funeral-planner-selected-color: #0e6c8b;

.aem-GridColumn.section--general-form {
    margin: 0;
}

.general-form {
    &__input,
    &__textarea,
    &__select {
        appearance: none;
        border-style: solid;
        display: block;
        font-family: inherit;
        margin: 0;
        width: 100%;
    }

    &__input::placeholder,
    &__textarea::placeholder {
        color: inherit;
    }

    &__select {
        &::-ms-expand {
            display: none;
        }

        .simplicity & {
            @include respond-to(sm) {
                padding-right: $baseline * 11;
            }
        }

        .white-lady & {
            padding-right: $baseline * 13;
        }
    }

    &__textarea {
        max-width: 100%;
        min-height: $baseline * 18;
    }

    &__input,
    &__textarea,
    &__select,
    &__textarea {
        &.general-form__element--error {
            box-shadow: 0 0 0 2px red;
        }

        &:invalid {
            .general-form--show-validation & {
                box-shadow: 0 0 0 2px red;
            }
        }

        &.general-form__element--valid {
            box-shadow: 0 0 0 2px rgba(187, 221, 224, 1);
        }
    }

    &--show-validation {
        .general-form__radio:invalid,
        .general-form__checkbox:invalid {
            + .general-form__label:before {
                box-shadow: 0 0 0 2px red;
            }
        }
    }

    &__select-wrapper {
        position: relative;

        .white-lady & {
            @extend .icon, .icon-caret-down;

            color: #9b1e34;
        }

        &:after {
            background: #fff;
            bottom: 0;
            content: '';
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            width: $baseline * 10;

            .simplicity & {
                border-left-style: solid;
                width: 62px;

                @include respond-to(sm) {
                    width: $baseline * 11;
                }
            }

            .white-lady & {
                border-left-style: solid;
                width: $baseline * 13;
            }

            .funeral-planner & {
                width: $baseline * 15;
            }
        }

        &:before {
            @include chevron(down, black);
            pointer-events: none;
            position: absolute;
            z-index: 2;
            right: $baseline * 5;
            top: 50%;

            .simplicity & {
                border-color: #005170;
                right: 32px;

                @include respond-to(sm) {
                    right: 22px;
                }
            }

            .white-lady & {
                font-size: px-to-rem(8px);
                line-height: px-to-rem($baseline * 6);

                // reset:
                border: 0;
                margin: -($baseline * 6 / 2) 0 0;
                height: auto;
                width: auto;
                transform: none;
            }
        }
    }

    // Radio
    &__radio,
    &__checkbox {
        border: 0;
        height: 0;
        opacity: 0;
        position: absolute;
        width: 0;

        + .general-form__label {
            color: inherit;
            display: block;
            line-height: $baseline * 6;
            position: relative;
            padding-left: $baseline * 9;

            &:before,
            &:after {
                box-sizing: border-box;
                content: '';
                display: block;
                position: absolute;
            }

            &:before {
                border-color: black;
                border-style: solid;
                border-width: 2px;
                height: $baseline * 6;
                left: 0;
                top: calc(50% - 12px);
                width: $baseline * 6;
            }

            &:after {
                background: black;
                height: 12px;
                left: 6px;
                opacity: 0;
                top: calc(50% - 6px);
                width: 12px;
                transition: opacity .3s;
            }
        }

        &:checked + .general-form__label {
            &:after {
                opacity: 1;
            }
        }

        &:focus + .general-form__label {
            &:before {
                box-shadow: 0 0 4px $funeral-planner-selected-color;
            }
        }
    }

    &__radio {
        + .general-form__label {
            &:before,
            &:after {
                border-radius: 100%;
            }
        }
    }

    &__element--range {
        padding-bottom: $baseline * 6;
    }

    &__range-inner {
        padding-top: $baseline * 9;
        position: relative;
    }

    &__range-value-container {
        margin: 0 $baseline * 4;
        pointer-events: none;
        position: relative;
        top: -$baseline * 9;
    }

    &__range-background-container {
        background: #e8f4f5;
        border: 3px solid $funeral-planner-selected-color;
        border-radius: $baseline * 2;
        bottom: 6px;
        height: $baseline * 4 - 2px;
        position: absolute;
        left: 3px;
        width: calc(100% - 6px);
    }

    &__range-background {
        background: $funeral-planner-not-selected-color;
        border-top-left-radius: $baseline * 2;
        border-bottom-left-radius: $baseline * 2;
        height: calc(100% + 6px);
        left: -3px;
        position: absolute;
        top: -3px;
    }

    &__range-value {
        color: $funeral-planner-selected-color;
        font-size: px-to-rem(16px);
        font-weight: bold;
        position: absolute;
        top: 0;
        transform: translateX(-50%);

        &:before {
            content: '$';
        }
    }

    &__range-min-value,
    &__range-max-value {
        bottom: -$baseline * 6;
        position: absolute;

        &:before {
            content: '$';
        }
    }

    &__range-min-value {
        left: 3px;
    }

    &__range-max-value {
        right: 3px;
    }

    &__range {
        appearance: none;
        background: none;
        height: $baseline * 4;
        margin: 0;
        position: relative;
        width: 100%;

        &::-webkit-slider-thumb {
            appearance: none;
            background: $color-01;
            box-shadow: 0;
            border: 3px solid $funeral-planner-not-selected-color;
            border-radius: 50%;
            cursor: pointer;
            height: 26px;
            width: 26px;
        }
    }

    &__error {
        color: red;
        display: none;
        font-size: px-to-rem(14px);
        margin-top: $baseline;
        text-align: right;

        .general-form--show-validation & {
        }
    }

    [type="checkbox"] ~ &__error {
        text-align: left;
    }
}

fieldset {
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;

    legend {
        @include sr-only();
    }
}

// Funeral planner specific.
.section--complex-header ~ *,
.modal__dialog--quote {
    .general-form__radio + .general-form__label,
    .general-form__checkbox + .general-form__label {
        &:before {
            border-color: $funeral-planner-not-selected-color;
        }

        &:after {
            background: $funeral-planner-not-selected-color;
        }
    }

    .general-form__radio:checked + .general-form__label,
    .general-form__checkbox:checked + .general-form__label {
        &:before {
            border-color: $funeral-planner-selected-color;
        }

        &:after {
            background: $funeral-planner-selected-color;
        }
    }

    .general-form__checkbox + .general-form__label,
    .general-form__checkbox:checked + .general-form__label {
        &:before {
            border-radius: 0;
            height: $baseline * 6;
            left: 0;
            width: $baseline * 6;
        }

        &:after {
            background: transparent;
            border: 2px solid $funeral-planner-selected-color;
            border-left-width: 0;
            border-top-width: 0;
            content: '';
            height: $baseline * 3;
            margin-top: -1px;
            margin-left: 2px;
            transform: rotateZ(45deg);
            width: 7px;
        }
    }

    .quote-form,
    .product-container,
    .customize-form {
        .general-form__select,
        .general-form__textarea {
            border: 1px solid $funeral-planner-not-selected-color;
        }
    }
}

.section--complex-header + * .general-form__select-wrapper::before {
    display: none;
}

.section--complex-header ~ * .quote-form__select-icon {
    pointer-events: none;

    &:before {
        border-radius: 50%;
        border-width: 2px;
        border-style: solid;
        content: '';
        display: block;
        height: 21px;
        position: absolute;
        right: $baseline * 4.5;
        top: calc(50% - 12px);
        width: 21px;
        z-index: 2;
    }

    &:after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top-width: 5px;
        border-top-style: solid;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        right: 25px;
        top: calc(50% - 1px);
        width: 0;
        z-index: 2;
    }
}

.search-form {
    .simplicity &,
    .white-lady & {
        position: relative;
    }
}
