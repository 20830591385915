$banner-bkg-color: #F6F3EF;

// banner
.ups-banner {
    background-color: $banner-bkg-color; //todo var
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $baseline * 8;
    text-align: center;
    position: relative;

    @include respond-to(sm) {
        justify-content: space-evenly;
    }
    @include respond-to(lg) {
        padding-top: $baseline * 16;
        padding-bottom: $baseline * 16;
    }

    &::before {
        content: '';
		display: block;
		background: $banner-bkg-color;
        width: 8.7%;
		height: 100%;
		position: absolute;
        left: 100%;
        margin-top: -($baseline * 8);
        
        @include respond-to(lg) {
            margin-top: 0;
            width: 100%;
        }
    }

    &::after {
        content: '';
		display: block;
		right: 100%;
		width: 100%;
		height: 100%;
		position: absolute;
        background: $banner-bkg-color;
        margin-top: -($baseline * 8);
        
        @include respond-to(lg) {
            margin-top: 0;
        }
    }
}

.ups-banner__details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ups-banner__title {
    font-size: px-to-rem(20px);
    line-height:  px-to-rem(24px);
    margin-top: $baseline * 4;
    font-weight: bold;

    span {
        display: block;
    }

    @include respond-to(lg) {
        font-size: px-to-rem(40px);
        line-height:  px-to-rem(48px);
        margin-bottom: $baseline * 8;
    }
}

.ups-banner__subtitle {
    font-size: px-to-rem(14px);
    line-height:  px-to-rem(21px);
    margin-bottom: $baseline * 8;
    max-width: 500px;

    @include respond-to(lg) {
        font-size: px-to-rem(20px);
        line-height:  px-to-rem(28px);
    }
}


// search
$ups-search-icon-width-sm: 60px;
$ups-search-icon-width-md: 70px;

.ups__search {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    transform: translateY(50%);
    padding-left: $baseline * 4;
    padding-right: $baseline * 4;

    @include respond-to(lg) {
        width: 100%;
    }
}

.ups__search-field {
    max-width: 620px;
    width: 100%;
    position: relative;
}

.ups__search-icon {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: $ups-search-icon-width-sm;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @include respond-to(md) {
        width: $ups-search-icon-width-md;
    }
}

.ups__search-input {
    border-radius: 100px;
    border: 1px solid #DCDCDC;
    padding: $baseline * 3.5 $baseline * 5;
    padding-left: ($ups-search-icon-width-sm - $baseline * 3);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-size: px-to-rem(16px);

    &:focus {
        outline: 0;
        border: 1px solid currentColor;
    }

    /* clears the 'X' from Internet Explorer */
    &::-ms-clear,
    &::-ms-reveal {
        display: none; 
        width : 0; 
        height: 0;
    }

    /* clears the 'X' from Chrome */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration { 
        display: none;
    }

    @include respond-to(md) {
        padding: $baseline * 5;
        padding-left: ($ups-search-icon-width-md - $baseline * 3);
        width: 100%;
    }
    
    @include respond-to(lg) {
        width: 100%;
    }
}

// cards

.ups-cards {
    margin-top: $baseline * 16;
}

.ups-card {
    padding-top: $baseline * 8;
    padding-bottom: $baseline * 8;

    + .ups-card {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
    }

    @include respond-to(sm) {
        padding: $baseline * 8;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    @include respond-to(lg) {
        padding: $baseline * 10;
        margin: 0 46px;
    }
}

.ups-card__toolbar {
    padding: 0;
    margin: 0 0 $baseline * 2;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    @include respond-to(sm) {
        margin-bottom: $baseline * 3;
    }
}

.ups-card__tool-option {
    display: block;
    padding: $baseline $baseline * 2;
    font-size: px-to-rem(12px);
    line-height:  px-to-rem(12px);
    text-decoration: none;
    margin-left: $baseline;
    display: flex;
    align-items: center;
    border-radius: 2px;

    &--green {
        background: rgba(105, 190, 40, 0.1);
        border: 1px solid #69BE28;
    }

    &--blue {
        background: rgba(91, 192, 222, 0.1);
        border: 1px solid #5BC0DE;
    }
}

a .ups-card__tool-title {
    text-decoration: underline;
    color: initial;
}

.ups-card__icon {
    margin-right: $baseline * 1.5;
    line-height: 0;
}

.ups-card__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
}

.ups-card__headshot {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: $baseline * 2;
    flex-shrink: 0;

    @include respond-to(sm) {
        width: 140px;
        height: 140px;
        margin-right: $baseline * 6;
    }
}

.ups-card__img {
    height: 100%;
}

.ups-card__profile {
    display: flex;
    flex-direction: column;
}

.ups-card__title {
    display: block;
    font-weight: normal;
    font-size: px-to-rem(14px);
    line-height:  px-to-rem(21px);

    @include respond-to(md) {
        font-size: px-to-rem(20px);
        line-height:  px-to-rem(24px);
        margin-bottom: $baseline * 2;
    }
}

.ups-card__subtitle {
    font-size: px-to-rem(12px);
    line-height:  px-to-rem(18px);
    opacity: .5;

    &--italic {
        font-style: italic;
    }

    @include respond-to(md) {
        font-size: px-to-rem(16px);
        line-height:  px-to-rem(22px);
        display: block;
        
    }
}

.ups-card__bio {
    display: none;
    word-break: break-word;

    @include respond-to(sm) {
        display: block;
        margin-top: $baseline * 2;
        font-size: px-to-rem(12px);
        line-height:  px-to-rem(18px);
    }
    
    @include respond-to(md) {
        font-size: px-to-rem(16px);
        line-height:  px-to-rem(24px);
    }
}

.ups-card__details {
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: $baseline * 4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: $baseline * 5;
    
    li {
        font-size: px-to-rem(12px);
        line-height:  px-to-rem(18px);
        display: flex;
        flex-direction: column;
        
        + li {
            margin-top: $baseline * 2;
        }
        
        @include respond-to(md) {
            font-size: px-to-rem(14px);
            line-height:  px-to-rem(21px);
        }
    }

    @include respond-to(sm) {
        margin-top: 0;
        width: 180px;
        margin-left: $baseline * 6;
        flex-shrink: 0;
    }

    @include respond-to(md) {
        width: 250px;
    }

    @include respond-to(lg) {
        padding: $baseline * 6;
    }
}

.ups-loading {
    min-height: 50vh;
    margin-top: $baseline * 16;
    display: flex;
    justify-content: center;
}

.ups-status-message {
    min-height: 200px;
    margin-top: $baseline * 16;
    display: flex;
    justify-content: center;

    @include respond-to(lg) {
        h3 {
            width: 55%;
            text-align: justify;
        }
    }
}

.ups-preloaded-state {
    .ups-status-message {
        visibility: hidden;
    }
}