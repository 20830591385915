.calculator {
    padding: $baseline * 2 $baseline * 4 $baseline * 4;

    @include respond-to(sm) {
        padding: $baseline * 4 $baseline * 7 $baseline * 8;
    }

    @include respond-to(md) {
        padding-bottom: $baseline * 14;
    }

    &__error {
        display: none;
        text-align: center;

        .calculator--error & {
            display: block;
        }
    }

    &__form {
        max-width: 630px;

        @include respond-to(sm) {
            margin: auto;
            width: 100%;
        }

        .simplicity &,
        .white-lady & {
            max-width: 740px;
        }
    }

    .calculator__years-input {
        vertical-align: top;

        @include respond-to(md) {
            display: inline-block;
            text-align: center;
            width: calc(100% - #{$baseline * 18});
        }
    }

    .calculator__years-label {
        display: block;
        margin-top: $baseline * 2;
        vertical-align: top;

        @include respond-to(md) {
            display: inline-block;
            line-height: $baseline * 12;
            margin: 0 0 0 $baseline;
        }
    }

    &__funeral-cost-element {
        position: relative;

        .general-form__input {
            padding-left: $baseline * 5;
        }
    }

    &__funeral-cost-overlay {
        background: $color-01;
        bottom: 1px;
        left: 1px;
        padding-left: $baseline * 5;
        pointer-events: none;
        position: absolute;
        right: 2px;
    }

    .general-form__input:focus + .calculator__funeral-cost-overlay {
        display: none;
    }

    .general-form__label {
        display: block;
        margin-bottom: $baseline * 2;
    }

    .section--button {
        @include respond-to(md) {
            margin-top: 0;
        }
    }

    .button {
        width: auto;
    }

    &__results {
        border-top: 2px solid $color-02;
        border-bottom: 2px solid $color-02;
        list-style: none;
        margin: $baseline * 12 auto 0 auto;
        max-width: 740px;
        padding: 0;

        @include respond-to(md) {
            margin: $baseline * 6 auto 0 auto;
        }
    }

    &__result-item {
        border-top: 1px solid $color-02;
        display: table;
        margin: 0;
        padding: $baseline * 6;
        width: 100%;

        &:last-child {
            border-bottom: 0;
        }

        &--highlighted {
            background: $color-01;
        }
    }

    &__result-description,
    &__result-value {
        @include respond-to(md) {
            display: table-cell;
        }
    }

    &__result-description {
        @include respond-to(md) {
            width: 60%;
        }
    }

    &__result-value {
        @include respond-to(md) {
            width: 40%;
        }
    }

    &__result-description {
        .cmp-text * {
            font-size: 1rem;
            line-height: 1.25rem;
        }
    }

    &__result-value {
        margin-top: $baseline * 8;

        @include respond-to(md) {
            margin: 0;
            padding-left: $baseline * 30;
            text-align: right;
        }

        .calculator__result-subtext * {
            font-size: px-to-rem(14px);
            line-height: px-to-rem(20px);
        }
    }

    &__result-heading,
    &__result-price {
        font-size: px-to-rem(22px);
        line-height: px-to-rem($baseline * 7);
        margin: 0 0 $baseline * 3 0;

        @include respond-to(md) {
            font-size: px-to-rem(30px);
            line-height: px-to-rem($baseline * 8);
        }
    }

    &__result-price {
        font-size: px-to-rem(30px);

        @include respond-to(md) {
            font-size: px-to-rem(36px);
        }
    }
}
