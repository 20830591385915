.thank-you {
    h3 {
        text-transform: uppercase;
    }

    &__features {
        margin-bottom: $baseline * 5;

        @include respond-to(lg) {
            margin: 0 0 $baseline * 5 $baseline * 10;
        }
    }

    &__feature-list {
        font-size: px-to-rem(16px);
        margin: 0 0 $baseline * 4 0;
        width: 100%;

        @media print {
            font-size: 14px;
            line-height: 1.5;
        }

        @include respond-to(sm) {
            display: table;
        }

        @media print {
            display: table;
        }
    }

    &__row {
        @include respond-to(sm) {
            display: table-row;
        }

        @media print {
            display: table-row;
        }

        dt,
        dd {
            padding: $baseline / 2 0;

            @include respond-to(sm) {
                display: table-cell;
            }

            @media print {
                display: table-cell;
            }
        }

        dt {
            display: table-cell;
            font-weight: bold;
        }

        dd {
            font-weight: 400;
            margin: 0 0 $baseline 0;
            white-space: nowrap;
            width: 50px;

            @include respond-to(sm) {
                margin: 0;
            }

            @media print {
                margin: 0;
            }
        }
    }

    &__total {
        border-radius: 4px;
        display: none;
        font-size: px-to-rem(18px);
        padding: $baseline * 4;
        text-align: center;
        text-transform: uppercase;

        .thank-you--show-price & {
            display: block;
        }
    }

    &__discussion-list {
        li {
            margin: $baseline / 2 0;
        }
    }
}
