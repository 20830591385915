.fp-heading {
    border-bottom: 3px solid $color-01;
    position: relative;

    &__background {
        display: block;
        height: 100%;
        left: 50%;
        margin-left: -50vw;
        position: absolute;
        top: 0;
        width: 100vw;
    }

    &__content {
        padding: $baseline * 4 0;
        position: relative;

        @include respond-to(sm) {
            padding: $baseline * 8 0 $baseline * 5 0;
        }
    }

    &__heading {
        font-size: px-to-rem($baseline * 7);
        line-height: px-to-rem($baseline * 8.5);
        margin-bottom: $baseline * 2.5;
        text-align: center;
        text-transform: uppercase;

        @include respond-to(sm) {
            font-size: px-to-rem($baseline * 10);
            line-height: px-to-rem($baseline * 12);
            margin-bottom: $baseline;
            text-align: left;
        }
    }

    &__description {
        font-size: px-to-rem($baseline * 5);
        line-height: px-to-rem($baseline * 6);
        text-align: left;
        text-transform: uppercase;

        @include respond-to(0, xs) {
            text-align: center;
        }
    }
}
