.textimage {
    display: inline-block;
    width: 100%;

   .textimage-left {
        float: left;
        margin-right: $baseline * 5;
    }

   .textimage-top {
        margin-bottom: $baseline * 5;
    }

   .textimage-right {
        float: right;
        margin-left: $baseline * 5;
    }
}
