.admin-wrapper {
    background-color: #efefef;
    
    .text-center { 
        margin-top: $baseline * 8; 
        font-size: $baseline * 5; 
        line-height: $baseline * 6; 
        font-weight: 400;

        @include respond-to(lg) {
            margin-top: $baseline * 10; 
            font-size: $baseline * 10; 
            line-height: $baseline * 12;
        }
    }
}

.admin-form {
    margin-bottom: $baseline * 8;

    .general-form__label { font-weight: bold; display: block; }
    .general-form__input { margin-top: 5px; }
    .general-form__textarea { min-height: $baseline * 25; }
    .help-block { display: none; }
    #noFile { margin: 10px 0; font-size: 14px; }
    .js-general-form__element--checkbox{ margin: 32px 0px; }
    #fileUpload { padding:0; }
    .admin-form__card .cmp-text p {
        font-size: $baseline * 6;
        font-weight: bold;
    }
    #fileUpload .cmp-text p {
        font-size: $baseline * 4;
        margin-bottom: 0;
    }
    #fileUpload .cmp-text div span {
        display: inline;
    }
    #fileUpload .upload-new-label {
        font-size: 14px;
        color: #686D71;
        margin: $baseline * 3 0;
    }
    .cmp-text p span {
        color: #686D71;
        font-size: 14px;
        font-weight: normal;
        margin-top: $baseline * 4;
    }

    .button {
        width: auto;
        text-transform: none;
    }
    
    &__section-heading {
        font-weight: bold;    
    }

    &__card {
        background-color: #fff;
        margin-top: $baseline * 8;
        padding: $baseline * 4 $baseline * 2;
        border-radius: 8px;

        @include respond-to(sm) {
            margin-top: $baseline * 8;
            padding: $baseline * 10 $baseline * 28;        
        }
    }
    
    @include respond-to(lg) {
        padding: 0 20%;
        margin-bottom: $baseline * 20;
    }

    .error {
        color: red;
        margin-top: 10px;
    }
    
    .general-form {
        &__label {
            font-size: px-to-rem(16px);
            line-height:  px-to-rem(22px);
        }

        &__element {
            margin-top: $baseline * 8;
        }

        &__input,
        &__textarea,
        &__select {
            .simplicity & {
                background-color: #f3f3f3;
                border-width: 0;
                color: #009bb5;
                font-weight: 300;
            }

            &::-webkit-input-placeholder { // Chrome/Opera/Safari
                .simplicity & { color: #009bb5; }
            }

            &::-moz-placeholder { // Firefox 19+
                .simplicity & { color: #009bb5; }
            }

            &:-ms-input-placeholder { // IE 10+
                .simplicity & { color: #009bb5; }
            }

            &:-moz-placeholder { // Firefox 18-
                .simplicity & { color: #009bb5; }
            }
        }

        // Radio
        &__radio,
        &__checkbox {
            + .general-form__label {
                .simplicity & {
                    color: #fff;
                    font-weight: 300;
                    line-height: px-to-rem($baseline * 6);
                    font-size: px-to-rem(20px);
                }

                &:before {
                    .simplicity & {
                        border-width: 0;
                    }
                }

                &:after {
                    .simplicity & {
                        background-color: #009bb5;
                    }
                }
            }
        }

        &__element--checkbox + .general-form__element--checkbox,
        &__element--radio + .general-form__element--radio {
            margin-top: $baseline * 4;
        }

        &__select-wrapper {
            &:after {
                .simplicity & {
                    border-width: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                }
            }

            &:before {
                .simplicity & {
                    border-color: #00daf9;
                }
            }
        }
    }
}
