.search-result {
    .search-group & {
        margin-top: $section-gutter;

        @include respond-to(md) {
            margin-top: $section-gutter-md;
        }
    }

    &__heading {
        font-size: px-to-rem(23px);
        font-weight: 400;
        line-height: px-to-rem($baseline * 8);
        margin: 0 0 $baseline * 1 0;
        text-transform: none;

        a {
            text-decoration: none;
        }
    }

    &__services-heading {
        text-align: center;
    }

    p {
        font-size: px-to-rem(14px);
        line-height: px-to-rem($baseline * 5);
        margin-bottom: $baseline * 2;

        &:last-child {
            margin-bottom: 0;
        }
    }

    // Warnings
    div.search-result__warning {
        border: 1px solid rgba(black, 0.2);
        border-radius: 3px;
        display: none;
        font-size: px-to-rem(14px);
        line-height: px-to-rem($baseline * 5);
        margin-bottom: $baseline * 6;
        padding: $baseline * 2;
    }

    &__results {
        border-spacing: 0;
        width: 100%;
    }

    // Branch item
    &__branch-item {
        border-top: 1px solid black;
        display: table;
        width: 100%;

        &:first-child {
            border-top: 2px solid black;
        }

        &:last-child {
            border-bottom: 2px solid black;
        }
    }

    .cmp-text,
    &__branch-item-map-container {
        margin: 0;
        padding-bottom: $baseline * 8;
        padding-top: $baseline * 8;
    }

    &__branch-item-map-container {
        display: none;

        @include respond-to(sm) {
            display: block;
        }
    }

    // Service item
    &__service-item {
        font-size: px-to-rem(14px);
        width: 100%;

        .search-result__results--list-display & {
            font-size: px-to-rem(18px);
            line-height: px-to-rem(28px);
            margin-bottom: $baseline * 12;
            text-align: center;

            @include respond-to(md) {
                display: table;
                font-size: px-to-rem(20px);
                line-height: px-to-rem(32px);
                text-align: left;
            }
        }

        a {
            text-decoration: none;
        }

        th,
        td {
            padding: $baseline * 5 0;

            @include respond-to(sm) {
                width: 33%;
            }
        }

        th {
            font-weight: normal;
            text-align: left;

            @include respond-to(0, xs) {
                display: none;
            }
        }

        td {
            @include respond-to(0, xs) {
                display: block;
                padding: $baseline;
                text-align: center;
            }

            @include respond-to(sm) {
                border-top: 1px solid black;
            }

            br {
                @include respond-to(0, xs) {
                    display: none;
                }
            }

            &:first-child {
                @include respond-to(0, xs) {
                    border-top: 1px solid black;
                    padding: $baseline * 4 0;
                }
            }

            &:last-child {
                @include respond-to(0, xs) {
                    padding-bottom: $baseline * 4;
                }
            }
        }

        &:nth-child(2) td:first-child {
            @include respond-to(0, xs) {
                border-top: 2px solid black;
            }
        }

        &:last-child td:last-child {
            @include respond-to(0, xs) {
                border-bottom: 2px solid black;
            }
        }

        &:nth-child(2) td {
            @include respond-to(sm) {
                border-top: 2px solid black;
            }
        }

        &:last-child td {
            @include respond-to(sm) {
                border-bottom: 2px solid black;
            }
        }
    }

    &__service-item-image-container,
    &__service-item-content-container {
        @include respond-to(md) {
            display: table-cell;
            vertical-align: middle;
        }
    }

    &__service-item-image-container {
        @include respond-to(md) {
            width: $baseline * 56;
        }
    }

    &__service-item-content-container {
        margin-top: $baseline * 8;

        @include respond-to(md) {
            margin: 0;
        }
    }

    &__service-item-image-border {
        border-radius: 50%;
        border-style: solid;
        border-width: 2px;
        background-size: cover;
        background-position: top center;
        height: $baseline * 36;
        margin: auto;
        overflow: hidden;
        width: $baseline * 36;

        @include respond-to(md) {
            height: $baseline * 48;
            margin: 0;
            width: $baseline * 48;
        }
    }

    &__service-item-image {
        display: block;
        width: 100%;
    }

    &__service-item-bio {
        font-size: px-to-rem(22px);
        margin-bottom: $baseline * 4;
        text-transform: uppercase;

        @include respond-to(md) {
            font-size: px-to-rem(24px);
        }
    }

    &__service-item-life {
        @include respond-to(0, sm) {
            display: block;
        }
    }

    &__service-item-title {
        font-weight: 400;

        @include respond-to(0, sm) {
            display: block;
        }
    }

    &__service-item-date,
    &__service-item-location {
        @include respond-to(0, sm) {
            margin-top: $baseline * 4;
        }
    }

    &__service-date-comma {
        @include respond-to(sm) {
            display: none;
        }
    }

    // Combined item
    &__combined-item {
        padding-bottom: $baseline * 4;

        &:first-child {
            border-top: 2px solid black;
            padding-top: $baseline * 4;
        }

        &:last-child {
            border-bottom: 2px solid black;
        }

        &-date,
        &-venue,
        &-address,
        &-phone {
            @include respond-to(md) {
                display: inline-block;
            }
        }

        &-phone,
        &-hours {
            @include respond-to(0, sm) {
                padding-left: $baseline * 16;
                position: relative;
            }
        }

        &-label {
            font-weight: 600;

            @include respond-to(0, sm) {
                left: 0;
                position: absolute;

                .search-result__combined-item-date &,
                .search-result__combined-item-venue & {
                    display: none;
                }
            }
        }
    }

    // Pagination
    &__pagination {
        margin-top: $baseline * 10;
        text-align: center;

        * {
            @extend %button-reset;
            background: transparent;
            cursor: pointer;
            font-size: px-to-rem(15px);
            padding: 0 $baseline;
        }
    }
}
