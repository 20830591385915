// sass-lint:disable indentation

$accordion-trigger-width: $baseline * 10;
$accordion-transition-time: .6s; // same as JavaScript

.accordion {
    padding-bottom: $baseline * 8;

    &__item {
        border-bottom-width: 3px;
        border-bottom-style: solid;
        position: relative;
    }

    &__head {
        text-align: center;
        margin-bottom: $baseline * 3;
    }

    &__heading {
        // line-height: px-to-rem($baseline * 12);
        margin: 0 0 $baseline;
        text-transform: uppercase;
    }

    &__subheading {
        // line-height: px-to-rem($baseline * 12);
        margin: 0;
    }

    &__body {
        background-color: $color-05;
        border-top-width: 3px;
        border-top-style: solid;
        display: none;
    }

    &__inner {
        padding-top: ($baseline * 10) - ($section-gutter / 2);
        padding-right: ($baseline * 10);
        padding-bottom: ($baseline * 14) - ($section-gutter / 2);
        padding-left: ($baseline * 10);

        @include respond-to(0, xs) {
            padding-right: ($baseline * 5);
            padding-left: ($baseline * 5);
        }

        @include respond-to(md) {
            padding-top: ($baseline * 10) - ($section-gutter-md / 2);
            padding-bottom: ($baseline * 14) - ($section-gutter-md / 2);
        }

        @include respond-to(lg) {
            padding-top: ($baseline * 10) - ($section-gutter-lg / 2);
            padding-bottom: ($baseline * 14) - ($section-gutter-lg / 2);
        }

        @include respond-to(xl) {
            padding-top: ($baseline * 10) - ($section-gutter-xl / 2);
            padding-bottom: ($baseline * 14) - ($section-gutter-xl / 2);
        }
    }

    &__trigger {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        max-height: $baseline * 8;
        margin: 0;
        margin-left: -(($accordion-trigger-width + $hit-area * 2) / 2);
        overflow: hidden;
        padding: 0 $hit-area ($hit-area * 3);
        text-align: center;
        position: absolute;
        top: 100%;
        left: 50%;

        transition: max-height $accordion-transition-time ease-in-out,
                    padding-top $accordion-transition-time ease-in-out,
                    padding-bottom $accordion-transition-time ease-in-out;

        .accordion__item--expanding & {
            max-height: 0;
            padding-bottom: 0;
        }

        .accordion__item--expanded & {
            padding: ($hit-area * 3) $hit-area 0;
            // transform: scaleY(-1);
            top: auto;
            bottom: 0;
        }

        .accordion__item--collapsing & {
            max-height: 0;
            padding-top: 0;
        }

        // &:focus,
        &:hover {
            outline: 0;

            .accordion__shape {
                height: $baseline * 4;
            }
        }
    }

    &__shape {
        display: block;
        height: 10px;
        width: $accordion-trigger-width;

        position: relative;

        transition: height .25s ease-in-out;

        &:before,
        &:after {
            content: '';
            height: 20px;
            width: 100%;

            position: absolute;

            transition: height .25s ease-in-out;
        }

        &:before {
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            display: none;

            top: -10px;

            .accordion__item--expanded & {
                display: block;
            }
        }

        &:after {
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;
            display: block;

            bottom: -10px;
            z-index: -1;

            .accordion__item--expanded & {
                display: none;
            }
        }
    }

    &__icon {
        font-size: 5px;
        position: absolute;
        right: 0;
        bottom: -5px;
        left: 0;

        .accordion__item--expanded & {
            top: -5px;
            bottom: auto;
            transform: rotate(180deg);
        }
    }
}
