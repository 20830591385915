// Hero
$ups-hero-bottom-white-height: $baseline * 8;
$ups-hero-bkg-color: #F6F3EF;

.section--service {
    margin-bottom: $baseline * 30;
}

.ups-hero {
    background-color: $ups-hero-bkg-color; 
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: $baseline * 8;
    padding-bottom: 0;
    text-align: center;
    position: relative;

    @include respond-to(sm) {
        flex-direction: row;
        justify-content: space-evenly;
    }
    @include respond-to(lg) {
        padding-top: $baseline * 16;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        background-color: $ups-hero-bkg-color; 
        width: 8.7%;
        left: 100%;
        height: 100%;
        margin-top: -($baseline * 8);
        overflow: hidden;

        @include respond-to(lg) {
            margin-top: -($baseline * 32);
            width: 100%;
        }
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        background-color: $ups-hero-bkg-color;
        width: 100%;
        right: 100%;
        height: 100%;
        margin-top: -($baseline * 8);

        @include respond-to(lg) {
            margin-top: -($baseline * 32);
        }
    }
} 

.no-filename {
    

    &::after, &::before {
        margin-top: 0;

        @include respond-to(lg) {
            margin-top: -($baseline * 16) !important;
        }
    }
}

.ups-hero__details {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to(sm) {
        margin-top: -$ups-hero-bottom-white-height;
    }

    @include respond-to(sm) {
        padding: $baseline * 10;
        width: 50%;
    }
}

.ups-hero__title {
    font-size: px-to-rem(20px);
    line-height:  px-to-rem(24px);
    margin-top: $baseline * 4;

    span {
        display: block;
    }

    @include respond-to(lg) {
        font-size: px-to-rem(40px);
        line-height:  px-to-rem(48px);
        margin-bottom: $baseline * 8;
    }
}

.ups-hero__subtitle {
    font-size: px-to-rem(14px);
    line-height:  px-to-rem(21px);
    font-style: italic;
    opacity: .5;
    margin-bottom: $baseline * 8;

    @include respond-to(lg) {
        font-size: px-to-rem(20px);
        line-height:  px-to-rem(28px);
    }
}

.ups-hero__headshot {
    border-radius: 50%;
    width: 260px;
    height: 260px;
    overflow: hidden;
    z-index: 2;

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: $ups-hero-bottom-white-height;
        background: #fff;
    }

    @include respond-to(sm) {
        margin-top: 0;
    }

    @include respond-to(lg) {
        width: 400px;
        height: 400px;
    }
}

.ups-hero__img {
    height: 100%;
    width: auto;
    position: relative;
    z-index: 1;
}


// Feature quote

.ups-feature-quote {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: $baseline * 16;
}

.ups-feature-quote__details {
    margin-top: $baseline * 4;
    max-width: 620px;
    text-align: center;
    font-size: px-to-rem(14px);
    line-height:  px-to-rem(21px);
    font-style: italic;
    font-weight: normal;

    @include respond-to(lg) {
        font-size: px-to-rem(24px);
        line-height:  px-to-rem(40px);
    }
}


// Featuer card

.ups-feature-card {
    margin-top: $baseline * 8;
    margin-bottom: $baseline * 16;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-width: 1062px;

    @include respond-to(sm) {
        margin: $baseline * 16 0;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
    }

    @include respond-to(lg) {
        margin-top: $baseline * 30; // 120px
        margin-left: auto;
        margin-right: auto;
    }
}

.ups-feature-card__primary {
    padding: $baseline * 8 $baseline * 5 0;
    
    @include respond-to(sm) {
        width: 50%;
        padding: $baseline * 16 $baseline * 8;
        max-width: 300px;
    }
}

.ups-feature-card__secondary {
    padding: 0 $baseline * 5 $baseline * 6;
    font-size: px-to-rem(12px);
    line-height: px-to-rem(18px);

    @include respond-to(sm) {
        width: 50%;
        padding: $baseline * 16 $baseline * 8 $baseline * 16 $baseline * 11;
        font-size: px-to-rem(16px);
        line-height: px-to-rem(24px);
    }
}

.ups-feature-card__title {
    font-size: px-to-rem(16px);
    line-height:  px-to-rem(20px);
    margin-bottom: $baseline * 8;
    font-weight: bold;
    width: 270px;
    word-break: break-word;

    @include respond-to(sm) {
        font-size: px-to-rem(24px);
        line-height:  px-to-rem(28px);
        margin-top: 0;
        width: 304px;
    }
}

.ups-feature-card__subtitle {
    font-size: px-to-rem(14px);
    line-height:  px-to-rem(21px);
    margin-bottom: $baseline * 4;
    width: 270px;
    word-break: break-word;

    @include respond-to(sm) {
        font-size: px-to-rem(18px);
        line-height:  px-to-rem(26px);
        margin-bottom: 0;
    }

    @include respond-to(lg) {
        width: 304px;
    }
}

.ups-feature-card__label {
    font-weight: bold;
    text-transform: none;
}

.ups-feature-card__detail {
    margin-bottom: $baseline * 4;

    &:last-child {
        margin-bottom: 0;
    }
}

.ups-feature-card__footer {
    color: #fff;
    padding: $baseline * 8 $baseline * 6;
    font-size: px-to-rem(14px);
    line-height:  px-to-rem(21px);
    width: 100%;
    display: flex;
    flex-direction: column;

    @include respond-to(sm) {
        padding: $baseline * 10;
        font-size: px-to-rem(18px);
        line-height:  px-to-rem(26px);
        flex-direction: row;
        justify-content: space-around;
    }
}

.ups-feature-card__footer-primary {
    margin-bottom: $baseline * 4;
    
    @include respond-to(sm) {
        margin-bottom: 0;
        max-width: 370px;
        width: 50%;
        margin-left: 5px;
    }
    
    @include respond-to(lg) {
        margin-left: $baseline * 4;
    }
}

.ups-feature-card__footer-secondary {
    @include respond-to(md) {
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}


// share buttons

.ups-share {
    display: flex;
    align-items: center;
    width: auto;
    height: $baseline * 12;
    text-transform: none;
    font-size: px-to-rem(14px);
    
    &:last-child {
        margin-top: $baseline * 3;

        @include respond-to(md) {
            margin-top: 0;
            margin-left: $baseline * 3;
        }
    }

    span {
        line-height: 0;
    }
}


.ups-share__icon {
    margin-right: $baseline * 3;
}


// signoff

.ups-signoff {
    display: none;
    margin: $baseline * 16 auto;
    // display: flex;
    justify-content: center;
    
    @include respond-to(lg) {
        margin: $baseline * 30 auto;
    }
}