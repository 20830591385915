.simplicity--patch-and-purr .carousel--image-carousel  {
  .slick-arrow {
    border: 0;
    bottom: auto;
    height: 80%;
    left: auto;
    margin-left: 0 !important; // Overwrite inline style.
    margin-top: 5%;
    position: absolute;
    top: 0% !important;
  }

  .slick-prev {
    left: 0;
    border-bottom-left-radius: 200px 300px;
    border-top-left-radius: 200px 300px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .slick-next {
    right: 0;
    border-bottom-right-radius: 200px 300px;
    border-top-right-radius: 200px 300px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

    &:after {
      float: right;
    }
  }

  @include respond-to(md) {
    .slick-list {
      margin: 0 $baseline * 24;
    }
  }

}



.simplicity--patch-and-purr .carousel--image-carousel  {
  $arrow-width: 15px;
  $arrow-height: 50px;

  .slick-arrow {
    opacity: 1;
    width: $baseline * 24;
    border: $baseline * 24 solid rgba(250, 184, 166, 0.4);
    padding: 0;

    &:hover  {
      &:before,
      &:after {
        background: #fab8a6;
      }
    }

    &:before,
    &:after {
      background: white;
      border: 0;
      border-radius: 3px;
      content: '';
      display: block;
      height: $arrow-height;
      margin: 0;
      position: absolute;
      top: calc(50% - #{$arrow-height - ($arrow-width / 2)});
      transform-origin: 50% #{$arrow-height - ($arrow-width / 2)};
      width: $arrow-width;
    }
  }

  .slick-prev {
    border-right: none;

    &:before,
    &:after {
      left: -70px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(135deg);
    }
  }

  .slick-next {
    border-left: none;

    &:before,
    &:after {
      right: -70px;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(-135deg);
    }
  }

  .slick-dots {
    button {
      border: 3px solid $color-01;
      height: $baseline * 4.5;
      opacity: .6;
      width: $baseline * 4.5;
    }

    .slick-active button {
      opacity: 1;
    }
  }

  .cmp-text {
    text-align: left;

    h1,
    h2,
    h3 {
      font-weight: 600;
    }
  }
}