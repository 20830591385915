// sass-lint:disable force-element-nesting no-color-literals placeholder-in-extend force-pseudo-nesting

.section--general-form + .section--contact-tile .ct {
    @include respond-to(0, sm) {
        padding-top: $baseline * 5;
    }
}

.ct {
    text-align: center;

    @include respond-to(md) {
        border: 1px solid #fff;
        padding: $baseline * 9;
    }

    .simplicity &,
    .white-lady & {
        @include respond-to(md) {
            border: 0;
            padding: 0;
        }
    }

    &__text {
        line-height: px-to-rem($baseline * 6);

        &:last-of-type {
            margin: 0;
        }

        &:not(.ct__heading):not(:last-of-type) {
            margin: 0 0 ($baseline * 4) - 1 0; // Subtract 1px from each p to account for border.

            .simplicity &,
            .white-lady & {
                margin: 0 0 ($baseline * 4);
            }
        }

        &:not(.ct__heading) {
            .simplicity &,
            .white-lady & {
                display: block;

                @include respond-to(sm) {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }

    &__heading {
        font-weight: bold;
        margin: 0;

        .simplicity &,
        .white-lady & {
            margin: 0 0 ($baseline * 10);
        }

        .simplicity & {
            font-size: px-to-rem(36px);
            line-height: px-to-rem(48px);
        }

        .white-lady & {
            font-size: px-to-rem(26px);
            line-height: px-to-rem(32px);

            @include respond-to(sm) {
                font-size: px-to-rem(30px);
                line-height: px-to-rem(48px);
            }
        }
    }

    &__link {
        .simplicity &,
        .white-lady & {
            @extend .button;
            width: auto;
        }
    }

    &__icons-container {
        display: table;
        margin: auto;

        .simplicity &,
        .white-lady & {
            display: block;

            @include respond-to(sm) {
                display: inline-block;
                vertical-align: middle;
                margin-top: 0;
            }
        }
    }

    &__icon-anchor {
        display: table-cell;

        .simplicity &,
        .white-lady & {
            display: inline-block;
            margin: $baseline * 4 15px 0 15px;

            @include respond-to(sm) {
                margin: 0 0 0 30px;
            }
        }

        &:hover {
            opacity: .7;
            text-decoration: none;
        }
    }

    &__icon,
    &__icon:before {
        display: block;
        font-size: $baseline * 9;
        height: $baseline * 9;
        line-height: $baseline * 9;
        width: $baseline * 9;
    }

    &__icon {
        margin: $baseline $baseline 0;
    }
}
