.section--complex-header {
    height: 156px;

    @include respond-to(md) {
        height: 235px;
    }
}

.complex-header {
    $nav-item-border-color: #b792f7;
    $nav-item-hover-bg-color: #b792f7;
    $secondary-nav-item-bg-color: #8589ef;
    $header-overlay-bg-color: rgba(105, 63, 127, 0.8);

    height: $baseline * 33;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: index($z-stack, header);

    @include respond-to(md) {
        height: $baseline * 59;
    }

    &:before {
        @include respond-to(0, sm) {
            background: $header-overlay-bg-color;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            opacity: 0;
            position: fixed;
            top: 0;
            transition: visibility .3s, opacity .3s;
            visibility: hidden;
            width: 100%;
        }
    }

    &--nav-open:before {
        @include respond-to(0, sm) {
            opacity: 1;
            visibility: visible;
        }
    }

    .container {
        position: relative;
    }

    &__content {
        background: #35bee0;
        padding: $baseline * 5.5 0 $baseline * 6 0;
        position: relative;
    }

    &__contact-info {
        @include respond-to(md) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__logo-container {
        @include respond-to(md) {
            align-self: center;
            position: relative;
        }
    }

    &__logo-anchor {
        display: block;
        margin: auto;
    }

    &__logo {
        display: block;
        height: $baseline * 14;
        transition: height .3s, margin-bottom .3s;

        &--large {
            display: none;
            height: 57px;

            @include respond-to(md) {
                display: block;
                margin-bottom: $baseline * 3;
            }

            .complex-header--contracted & {
                height: 40px;
                margin-bottom: 0;
            }
        }

        &--small {
            display: block;
            height: 58px;
            margin: auto;

            @include respond-to(md) {
                display: none;
            }
        }
    }

    &__telephone {
        align-self: center;
        display: none;
        transition: opacity .3s, visibility .3s;

        @include respond-to(md) {
            display: block;
        }

        .complex-header--contracted & {
            opacity: 0;
            visibility: hidden;
        }

        &-tagline {
            font-size: px-to-rem(18px);
            line-height: px-to-rem(22px);
            font-weight: 600;
            text-transform: uppercase;
        }

        &-anchor {
            display: inline-block;
            font-size: px-to-rem(32px);
            font-weight: bold;
            line-height: px-to-rem(36px);
            margin-left: $baseline * 4;
            text-decoration: none;
        }
    }

    &__ctas {
        display: none;
        font-size: 0;
        overflow: hidden;
        text-align: center;
        transition: opacity .3s, visibility .3s, height .3s;

        @include respond-to(md) {
            display: block;
        }

        &--expanded {
            height: 60px;

            .complex-header--contracted & {
                height: 0;
                opacity: 0;
                visibility: hidden;
            }
        }

        &--contracted {
            opacity: 0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            visibility: hidden;

            .complex-header--contracted & {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .button {
        display: inline-block;
        font-size: px-to-rem(16px);
        line-height: px-to-rem($baseline * 6);
        margin: $baseline * 3 $baseline * 2 0 $baseline * 2;
        padding: $baseline * 3 $baseline * 6 $baseline * 3 $baseline * 16;
        position: relative;
        white-space: nowrap;
        width: auto;

        img {
            left: $baseline * 6;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__ctas--contracted {
        .button {
            margin-top: 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__open-nav-container {
        text-align: right;

        @include respond-to(md) {
            display: none;
        }

        @media print {
            display: none;
        }

        .complex-header__telephone-anchor {
            font-size: px-to-rem(26px);
            font-weight: 600;
            vertical-align: middle;
        }
    }

    &__open-nav {
        @extend %button-reset;
        background: transparent;
        cursor: pointer;
        display: inline-block;
        margin-left: $baseline * 3;
        margin-right: -$baseline * 3;
        padding: 13px !important;
        vertical-align: middle;

        .icon,
        .icon:before {
            display: block;
            font-size: 24px;
        }

        .icon-menu {
            display: block;
        }

        .icon-cross {
            display: none;
        }

        .complex-header--nav-open & {
            .icon-menu {
                display: none;
            }

            .icon-cross {
                display: block;
            }
        }
    }

    &__nav-container {
        transition: opacity .3s, visibility .3s;

        .complex-header--contracted:not(.complex-header--nav-open) & {
            opacity: 0;
            visibility: hidden;
        }

        .complex-header--contracted & {
            @include respond-to(md) {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &__nav.container {
        display: none;

        @include respond-to(0, sm) {
            margin: 0;
            width: 100%;
        }

        @include respond-to(md) {
            display: block !important;
        }
    }

    &__nav-list {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;

        @include respond-to(md) {
            display: flex;
            justify-content: center;
            text-align: left;
        }

        &--buttons {
            @include respond-to(md) {
              display: none;
            }
        }
    }

    &__nav-item {
        margin: 0;
        padding: 0;
        position: relative;

        @include respond-to(md) {
            display: block !important;
        }

        a {
            border-top: 2px solid $nav-item-border-color;
            display: block;
            font-size: px-to-rem(16px);
            font-weight: 600;
            line-height: px-to-rem($baseline * 6);
            padding: 11px 10px;
            text-decoration: none;
            text-transform: uppercase;
            transition: background .3s;

            @include respond-to(md) {
                border-top: 0;
                padding: 18px 10px;
            }

            &:hover,
            &[aria-expanded="true"] {
                background: $nav-item-hover-bg-color;
            }
        }

        > a {
            @include respond-to(0, sm) {
              background: $secondary-nav-item-bg-color;
            }

            .complex-header__nav-list--buttons & {
                @include respond-to(0, sm) {
                    background: transparent;
                }
            }
        }

        img {
            position: absolute;
            left: $baseline * 5;

        }

        .complex-header__nav-item-button {
            display: none;
        }

        &--has-children {
            > a {
                &:after {
                    @include respond-to(md) {
                        @include chevron(down, white);
                        display: inline-block;
                        height: $baseline * 2.5;
                        margin-left: $baseline * 2;
                        margin-right: $baseline;
                        position: relative;
                        top: -3px;
                        width: $baseline * 2.5;
                    }
                }
            }

            .complex-header__nav-item-button {
                @extend %button-reset;
                background: transparent;
                display: block;
                height: $baseline * 12;
                padding: $baseline * 4 $baseline * 8 !important;
                position: absolute;
                right: 0;
                top: 0;
                transition: right .3s;

                @include respond-to(md) {
                    display: none;
                }

                &:after {
                    @include chevron(down, white);
                    display: inline-block;
                    height: $baseline * 2.5;
                    margin-left: $baseline * 2;
                    margin-right: $baseline;
                    position: relative;
                    top: -3px;
                    transform: rotateZ(135deg);
                    transition: transform .3s;
                    width: $baseline * 2.5;
                }

                &[aria-expanded="true"] {
                    right: calc(100% - #{$baseline * 22});

                    &:after {
                        transform: rotateZ(-45deg);
                    }
                }
            }
        }
    }

    &__nav-secondary-list {
        display: none;
        list-style: none;
        margin: 0;
        padding: 0;

        @include respond-to(md) {
            left: 0;
            min-width: 368px;
            position: absolute;
            top: 100%;
        }

        a {
            background: $secondary-nav-item-bg-color;
            border-top: 2px solid $nav-item-border-color;
        }
    }
}
