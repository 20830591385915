// ups modal

.ups-modal__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: $baseline;
    font-size: px-to-rem(14px);
    line-height:  px-to-rem(21px);

    @include respond-to(md) {
        font-size: px-to-rem(18px);
        line-height:  px-to-rem(28px);
    }

    .js-modal__publish-message {
        display: none;
    }
}

.ups-modal__title {
    font-weight: bold;
    font-size: px-to-rem(18px);
    line-height:  px-to-rem(26px);
    margin-bottom: $baseline * 4;

    @include respond-to(md) {
        font-size: px-to-rem(24px);
        line-height:  px-to-rem(32px);
    }
}

.ups-modal__ctas {
    margin-top: $baseline * 4;
    display: flex;
    justify-content: center;
}